import { NfAppExpCode, NfApplication } from '../menu.enum';
import { NfMenuConfig } from '../menu.type';

const menuName = 'DASHBOARD';
const titleName = 'Business Dashboard';
export const NF_DASHBOARD_MENU_CONFIG: NfMenuConfig[] = [
  {
    application: NfApplication.Dashboard,
    appExpCode: NfAppExpCode.DOC,
    menu: {
      name: menuName,
      title: titleName,
      meta: {
        boostTitle: 'Boost 360: Business Dashboard',
        jioTitle: 'JioOnline: Business Dashboard'
      },
    },
  },
  {
    application: NfApplication.Dashboard,
    appExpCode: NfAppExpCode.HOS,
    menu: {
      name: menuName,
      title: titleName,
      meta: {
        boostTitle: 'Boost 360: Business Dashboard',
        jioTitle: 'JioOnline: Business Dashboard'
      },
    },
  },
  {
    application: NfApplication.Dashboard,
    appExpCode: NfAppExpCode.HOT,
    menu: {
      name: menuName,
      title: titleName,
      meta: {
        boostTitle: 'Boost 360: Business Dashboard',
        jioTitle: 'JioOnline: Business Dashboard'
      },
    },
  },
  {
    application: NfApplication.Dashboard,
    appExpCode: NfAppExpCode.CAF,
    menu: {
      name: menuName,
      title: titleName,
      meta: {
        boostTitle: 'Boost 360: Business Dashboard',
        jioTitle: 'JioOnline: Business Dashboard'
      },
    },
  },
  {
    application: NfApplication.Dashboard,
    appExpCode: NfAppExpCode.EDU,
    menu: {
      name: menuName,
      title: titleName,
      meta: {
        boostTitle: 'Boost 360: Business Dashboard',
        jioTitle: 'JioOnline: Business Dashboard'
      },
    },
  },
  {
    application: NfApplication.Dashboard,
    appExpCode: NfAppExpCode.SPA,
    menu: {
      name: menuName,
      title: titleName,
      meta: {
        boostTitle: 'Boost 360: Business Dashboard',
        jioTitle: 'JioOnline: Business Dashboard'
      },
    },
  },
  {
    application: NfApplication.Dashboard,
    appExpCode: NfAppExpCode.SAL,
    menu: {
      name: menuName,
      title: titleName,
      meta: {
        boostTitle: 'Boost 360: Business Dashboard',
        jioTitle: 'JioOnline: Business Dashboard'
      },
    },
  },
  {
    application: NfApplication.Dashboard,
    appExpCode: NfAppExpCode.MFG,
    menu: {
      name: menuName,
      title: titleName,
      meta: {
        boostTitle: 'Boost 360: Business Dashboard',
        jioTitle: 'JioOnline: Business Dashboard'
      },
    },
  },
  {
    application: NfApplication.Dashboard,
    appExpCode: NfAppExpCode.RTL,
    menu: {
      name: menuName,
      title: titleName,
      meta: {
        boostTitle: 'Boost 360: Business Dashboard',
        jioTitle: 'JioOnline: Business Dashboard'
      },
    },
  },
  {
    application: NfApplication.Dashboard,
    appExpCode: NfAppExpCode.SVC,
    menu: {
      name: menuName,
      title: titleName,
      meta: {
        boostTitle: 'Boost 360: Business Dashboard',
        jioTitle: 'JioOnline: Business Dashboard'
      },
    },
  },
];
