import moment from 'moment';

export const DateSelcetionConfig: any = {
  custom: [
    {
      batchType: 'MONTH',
      startDate: new Date().setDate(new Date().getDate() - 30),
      endDate: new Date(),
      text: 'Previous 30 Days',
    },
    {
      batchType: 'LASTMONTH',
      startDate: moment().subtract(1, 'months').startOf('month'),
      endDate: moment().subtract(1, 'months').endOf('month'),
      text: 'Previous Month',
    },
    {
      batchType: 'LAST3MONTH',
      startDate: moment().subtract(3, 'months').startOf('month'),
      endDate: moment().subtract(1, 'months').endOf('month'),
      text: 'Previous 3 Months',
    },
    {
      batchType: 'CUSTOM',
      text: 'Custom Range',
      startDate: null,
      endDate: null,
    },
  ],
  lastYear: [
    {
      batchType: 'DAILY',
      startDate: new Date().setDate(new Date().getDate() - 7),
      endDate: new Date(),
      text: 'Last Week',
    },
    {
      batchType: 'WEEKLY',
      startDate: new Date().setDate(new Date().getDate() - 30),
      endDate: new Date(),
      text: 'Last Month',
    },
    {
      batchType: 'QUARTERLY',
      startDate: new Date().setDate(new Date().getDate() - 90),
      endDate: new Date(),
      text: 'Last 3 Months',
    },
    {
      batchType: 'MONTHLY',
      startDate: new Date().setDate(new Date().getDate() - 365),
      endDate: new Date(),
      text: 'Last Year',
    },
  ],
  mlcDashboard: [
    {
      batchType: 'WEEKLY',
      startDate: new Date().setDate(new Date().getDate() - 30),
      endDate: new Date(),
      text: 'Last 30 Days',
    },
    {
      batchType: 'WEEKLY',
      startDate: moment().subtract(1, 'months').startOf('month'),
      endDate: moment().subtract(1, 'months').endOf('month'),
      text: 'Last Month',
    },
    {
      batchType: 'WEEKLY',
      startDate: moment().subtract(3, 'months').startOf('month'),
      endDate: moment().subtract(1, 'months').endOf('month'),
      text: 'Last 3 Months',
    },
  ],

  mlcLatestUpdates: [
    {
      batchType: 'WEEKLY',
      startDate: new Date().setDate(new Date().getDate() - 30),
      endDate: new Date(),
      text: 'Last 30 Days',
    },
    {
      batchType: 'WEEKLY',
      startDate: moment().subtract(1, 'months').startOf('month'),
      endDate: moment().subtract(1, 'months').endOf('month'),
      text: 'Last Month',
    },
    {
      batchType: 'WEEKLY',
      startDate: moment().subtract(3, 'months').startOf('month'),
      endDate: moment().subtract(1, 'months').endOf('month'),
      text: 'Last 3 Months',
    },
    {
      batchType: 'CUSTOM',
      text: 'Custom Range',
      startDate: null,
      endDate: null,
    },
  ],
};
