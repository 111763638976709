import { Injectable } from '@angular/core';
declare var webengage: any;
@Injectable({
  providedIn: 'root',
})
export class WebengageService {
  constructor() {}

  get _webengage(): any {
    return webengage;
  }
}
