import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'main-download-boost-app',
  templateUrl: './download-boost-app.component.html',
  styleUrls: ['./download-boost-app.component.scss'],
})
export class DownloadBoostAppComponent implements OnInit {
  androidUrl = 'javascript:void(0)';
  iosUrl = 'javascript:void(0)';

  constructor(
    private dialogRef: MatDialogRef<DownloadBoostAppComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data) {
      this.androidUrl = this.data.androidUrl || 'javascript:void(0)';
      this.iosUrl = this.data.iosUrl || 'javascript:void(0)';
    }
  }

  ngOnInit(): void {}
}
