import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralSliderComponent } from './general-slider.component';
import { MatSidenavModule } from '@angular/material/sidenav';



@NgModule({
  declarations: [GeneralSliderComponent],
  imports: [
    CommonModule,
    MatSidenavModule
  ],
  exports: [GeneralSliderComponent]
})
export class GeneralSliderModule { }
