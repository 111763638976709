<div class="modal-container">
  <div class="modal-header">
    <div class="close">
      <mat-icon (click)="closeModal()">close</mat-icon>
    </div>
    <div class="title">select store</div>
  </div>

  <div class="modal-body">
    <div class="search-store">
      <input
        type="text"
        placeholder="Type store name..."
        [(ngModel)]="search"
      />
    </div>
    <div class="stores">
      <a [routerLink]="['/dashboard']" target="_blank" class="store-item">
        <div class="details">
          <div class="store-name font-extrabold">All Stores</div>
          <div class="store-address">(Aggregate of all stores)</div>
        </div>
      </a>
      <a
        *ngFor="let item of stores | searchFilter: search"
        [routerLink]="['/dashboard']"
        [queryParams]="{ storeId: item.FloatingPointId }"
        target="_blank"
        class="store-item"
      >
        <div class="details">
          <div class="store-name">{{ item.Name }}</div>
          <div class="store-address">({{ item.Address }})</div>
        </div>
      </a>
    </div>
  </div>
</div>
