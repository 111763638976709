import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AnimationOptions } from 'ngx-lottie';
import { Subscription } from 'rxjs';
import { Clients } from './../../constants/client.constant';
import { ConsumerService } from './../../services/consumer.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
declare const ir: any;

@Component({
  selector: 'app-refer',
  templateUrl: './refer.component.html',
  styleUrls: ['./refer.component.css'],
})
export class ReferComponent implements OnInit, OnDestroy {
  consumerData: any;
  isLoader = true;
  consumerDataSubscription!: Subscription;
  linkUrl!: any;
  baseUrl = `https://www.ref-r.com/campaign_user/p/home?brandid=31445&campaignid=26277&bid_e=355BCB8D72414FBD784338ADC28FCA09&t=420`;

  options: AnimationOptions = {
    path: '../assets/lottie/boost-lottie-loader.json',
  };

  constructor(
    public dialogRef: MatDialogRef<ReferComponent>,
    public dialog: MatDialog,
    private consumerService: ConsumerService,
    private domSanitizer: DomSanitizer
  ) {
    // this.consumerService.setClientDetails(this.getClientData);
    this.consumerService.getClientDetails.subscribe((consumer: any) => {
      if (
        consumer &&
        consumer.Name &&
        consumer.Name.toLowerCase() == Clients.jioOnline
      ) {
        this.options = {
          path: 'assets/lottie/jio-lottie-loader.json',
        };
      } else {
        this.options = {
          path: 'assets/lottie/boost-lottie-loader.json',
        };
      }
    });
  }

  ngOnInit() {
    // this.consumerData = this.consumerService.getConsumerData();
    this.consumerDataSubscription =
      this.consumerService.getConsumerData.subscribe((data) => {
        if (data) {
          this.consumerData = data;
        }
      });
    if (this.consumerData && this.consumerData.Email && this.consumerData.Name)
      // ir('track', {
      //   orderID: this.consumerData.Email ?? '',
      //   event: 'registration',
      //   email: this.consumerData.Email ?? '',
      //   fname: this.consumerData.Name ?? '',
      //   mobile: this.consumerData.PrimaryNumber ?? '',
      // });

      this.linkUrl = `${this.baseUrl}&email=${this.consumerData.Email}&fname=${this.consumerData.Name}`;

    this.linkUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
      this.linkUrl
    );
  }

  ngOnDestroy() {
    if (
      this.consumerDataSubscription &&
      !this.consumerDataSubscription.closed
    ) {
      this.consumerDataSubscription.unsubscribe();
    }
  }
  myLoadEvent() {
    this.isLoader = false;
  }
}
