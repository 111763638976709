import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  accessToken: any;
  token: any;

  getToken() {
    return this.token;
  }

  setToken(token: any) {
    this.token = token.replace(/ /gim, '+');
  }
  setAccessToken(token: any) {
    this.accessToken = token;
  }
  getAccessToken() {
    return this.accessToken;
  }
}
