import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'main-mlc-migration-modal',
  templateUrl: './mlc-migration-modal.component.html',
  styleUrls: ['./mlc-migration-modal.component.scss'],
})
export class MlcMigrationModalComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<MlcMigrationModalComponent>) {}

  ngOnInit() {}
  closeModal() {
    this.dialogRef.close();
  }
}
