export enum DrScoreMetric {
    ADD_BUSINESS_NAME = "boolean_add_business_name",
    ADD_BUSINESS_DESCRIPTION = "boolean_add_business_description",
    ADD_CLINIC_LOGO = "boolean_add_clinic_logo",
    ADD_FEATURED_IMAGE_VIDEO = "boolean_add_featured_image_video",
    SELECT_WHAT_YOU_SELL = "boolean_select_what_you_sell",
    ADD_BUSINESS_HOURS = "boolean_add_business_hours",
    ADD_CONTACT_DETAILS = "boolean_add_contact_details",
    ADD_CUSTOM_DOMAIN_NAME_AND_SSL = "boolean_add_custom_domain_name_and_ssl",
    NUMBER_UPDATES_POSTED = "number_updates_posted",
    SOCIAL_CHANNEL_CONNECTED = "boolean_social_channel_connected",
    NUMBER_SERVICES_ADDED = "number_services_added",
    NUMBER_PRODUCTS_ADDED = "number_products_added",
    ADD_BANK_ACCOUNT = "boolean_add_bank_account",
    IMAGE_UPLOADED_TO_GALLERY = "boolean_image_uploaded_to_gallery",
    CREATE_CUSTOM_PAGE = "boolean_create_custom_page",
    SHARE_BUSINESS_CARD = "boolean_share_business_card",
    CREATE_DOCTOR_E_PROFILE = "boolean_create_doctor_e_profile",
    CREATE_SAMPLE_IN_CLINIC_APPOINTMENT = "boolean_create_sample_in_clinic_appointment",
    CREATE_SAMPLE_VIDEO_CONSULTATION = "boolean_create_sample_video_consultation",
    MANAGE_APPOINTMENT_SETTINGS = "boolean_manage_appointment_settings",
    RESPOND_TO_CUSTOMER_ENQUIRIES = "boolean_respond_to_customer_enquiries",
    CREATE_STAFF = "boolean_create_staff",
    MANAGE_CALLS = "boolean_manage_calls",
    ADD_TESTIMONIAL = "boolean_add_testimonial",
    MANAGE_CUSTOMER_CALLS = "boolean_manage_customer_calls",
    ADD_FACEBOOK_LIKEBOX_PLUGIN = "add_facebook_likebox_plugin",
    SHARE_MULTICHANNEL_POST = "boolean_share_multichannel_post",
    CATALOG_SETUP = "boolean_catalog_setup",
    GENERAL_APPOINTMENTS = "boolean_general_appointments",
    BUSINESS_VERIFICATION = "boolean_business_verification"
}