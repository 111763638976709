<div class="addons-detail-modal-wrapper">
  <div class="addons-detail-header">
    <i class="modal-backicon">
      <!-- <img src="assets/images/modal-back-icon.svg"> -->
    </i>
    Auto Renewal Subscription
    <span class="modal-close">
      <img src="assets/images/modal-close-icon.svg" />
    </span>
  </div>
  <div class="recommended-wrapper">
    <div class="auto-renewal-content">
      <img class="success-icon" src="assets/images/icon-order-success.svg" />
      <h3>Order Successfully placed</h3>
      <h6>Blossom pack (10 features) + 2 features For ₹1,099/month</h6>
      <p>
        Order placed on 11 Dec 2019 at 2:34PM Order ID #234543234 Transaction ID
        #234543234
      </p>
    </div>

    <a class="need-help" href="">Need Help?</a>
  </div>
</div>
