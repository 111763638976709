<div class="page-modal">
  <div class="header-modal">
    <div class="content-wrapper-closeicon" (click)="closeModal()">
      <mat-icon>close</mat-icon>
    </div>
    <span class="title-header-modal"> Domain Booking In Process</span>
  </div>

  <div class="modal-body">Your Domain will be activated within 48 hours.</div>
</div>
