import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NfTableComponent } from './table.component';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    MatTableModule,
    MatIconModule,
    MatCheckboxModule,
    FormsModule,
  ],
  declarations: [NfTableComponent],
  exports: [NfTableComponent],
})
export class NfTableModule {}
