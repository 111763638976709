import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'nf-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class NfTabsComponent implements OnInit {

@Input() dataArray!:any[];
@Output() tabSelected = new EventEmitter();

  public imageGalleryTab!: any[];
  public activeLink!:string;

  constructor() { }

  ngOnInit(): void {
    this.imageGalleryTab = this.dataArray;
    this.activeLink = this.imageGalleryTab[0].key;
  }

  onTabSelected(key:string){
    this.activeLink = key;  
    this.tabSelected.emit(this.activeLink)
  }

}
