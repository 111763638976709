import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  NfMenuConfig,
  NfMenuService,
  NfAppExpCode,
  NfApplication,
} from '@nf/core';

import { CanAutoUnsubscribe } from '@nf/common';

import { LocalStorageKeys } from '../../environments/environment';
import { LoginService } from '../login/login.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SelectStoreComponent } from '../modal/select-store/select-store.component';
import { AddUpdateComponent } from 'projects/manage-content-app/src/app/latest-update/add-update/add-update.component';

@Component({
  selector: 'main-mlc-header',
  templateUrl: './mlc-header.component.html',
  styleUrls: ['./mlc-header.component.scss'],
})
@CanAutoUnsubscribe
export class MlcHeaderComponent implements OnInit {
  public user!: any;
  public noOfStores: number = 0;

  public dashboard!: NfMenuConfig;
  public manageContent!: NfMenuConfig;
  public ecommerce!: NfMenuConfig;
  public setting!: NfMenuConfig;
  public isHumbergerMenu: boolean = false;
  //Subscription variables
  public loginSub?: Subscription;
  public menuHeader: string = '';

  constructor(
    private menuService: NfMenuService,
    private router: Router,
    private loginService: LoginService,
    private dialog: MatDialog
  ) {
    const user = JSON.parse(localStorage.getItem(LocalStorageKeys.user)!);
    if (user && user.authTokens) this.noOfStores = user.authTokens.length;

    this.loginSub = this.loginService.isLoggedIn$.subscribe((res) => {
      if (res) {
        this.setMenuConfig(NfAppExpCode.MFG);
      }
    });
  }

  ngOnInit(): void {}

  setMenuConfig(appExpCode: NfAppExpCode) {
    // console.log('in mlc menu config');
    this.dashboard = this.menuService.getMenuConfig(
      NfApplication.Dashboard,
      appExpCode
    )!;

    this.manageContent = this.menuService.getMenuConfig(
      NfApplication.ManageContent,
      appExpCode
    )!;

    this.ecommerce = this.menuService.getMenuConfig(
      NfApplication.Ecommerce,
      appExpCode
    )!;

    this.setting = this.menuService.getMenuConfig(
      NfApplication.Setting,
      appExpCode
    )!;
  }

  onLogout() {
    this.loginService.clearLocalStorage();
    this.loginService.setLogin(false);
    this.router.navigateByUrl('/login');
    this.menuService.setTitleOfFavicon(NfApplication.Login, '');
  }

  openSelectStoreModal() {
    const dialogRef = this.dialog.open(SelectStoreComponent, {
      backdropClass: 'dark-backdrop',
      panelClass: 'full-width-pane',
      disableClose: true,
    });
  }

  openAddUpdateModal() {
    const dialogRef = this.dialog.open(AddUpdateComponent, {
      width: '750px',
      backdropClass: 'dark-backdrop',
      disableClose: true,
    });
  }

  onHamburgerClick() {
    this.isHumbergerMenu = !this.isHumbergerMenu;
  }

  getMenuHeader(menu: string) {
    switch (menu) {
      case 'DASHBOARD':
        this.menuHeader = 'DASHBOARD';
        break;
      case 'MANAGE_CONTENT':
        this.chechIsExistMenuBar('MANAGE_CONTENT');
        break;
      case 'ECOMMERCE':
        this.chechIsExistMenuBar('ECOMMERCE');
        break;

      case 'SETTING':
        this.chechIsExistMenuBar('SETTING');
        break;
      default:
        break;
    }
  }

  chechIsExistMenuBar(title: string) {
    if (this.menuHeader == title) {
      this.menuHeader = '';
    } else {
      this.menuHeader = title;
    }
  }
}
