import { NgModule } from '@angular/core';
import { CommonLibComponent } from './common-lib.component';
import { JioOnlineDirective } from './directives/jio-online.directive';
import { BoostDirective } from './directives/boost.directive';

@NgModule({
  declarations: [CommonLibComponent, JioOnlineDirective, BoostDirective],
  imports: [],
  exports: [CommonLibComponent, JioOnlineDirective, BoostDirective],
})
export class CommonLibModule {}
