import { NfClient, NfCoreLibConfig } from '@nf/core';
import { NfApiConfig, NfApiTokenStorageType, NfApiTokenType } from '@nf/common';

const production = false;

const coreConfig: NfCoreLibConfig = {
  production,
  client: NfClient.Boost,
};

const apiConfig: NfApiConfig = {
  baseUrl: 'https://api2.withfloats.com',
  tokenKey: 'token',
  tokenType: NfApiTokenType.BEARER,
  authData: 'user',
  storedIn: NfApiTokenStorageType.LOCAL_STORAGE,
  refreshToken: 'refresh-token',
  mainToken: 'main-token',
};

export enum LocalStorageKeys {
  auth_token = 'authToken',
  app_exp_code = 'AppExperienceCode',
  user = 'user',
  token = 'token',
  drs_total = 'drs-total',
  refreshToken = 'refresh-token',
  mainToken = 'main-token',
}

export const environment = {
  production,
  coreConfig,
  apiConfig,
  assuredPurchaseBaseUrl: 'https://assuredpurchase.withfloats.com',
  jioMobilityURL: 'https://www.jio.com/',
  jioFiberURL: 'https://www.jio.com/fiber',
  jioBusinessURL: 'https://www.jio.com/business/',
};
