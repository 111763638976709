import {
  AfterViewChecked,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

@Directive({
  selector: '[fxLayout],[fxLayoutAlign],[fxFlex],[fxLayoutGap],[fxHide],[iconSize]',
})
export class FxLayoutDirective implements OnChanges, AfterViewChecked {
  @Input() fxLayout: any = {};
  @Input() fxLayoutAlign: any = {};
  @Input() fxFlex: any = {};
  @Input() fxLayoutGap: any = {};
  @Input() fxHide!: boolean;
  @Input() fxRemoveMargin!: number;
  @Input() iconSize!: string;

  constructor(private eleRef: ElementRef) {}

  createLayout() {
    this.eleRef.nativeElement.style.display = 'flex';
    this.eleRef.nativeElement.style.boxSizing = 'border-box';
    if (
      this.fxLayout &&
      this.fxLayout['search'] &&
      this.fxLayout.search('wrap') !== -1
    ) {
      this.eleRef.nativeElement.style.flexFlow = this.fxLayout;
    } else if (this.fxLayout) {
      this.eleRef.nativeElement.style.flexDirection = this.fxLayout;
    }

    if (this.fxLayoutAlign) {
      const align = this.fxLayoutAlign.toString().split(' ');
      this.eleRef.nativeElement.style.placeContent =
        align[0] === 'end' ? 'flex-end' : align[0];
      this.eleRef.nativeElement.style.alignItems =
        align[1] === 'end' ? 'flex-end' : align[1];
    }

    if (this.fxFlex) {
      this.eleRef.nativeElement.style.flex = '1 1 ' + this.fxFlex;
      if (this.fxLayout) {
        if (
          this.fxLayout === 'row' ||
          (this.fxLayout['search'] && this.fxLayout.search('wrap') !== -1)
        ) {
          this.eleRef.nativeElement.style.height = this.fxFlex;
          this.eleRef.nativeElement.style.maxHeight = this.fxFlex;
        } else if (this.fxLayout === 'column') {
          this.eleRef.nativeElement.style.width = this.fxFlex;
          this.eleRef.nativeElement.style.maxWidth = this.fxFlex;
        }
      }
    }
    if (this.fxFlex === '') {
      this.eleRef.nativeElement.style.flex = '1';
    }
    if (this.fxLayoutGap) {
      for (
        let i = 0;
        i <
        this.eleRef.nativeElement.children.length -
          (this.fxRemoveMargin ? this.fxRemoveMargin : 1);
        i++
      ) {
        if (
          this.fxLayout === 'column' ||
          (this.fxLayout['search'] && this.fxLayout.search('wrap') !== -1)
        ) {
          this.eleRef.nativeElement.children[i].style.marginBottom =
            this.fxLayoutGap;
        }
        if (
          this.fxLayout === 'row' ||
          (this.fxLayout['search'] && this.fxLayout.search('wrap') !== -1)
        ) {
          this.eleRef.nativeElement.children[i].style.marginRight =
            this.fxLayoutGap;
        }
      }
    }
  }

  setIconSize() {
    this.eleRef.nativeElement.style.width = this.iconSize;
    this.eleRef.nativeElement.style.height = this.iconSize;
    this.eleRef.nativeElement.style.fontSize = this.iconSize;
  }

  ngOnChanges(): void {
    // if (this.fxLayoutGap) {
    // }
    if (this.fxHide) {
      this.eleRef.nativeElement.style.display = 'none';
    } else if (this.iconSize) {
      this.setIconSize();
    } else {
      this.createLayout();
    }
  }
  ngAfterViewChecked() {
    if (this.fxHide) {
      this.eleRef.nativeElement.style.display = 'none';
    } else {
      this.createLayout();
    }
  }
}
