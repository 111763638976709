// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  assuredPurchaseBaseUrl: 'https://assuredpurchase.withfloats.com',
  baseUrl: 'https://api2.withfloats.com',
  apiURL: 'https://api.nowfloats.com',
  webActionBaseUrl: 'https://webaction.api.boostkit.dev',
  webActionAuthorization: '59f6bc18dd304110e0972777',
  deactivateKey: '78234i249123102398',
  deactivatePwd: 'JYUYTJH*(*&BKJ787686876bbbhl',
  deactivateAuthorization: '58ede4d4ee786c1604f6c535',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
