import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PriceManager {
  isAnnualSetUp: boolean = true;

  constructor(public currencyPipe: CurrencyPipe) {}
  setAnnualPrice(type: boolean) {
    this.isAnnualSetUp = type;
  }

  getPrice(amount: number): string {
    if (this.isAnnualSetUp) {
      return this.currencyPipe.transform(amount * 12, '₹') + '/year';
    } else {
      return this.currencyPipe.transform(amount, '₹') + '/month';
    }
  }
  getPriceWithoutMonth(amount: number) {
    if (this.isAnnualSetUp) {
      return amount * 12;
    } else {
      return amount;
    }
  }
}
