import { SafeUrl } from '@angular/platform-browser';

export class PrimaryImage {
  url: string | undefined;
  createdon: Date | undefined;
  updatedon: Date | undefined;
  _parentClassId: string | undefined;
  _parentClassName: string | undefined;
  _propertyName: string | undefined;
  _kid: string | undefined;
  websiteid: string | undefined;
  isarchived: boolean | undefined;
}

export class SecondaryImage {
  url: string | undefined;
  createdon: Date | undefined;
  updatedon: Date | undefined;
  _parentClassId: string | undefined;
  _parentClassName: string | undefined;
  _propertyName: string | undefined;
  _kid: string | undefined;
  websiteid: string | undefined;
  isarchived: boolean | undefined;
}

export class FeatureBanner {
  url: string | undefined;
  createdon: Date | undefined;
  updatedon: Date | undefined;
  _parentClassId: string | undefined;
  _parentClassName: string | undefined;
  _propertyName: string | undefined;
  _kid: string | undefined;
  websiteid: string | undefined;
  isarchived: boolean | undefined;
}

export class ExtendedProperty {
  key: string | undefined;
  value: string | undefined;
  createdon: Date | undefined;
  updatedon: Date | undefined;
  _parentClassId: string | undefined;
  _parentClassName: string | undefined;
  _propertyName: string | undefined;
  _kid: string | undefined;
  websiteid: string | undefined;
  isarchived: boolean | undefined;
}

export class LearnMoreLink {
  link_description: string | undefined;
  link: string | undefined;
  createdon: Date | undefined;
  updatedon: Date | undefined;
  _parentClassId: string | undefined;
  _parentClassName: string | undefined;
  _propertyName: string | undefined;
  _kid: string | undefined;
  websiteid: string | undefined;
  isarchived: boolean | undefined;
}

export class Feature {
  boost_widget_key: string | undefined;
  feature_code: string | undefined;
  name: string | undefined;
  price!: number;
  discount_percent!: number;
  is_premium: boolean | undefined;
  createdon: Date | undefined;
  updatedon: Date | undefined;
  _parentClassId: string | undefined;
  _parentClassName: string | undefined;
  _propertyName: string | undefined;
  _kid: string | undefined;
  websiteid: string | undefined;
  isarchived: boolean | undefined;
  description: string | undefined;
  description_title: string | undefined;
  target_business_usecase: string | undefined;
  feature_importance: number | undefined;
  total_installs: string | undefined;
  primary_image: PrimaryImage | undefined;
  secondary_images: SecondaryImage[] | undefined;
  feature_banner: FeatureBanner | undefined;
  extended_properties: ExtendedProperty[] | undefined;
  usecase_importance: string | undefined;
  exclusive_to_categories: string[] | undefined;
  time_to_activation?: number | undefined;
  learn_more_link: LearnMoreLink | undefined;
  discountedPrice!: number;
}

export class PrimaryImage2 {
  url: string | undefined;
  createdon: Date | undefined;
  updatedon: Date | undefined;
  _parentClassId: string | undefined;
  _parentClassName: string | undefined;
  _propertyName: string | undefined;
  _kid: string | undefined;
  websiteid: string | undefined;
  isarchived: boolean | undefined;
}

export class IncludedFeature {
  feature_code: string | undefined;
  createdon: Date | undefined;
  updatedon: Date | undefined;
  _parentClassId: string | undefined;
  _parentClassName: string | undefined;
  _propertyName: string | undefined;
  _kid: string | undefined;
  websiteid: string | undefined;
  isarchived: boolean | undefined;
  feature_price_discount_percent?: number;
  feature_unit_count?: number | undefined;
}

export class Bundle {
  name: string | undefined;
  desc: string | undefined;
  exclusive_to_categories: string[] | undefined;
  overall_discount_percent: number = 0;
  createdon: Date | undefined;
  updatedon: Date | undefined;
  _parentClassId: string | undefined;
  _parentClassName: string | undefined;
  _propertyName: string | undefined;
  _kid: string | any;
  websiteid: string | undefined;
  isarchived: boolean | undefined;
  primary_image: PrimaryImage2 | undefined;
  included_features: IncludedFeature[] | undefined;
  exclusive_for_customers: string[] | undefined;
  min_purchase_months?: number | undefined;
  target_business_usecase: string | undefined;
  totalPrice: number = 0;
  discountedPrice: number = 0;
}

export class DiscountCoupon {
  code: string | undefined;
  discount_percent: number | undefined;
  createdon: Date | undefined;
  updatedon: Date | undefined;
  _parentClassId: string | undefined;
  _parentClassName: string | undefined;
  _propertyName: string | undefined;
  _kid: string | undefined;
  websiteid: string | undefined;
  isarchived: boolean | undefined;
}

export class Image {
  url: string | undefined;
  createdon: Date | undefined;
  updatedon: Date | undefined;
  _parentClassId: string | undefined;
  _parentClassName: string | undefined;
  _propertyName: string | undefined;
  _kid: string | undefined;
  websiteid: string | undefined;
  isarchived: boolean | undefined;
  description: string | undefined;
}

export class PromoBanner {
  createdon: Date | undefined;
  updatedon: Date | undefined;
  _parentClassId: string | undefined;
  _parentClassName: string | undefined;
  _propertyName: string | undefined;
  _kid: string | undefined;
  websiteid: string | undefined;
  isarchived: boolean | undefined;
  exclusive_to_customers: string[] | undefined;
  title: string | undefined;
  image: Image | undefined;
  cta_feature_key: string | undefined;
  exclusive_to_categories: string[] | undefined;
  cta_web_link: string | undefined;
  cta_bundle_identifier: string | undefined;
  importance?: number | undefined;
}

export class ExpertConnect {
  contact_number: string | undefined;
  is_online: boolean | undefined;
  createdon: Date | undefined;
  updatedon: Date | undefined;
  _parentClassId: string | undefined;
  _parentClassName: string | undefined;
  _propertyName: string | undefined;
  _kid: string | undefined;
  websiteid: string | undefined;
  isarchived: boolean | undefined;
  line1: string | undefined;
  line2: string | undefined;
  offline_message: string | undefined;
}

export class Image2 {
  url: string | undefined;
  createdon: Date | undefined;
  updatedon: Date | undefined;
  _parentClassId: string | undefined;
  _parentClassName: string | undefined;
  _propertyName: string | undefined;
  _kid: string | undefined;
  websiteid: string | undefined;
  isarchived: boolean | undefined;
}

export class PartnerZone {
  title: string | undefined;
  cta_web_link: string | undefined;
  createdon: Date | undefined;
  updatedon: Date | undefined;
  _parentClassId: string | undefined;
  _parentClassName: string | undefined;
  _propertyName: string | undefined;
  _kid: string | undefined;
  websiteid: string | undefined;
  isarchived: boolean | undefined;
  image = new Image2();
}

export class VideoGallery {
  title: string | undefined;
  desc: string | undefined;
  duration: string | undefined;
  youtube_link: string | any;
  createdon: Date | undefined;
  updatedon: Date | undefined;
  _parentClassId: string | undefined;
  _parentClassName: string | undefined;
  _propertyName: string | undefined;
  _kid: string | undefined;
  websiteid: string | undefined;
  isarchived: boolean | undefined;
}

export class Rootaliasurl {
  url: string | undefined;
  _kid: string | undefined;
  createdon: Date | undefined;
  updatedon: Date | undefined;
  isarchived: boolean | undefined;
  websiteid: string | undefined;
  _parentClassId: string | undefined;
  _parentClassName: string | undefined;
  _propertyName: string | undefined;
}

export class Datum {
  userid: string | undefined;
  schemaid: string | undefined;
  _kid: string | undefined;
  createdon: Date | undefined;
  updatedon: Date | undefined;
  isarchived: boolean | undefined;
  websiteid: string | undefined;
  feedback_link: string | undefined;
  features = new Array<Feature>();
  feature_deals: any[] | undefined;
  bundles = new Array<Bundle>();
  discount_coupons = new Array<DiscountCoupon>();
  promo_banners = new Array<PromoBanner>();
  expert_connect: ExpertConnect | undefined;
  partner_zone = new Array<PartnerZone>();
  video_gallery: VideoGallery[] | undefined;
  rootaliasurl: Rootaliasurl | undefined;

  filteredFeatureByCategory: any = [];
  filteredBundlesByCategory: any = [];
  filterBannersByCategory: any = [];
  myAddOns: any = [];
  filteredFeaturesByInclOrExpCode: any = [];
}

export class Extra {
  CurrentIndex: number | undefined;
  TotalCount: number | undefined;
  PageSize: number | undefined;
}

export class HomeWeb {
  Data = new Array<Datum>();
  Extra: Extra | undefined;
}
