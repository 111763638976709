import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  NfApiConfig,
  NfApiOptions,
  NfApiMethodOptions,
  NfApiHeaders,
} from './api.type';
import { NF_API_CONFIG } from './api.constant';
import { NfApiTokenStorageType } from './api.enum';
import { NfCookieService } from '../services/cookie.service';

@Injectable({ providedIn: 'root' })
export class NfApiService {
  constructor(
    @Inject(NF_API_CONFIG)
    private apiConfig: NfApiConfig,
    private http: HttpClient,
    private cookieService: NfCookieService
  ) {}

  /**
   * GET METHOD
   *
   * @param options : NfApiOptions
   *
   * @return Observable<T>
   */
  public get<T>(options: NfApiOptions): Observable<T> {
    const url = this._getPath(options);
    const apiOptions = this._getOptions(options);
    return this.http.get<T>(url, {
      headers: apiOptions.headers,
      params: apiOptions.params,
      responseType: apiOptions.responseType,
    });
  }

  /**
   * POST METHOD
   *
   * @param options : NfApiOptions
   *
   * @return Observable<T>
   */
  public post<T>(body: any, options: NfApiOptions): Observable<T> {
    const url = this._getPath(options);
    const apiOptions = this._getOptions(options);
    return this.http.post<T>(url, body, {
      headers: apiOptions.headers,
      params: apiOptions.params,
      responseType: apiOptions.responseType,
    });
  }

  /**
   * PUT METHOD
   *
   * @param options : NfApiOptions
   *
   * @return Observable<T>
   */
  public put<T>(body: any, options: NfApiOptions): Observable<T> {
    const url = this._getPath(options);
    const apiOptions = this._getOptions(options);
    return this.http.put<T>(url, body, {
      headers: apiOptions.headers,
      params: apiOptions.params,
      responseType: apiOptions.responseType,
    });
  }

  /**
   * PATCH METHOD
   *
   * @param options : NfApiOptions
   *
   * @return Observable<T>
   */
  public patch<T>(body: any, options: NfApiOptions): Observable<T> {
    const url = this._getPath(options);
    const apiOptions = this._getOptions(options);
    return this.http.put<T>(url, body, {
      headers: apiOptions.headers,
      params: apiOptions.params,
      responseType: apiOptions.responseType,
    });
  }

  /**
   * DELETE METHOD
   *
   * @param options : NfApiOptions
   *
   * @return Observable<T>
   */
  public delete<T>(options: NfApiOptions, body?: any): Observable<T> {
    const url = this._getPath(options);
    const apiOptions = this._getOptions(options);
    return this.http.delete<T>(url, {
      headers: apiOptions.headers,
      params: apiOptions.params,
      responseType: apiOptions.responseType,
      body,
    });
  }

  /**
   * Construct Path for Api Call
   *
   * @param config : NfApiConfig
   * @param options : NfApiOptions
   *
   * @reurn string;
   */
  private _getPath(options: NfApiOptions): string {
    const config = options.config ? options.config : this.apiConfig;
    let path = '';
    if (config.baseUrl) {
      path = config.baseUrl.trim() + options.apiPath.trim();
    } else {
      path = options.apiPath.trim();
    }
    return path;
  }

  /**
   * Construct Options for Api Call
   *
   * @param options : NfApiOptions
   *
   * @return any
   */
  private _getOptions(options: NfApiOptions): NfApiMethodOptions {
    const apiOptions: NfApiMethodOptions = {};
    // const config = options.config ? options.config : this.apiConfig;
    const config = {...this.apiConfig,...options.config}

    // Set Headers
    if (config.headers) {
      apiOptions.headers = config.headers;
    }

    if (apiOptions.headers && options.headers) {
      const keys = options.headers.keys();
      keys.forEach((key) => {
        if (apiOptions.headers && options.headers) {
          let value = options.headers.get(key);
          if (!value) {
            value = '';
          }
          apiOptions.headers = apiOptions.headers.set(key, value);
        }
      });
    } else if (options.headers) {
      apiOptions.headers = options.headers;
    }

    // Set Credentials
    if (
      options.withCredentials === undefined ||
      options.withCredentials === true
    ) {
      let token = '';
      if (
        config.storedIn === NfApiTokenStorageType.LOCAL_STORAGE &&
        config.tokenKey
      ) {
        const tokenValue = localStorage.getItem(config.tokenKey);
        if (tokenValue) {
          token = tokenValue;
        }
      }
      if (config.storedIn === NfApiTokenStorageType.COOKIE && config.tokenKey) {
        token = this.cookieService.getCookie(config.tokenKey);
      }

      if (token) {
        if (apiOptions.headers) {
          if (options.headers?.get('Authorization')) {
            apiOptions.headers = apiOptions.headers.set(
              'Authorization',
              options.headers?.get('Authorization') || ''
            );
          } else {
            apiOptions.headers = apiOptions.headers.set(
              'Authorization',
              `${config.tokenType} ${token}`
            );
          }
        } else {
          apiOptions.headers = new NfApiHeaders({
            Authorization: `${config.tokenType} ${token}`,
          });
        }
      }
    }

    // Set Params
    if (options.params) {
      apiOptions.params = options.params;
    }

    // Set Response Type
    if (options.responseType) {
      apiOptions.responseType = options.responseType;
    }

    // Return Options
    return apiOptions;
  }
}
