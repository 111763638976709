import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'projects/main-app/src/environments/environment';

@Component({
  selector: 'main-jio-header',
  templateUrl: './jio-header.component.html',
  styleUrls: ['./jio-header.component.scss'],
})
export class JioHeaderComponent implements OnInit {
  public mobilityUrl: string = environment.jioMobilityURL;
  public fiberUrl: string = environment.jioFiberURL;
  public logoUrl: string = environment.jioBusinessURL;

  constructor() {}

  ngOnInit(): void {}
}
