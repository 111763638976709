<div class="page-modal">
  <div class="modal-body">
    <div class="label">Book your Domain</div>
    <mat-icon class="close" (click)="closeModal()">close</mat-icon>
    <div class="buttons-wrap">
      <br />
      <br />
      <p *ngIf="isNewBooked" class="text-green-500">
        Domain booked successfully will be activated in 48 hours.
      </p>
      <div *ngIf="isConfirmed" class="flex flex-col gap-4 items-center">
        <p>The following website domain will be booked:</p>

        <p class="text-2xl">{{ domainName + domain }}</p>

        <p>Would you like to continue?</p>
        <div
          class="modal-footer res-padding-10px flex justify-center items-center"
          id="update-modal-footer"
        >
          <div id="modal-footer-buttons" class="">
            <button
              class="btn modal-footer-cancel-btn back-button"
              (click)="isConfirmed = false"
            >
              Back
            </button>
          </div>
          <div id="modal-footer-buttons" class="">
            <button
              class="
                btn
                modal-footer-disable-btn
                min-width-size
                activate-button
                pointer-events-none
              "
              (click)="activateDomain()"
              [ngClass]="{
                'modal-footer-posted-btn': isAvailable && isChecking,
                'pointer-events-auto': isAvailable && isChecking
              }"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
      <section class="section-group" *ngIf="!isNewBooked && !isConfirmed">
        <div class="form-group flex res-flex-wrap">
          <label class="w-1/1 control-label business-label pl-4 pr-4 res-label">
            Domain:
          </label>
          <div class="w-1/1 pl-4 pr-4 padding-zero">
            <div class="input-group">
              <!-- /btn-group -->
              <input
                id="other-website-url"
                class="form-control border-radius br-l-0"
                placeholder="Write a Domain Name..."
                name="url-name"
                [(ngModel)]="domainName"
                (keyup)="onDomainInput($event)"
              />

              <div class="input-group-btn padding-0">
                <select
                  id="Uri-val"
                  type="button"
                  class="
                    btn-drop
                    br-r-0 br-l-0
                    dropdown-toggle
                    other-website--url
                  "
                  (change)="domainChange()"
                  [(ngModel)]="domain"
                >
                  <option value=".COM">.COM</option>
                  <option value=".NET">.NET</option>
                  <option value=".CO.IN">.CO.IN</option>
                  <option value=".IN">.IN</option>
                  <option value=".ORG">.ORG</option>
                </select>
              </div>
            </div>
            <div class="available" *ngIf="isAvailable && isChecking">
              Domain is Available.
            </div>
            <div
              class="not-available"
              *ngIf="!isAvailable && !isLoading && isChecking"
            >
              Domain is not Available. Please contact ria@nowfloats.com.
            </div>
            <div class="available" *ngIf="isLoading">
              Chacking Domain Availablilty...
            </div>
          </div>
        </div>

        <div class="form-group flex res-flex-wrap">
          <label class="w-1/1 res-price-head"> Price of {{ domain }}: </label>
          <div class="w-1/1 pl-4 pr-4 padding-zero">
            <div class="input-group res-price-wrap">
              <span>{{ domainPrice }}</span>
              *Already Included in your package
            </div>
          </div>
        </div>
        <br />
        <div class="modal-footer res-padding-10px" id="update-modal-footer">
          <div id="modal-footer-buttons" class="">
            <button
              class="btn modal-footer-cancel-btn back-button"
              (click)="closeModal()"
            >
              Back
            </button>
          </div>
          <div id="modal-footer-buttons" class="">
            <button
              class="
                btn
                modal-footer-disable-btn
                min-width-size
                activate-button
                pointer-events-none
              "
              (click)="takeConfirmation()"
              [ngClass]="{
                'modal-footer-posted-btn': isAvailable && isChecking,
                'pointer-events-auto': isAvailable && isChecking
              }"
            >
              Activate your domain
            </button>
          </div>
        </div>
      </section>

      <!-- <button class="btn btn-default">CANCEL</button>
        <button class="btn btn-yellow">BOOK A NEW DOMAIN</button> -->
    </div>
  </div>
</div>
<nf-loader *ngIf="showLoader" [loaderText]="'Booking domain...'"></nf-loader>
