import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Subject } from 'rxjs';
import {
  BillingDetails,
  BillingDetailsRes,
} from './../../model/BillingDetails';
import { GstDetails, GstDetailsRes } from './../../model/GstDetails';
import { State, StateRes } from './../../model/State';
import { CartServiceService } from './../../services/cart-service.service';
import { CommonService } from './../../services/common.service';
import { RenewSubscriptionModalComponent } from '../renew-subscription-modal/renew-subscription-modal.component';
@Component({
  selector: 'app-billing-details',
  templateUrl: './billing-details.component.html',
  styleUrls: ['./billing-details.component.scss'],
})
export class BillingDetailsComponent implements OnInit {
  isLoader = false;
  isGst: boolean = false;
  gstDetails = new GstDetails();
  newUser = false;
  billingDetails = new BillingDetails();
  states = new Array<State>();
  errorMsg = '';
  test: any;
  openRenewModel!: boolean;

  public onClose = new Subject<boolean>();

  constructor(
    private commonService: CommonService,
    private cartService: CartServiceService,
    public dialogRef: MatDialogRef<BillingDetailsComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.openRenewModel = this.data.openRenewModel;
    this.getBillingDetails();
    this.getStates();
  }

  onProceed(form: NgForm) {
    this.setLoader(true);
    if (this.billingDetails) {
      this.billingDetails.DeviceType = 'WEB';
      // this.billingDetails.TaxDetails.GSTIN = this.isGst;
      if (this.newUser) {
        this.setCustomValuesForNewUsers();
        this.createBillingDetails(this.billingDetails);
      } else {
        this.updateBillingDetails(this.billingDetails);
      }
    }
  }

  setCustomValuesForNewUsers() {
    this.billingDetails.InternalSourceId = this.cartService.getFpID() ?? '';
    this.billingDetails.CountryCode = '+91';
    this.billingDetails.ClientId = this.cartService.getClientID() ?? '';
    this.billingDetails.AddressDetails.City =
      this.billingDetails?.AddressDetails?.State ?? '';
    this.billingDetails.AddressDetails.Country = 'INDIA';
    this.billingDetails.BusinessDetails.Email =
      this.billingDetails?.Email ?? '';
  }

  getBillingDetails() {
    this.setLoader(true);
    let fpId = this.cartService.getFpID();
    let clientId = this.cartService.getClientID();
    this.commonService.CustomerPaymentProfile(fpId, clientId).subscribe(
      (data: any) => {
        this.isNewUser(false);
        const res = data as BillingDetailsRes;
        this.billingDetails = res.Result;
        this.checkGSTIsAvailable(this.billingDetails);
        this.setLoader(false);
      },
      (error: any) => {
        this.setLoader(false);
        if (
          error != null &&
          error.Error.ErrorCode == 'INVALID CUSTOMER' &&
          error.StatusCode == 400
        ) {
          this.isNewUser(true);
        }
      }
    );
  }

  checkGSTIsAvailable(billingDetails: BillingDetails) {
    if (
      billingDetails &&
      billingDetails.TaxDetails &&
      billingDetails.TaxDetails.GSTIN
    ) {
      this.isGSTAvailable(billingDetails.TaxDetails.GSTIN);
    } else {
      this.isGSTAvailable('');
    }
  }

  isGSTAvailable(isGST: string) {
    if (isGST) {
      this.isGst = true;
      this.onChangeGstDetails();
    } else {
      this.isGst = false;
    }
  }

  setLoader(isLoader: boolean) {
    isLoader ? (this.isLoader = true) : (this.isLoader = false);
  }

  isNewUser(isNew: boolean) {
    isNew ? (this.newUser = true) : (this.newUser = false);
  }

  createBillingDetails(billingDetail: BillingDetails) {
    this.commonService.CreateCustomerPaymentProfile(billingDetail).subscribe(
      (data: any) => {
        this.setLoader(false);
        this.openModal();
      },
      (error: any) => {
        this.setLoader(false);
      }
    );
  }

  openModal(): void {
    if (this.billingDetails && this.billingDetails.Email) {
      this.cartService.setEmail(this.billingDetails.Email);
    }
    if (
      this.billingDetails &&
      this.billingDetails.TaxDetails &&
      this.billingDetails.TaxDetails.GSTIN
    ) {
      this.cartService.setGSTINNumber(this.billingDetails.TaxDetails.GSTIN);
    }

    if (this.openRenewModel) {
      this.onClose.next(undefined);
      this.dialogRef.close();
      const dialogRef = this.dialog.open(RenewSubscriptionModalComponent, {
        panelClass: ['renewal-history-modal', 'order-history-modal'],
      });
    } else {
      // this.onClose.next(true);
      // this.dialogRef.close();
      this.dialogRef.close({ data: true });
    }
  }

  updateBillingDetails(billingDetail: BillingDetails) {
    this.commonService.UpdateCustomerPaymentProfile(billingDetail).subscribe(
      (data: any) => {
        this.setLoader(false);
        this.openModal();
      },
      (error: any) => {
        this.setLoader(false);
        this.showError('States Not Availale !!');
      }
    );
  }

  getStates() {
    this.hideError();
    let clientId = this.cartService.getClientID();
    this.commonService.GetStateCode(clientId).subscribe(
      (data: any) => {
        const res = data as StateRes;
        this.states = res.Result.data;
      },
      (error: any) => {
        this.showError('States Not Availale !!');
      }
    );
  }

  getGSTDetails(gstIn: string) {
    this.hideError();
    let clientId = this.cartService.getClientID();
    this.commonService.GetGSTINInformation(clientId, gstIn).subscribe(
      (data: any) => {
        const res = data as GstDetailsRes;
        this.gstDetails = res.Result;
        let addressDetails =
          this.gstDetails.Address.AddressLine1 +
          this.gstDetails.Address.AddressLine2 +
          this.gstDetails.Address.City +
          this.gstDetails.Address.Pincode +
          this.gstDetails.Address.District +
          this.gstDetails.Address.State;
        this.billingDetails.Name = this.gstDetails.LegalName;
        this.billingDetails.AddressDetails.Line1 = addressDetails;
      },
      (error: any) => {
        this.showError('Invalid GST Number!!');
      }
    );
  }

  hideError() {
    this.errorMsg = '';
  }

  showError(message: string) {
    this.errorMsg = message;
  }

  onChangeGstDetails() {
    const patternCheck = new RegExp(
      /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/
    );
    if (patternCheck.test(this.billingDetails.TaxDetails.GSTIN)) {
      this.getGSTDetails(this.billingDetails.TaxDetails.GSTIN);
    }
  }

  onCloseModal() {
    // this.onClose.next(false);
    // this.dialogRef.close();
    this.dialogRef.close({ data: false });
  }
}
