import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'nf-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class NfTableComponent implements OnInit {
  @Input() public columns: any = [];
  @Input() public tableStyle: any = [];
  @Input() public tableContent: any;
  @Input() public iconClass: string = '';
  @Output() output = new EventEmitter<SelectionModel<NewsLetterSubs>>();
  public selection: SelectionModel<NewsLetterSubs> =
    new SelectionModel<NewsLetterSubs>(true, []);
  public allSelected: boolean = false;

  public callStatus = CallStatus;
  constructor() {}

  ngOnInit() {}

  masterToggle() {
    this.allSelected
      ? this.selection.clear()
      : this.tableContent.data.forEach((row: any) => {
          if (row.SubscriptionStatus === '20') this.selection.select(row);
        });
    this.allSelected = !this.allSelected;
    console.log(this.selection);
    this.output.emit(this.selection);
  }
  selectCheckbox(e: any, row: any) {
    if (e) {
      this.selection.toggle(row);
      this.output.emit(this.selection);
    }
  }
  handleSelection($event: any) {
    console.log($event);
    $event.stopPropagation();

  }
}


export interface NewsLetterSubs {
  UserMobile: string;
  SubscriptionStatus: string;
  CreatedOn: string;
  UserCountryCode?: string;
  SubscriptioValue: string;
}


export enum CallStatus {
  missed = 'MISSED',
  received = 'CONNECTED',
}