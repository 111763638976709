export class Notifications {
  public message!: string;
  public createdOn!: string;
  public isRead!: boolean;
  public headerText!: string;
  public buttonText!: string;
  public isTargetAchieved!: boolean;
  public _id!: string;
  public notificationData!: any[];

  Days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  Months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  constructor(item: any) {
    this.message = item.Message;
    this.createdOn = this.formatDate(item.CreatedOn);
    this.isRead = item.isRead;
    this.headerText = this.getText(item.NotificationData, 'headText');
    this.buttonText = this.getText(item.NotificationData, 'ButtonText');
    this.isTargetAchieved = item.isTargetAchieved;
    this._id = item._id;
    this.notificationData = item.NotificationData;
  }

  formatDate(d: string) {
    let date = new Date(
        new Date(parseInt(d.replace('/Date(', '').replace(')/', '')))
          .toUTCString()
          .replace(',', '')
      ),
      dateValue;
    let days = Math.floor(
      (new Date().getTime() - date.getTime()) / (1000 * 60 * 60 * 24)
    );
    let hours = new Date().getHours() - date.getHours();
    let hour = date.getHours();
    let mins = Math.abs(new Date().getMinutes() - date.getMinutes());
    let secs = date.getSeconds();
    let TimeHour = 'AM';
    if (date.getHours() > 12) {
      TimeHour = 'PM';
      hour = hour - 12;
    }
    return (
      this.Days[date.getDay()] +
      ', ' +
      this.Months[date.getMonth()].substring(0, 3) +
      ' ' +
      parseFloat(date.getDate() + '') +
      ', ' +
      date.getFullYear() +
      ' • ' +
      (hour < 10 ? '0' + hour : hour) +
      ':' +
      (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
      ' ' +
      TimeHour
    );
  }

  getText(data: any[], keyText: string) {
    if (data != null) {
      let hText = data.find((e) => e.Key == keyText);
      if (hText) return hText.Value;
    }
    return '';
  }
}
