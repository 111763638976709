import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import moment from 'moment';
import { DateSelcetionConfig } from './date-selection-config';

@Component({
  selector: 'main-date-selection',
  templateUrl: './date-selection.component.html',
  styleUrls: ['./date-selection.component.scss'],
  providers: [DatePipe]
})
export class DateSelectionComponent implements OnInit {
  @Output() dateChange = new EventEmitter();
  @Output() resetAbodentCount = new EventEmitter();
  @Input() dataKey: string = 'custom';
  @Input() selectedDataKey: string = 'LAST3MONTH';
  public DateRangeArr: any[] = [
    { key: 'MONTH', text: 'Previous 30 Days' },
    { key: 'LASTMONTH', text: 'Previous Month' },
    { key: 'LAST3MONTH', text: 'Previous 3 Months' },
    { key: 'CUSTOM', text: 'Custom Range' },
  ];
  public openCloseSelect: boolean = false;
  public dateRangeData: any = {
    batchType: '',
    text: '',
  };
  private abandonedOrders: any;
  private customDateDayCount?: number;
  public openRevenueSelect: boolean = false;
  public selectedSaleDate: string = '';
  public selectedRevenueDate: string = '';
  public batchDateArr: any[] = [];
  public saleFormGroup: FormGroup;
  public revenueFormGroup: FormGroup;
  constructor(private datePipe: DatePipe) {
    this.saleFormGroup = new FormGroup({
      start: new FormControl(),
      end: new FormControl(),
    });
    this.revenueFormGroup = new FormGroup({
      start: new FormControl(),
      end: new FormControl(),
    });

  }

  onCustomRangeCheck(dateRangeKey: string, key: string) {
    console.log('resetAbodentCount');
    let startDate, endDate;
    this.dateRangeData.batchType = dateRangeKey;
    this.resetAbodentCount.emit(true);
    
    if (key == 'SALE') {
      startDate = this.datePipe.transform(
        this.saleFormGroup.controls.start.value,
        'yyyy-MM-dd'
      );
      endDate = this.datePipe.transform(
        this.saleFormGroup.controls.end.value,
        'yyyy-MM-dd'
      );
      this.selectedSaleDate =
        this.datePipe.transform(startDate, 'mediumDate') +
        ' - ' +
        this.datePipe.transform(endDate, 'mediumDate');
      this.openSelector();
      this.customDateDayCount =
        moment(endDate).diff(moment(startDate), 'days') + 1;
      this.dateChange.emit({startDate, endDate, changeDateSelect: false, batchType: 'CUSTOM'});
      console.log('date order');
    } else {
      startDate = this.datePipe.transform(
        this.revenueFormGroup.controls.start.value,
        'yyyy-MM-dd'
      );
      endDate = this.datePipe.transform(
        this.revenueFormGroup.controls.end.value,
        'yyyy-MM-dd'
      );
      this.selectedRevenueDate =
        this.datePipe.transform(startDate, 'mediumDate') +
        ' - ' +
        this.datePipe.transform(endDate, 'mediumDate');
      this.openSelector();
      this.customDateDayCount =
        moment(endDate).diff(moment(startDate), 'days') + 1;
      this.dateChange.emit({startDate, endDate, changeDateSelect: false});
      console.log('date order');
    }
  }
  openSelector() {
    this.openCloseSelect = !this.openCloseSelect;
  }
  openRevenueSelector() {
    this.openRevenueSelect = !this.openRevenueSelect;
  }
  forDate() {
    let startDate, endDate;
    this.selectedRevenueDate =
      this.datePipe.transform(this.getStartDate(3)) +
      ' - ' +
      this.datePipe.transform(this.getEndDate(1));

    this.selectedSaleDate =
      this.datePipe.transform(this.getStartDate(3)) +
      ' - ' +
      this.datePipe.transform(this.getEndDate(1));
    startDate = this.getStartDate(3);
    endDate = this.getEndDate(1);
    this.batchDateArr = DateSelcetionConfig[this.dataKey];
    this.dateRangeData.batchType = this.selectedDataKey;
    this.resetAbodentCount.emit(true);
    console.log('resetAbodentCount');
    this.dateChange.emit({startDate, endDate, changeDateSelect: false, batchType: this.selectedDataKey});
    console.log('date order');

  }
  calculateOrderChange(presentVal: number, pastVal: number) {
    if (pastVal == 0) {
      return presentVal * 100;
    } else {
      return (presentVal / pastVal - 1) * 100;
    }
  }

  getStartDate(num: number) {
    return moment()
      .subtract(num, 'months')
      .startOf('month')
      .format('yyyy-MM-DD');
  }

  getEndDate(num: number) {
    return moment().subtract(num, 'months').endOf('month').format('yyyy-MM-DD');
  }
  openDatePicker(datepicker: any) {
    this.saleFormGroup.reset();
    datepicker.open();
  }
  onDateSelect(date: any, key: string) {
    let startDate, endDate;
    this.dateRangeData.batchType = date.batchType;
    console.log('resetAbodentCount');
    this.resetAbodentCount.emit(true);
    const found: any = this.batchDateArr.find((element) => {
      return element.batchType == date.batchType;
    });
    console.log(found);
    if (key == 'SALE') {
      this.openCloseSelect = false;
      this.selectedSaleDate =
        this.getMediumDateTransform(found.startDate) +
        ' - ' +
        this.getMediumDateTransform(found.endDate);
      startDate = this.getYearlyDateTransform(found.startDate);
      endDate = this.getYearlyDateTransform(found.endDate);
      this.dateChange.emit({startDate, endDate, changeDateSelect: false, batchType: date.batchType});
      console.log('date order');
    } else {
      this.openRevenueSelect = false;
      this.selectedRevenueDate =
        this.getMediumDateTransform(found.startDate) +
        ' - ' +
        this.getMediumDateTransform(found.endDate);
      startDate = this.getYearlyDateTransform(found.startDate);
      endDate = this.getYearlyDateTransform(found.endDate);
      // this.getRevenueData(startDate, endDate, false);
    }
  }

  getMediumDateTransform(date: any) {
    return this.datePipe.transform(date, 'mediumDate');
  }
  getYearlyDateTransform(date: any) {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }
  ngOnInit(): void {
    this.forDate();
  }

}