<div class="addons-detail-modal-wrapper">
  <div class="addons-detail-header">
    how would you like to renew?
    <span class="modal-close" (click)="dialogRef.close()">
      <img src="assets/images/modal-close-icon.svg" />
    </span>
  </div>
  <div class="recommended-wrapper">
    <div class="renew-subscription-content">
      <form>
        <div class="radio-bx">
          <label class="radio-custom"
            >Auto-renew (using credit card)
            <input
              type="radio"
              (change)="onItemChange(a.value)"
              #a
              value="AutoRenew"
              checked="checked"
              name="radio"
            />
            <span class="checkmark"></span>
          </label>
          <p>
            <!-- Your next auto-renewal date will be 12th july 2020. -->
            You can disable auto renewal of any feature/package at anytime.
          </p>
        </div>
        <div class="radio-bx">
          <label class="radio-custom"
            >Remind me to renew
            <input
              type="radio"
              (change)="onItemChange(b.value)"
              #b
              value="remindRenew"
              name="radio"
            />
            <span class="checkmark"></span>
          </label>
          <p>
            We’ll send renewal reminder SMS and emailer 7 day prior to the day
            of expiry.
          </p>
        </div>

        <div class="error" *ngIf="errorMsg && errorMsg.length">
          {{ errorMsg }}
        </div>

        <!-- <button
          *ngIf="!isLoader"
          type="button"
          class="btn-yellow btn-100"
          (click)="onProceed()"
        >
          Proceed Securely
        </button> -->

        <nf-raised-button
          *ngIf="!isLoader"
          [style]="{ 'font-weight': '600' }"
          (click)="onProceed()"
        >
          Proceed Securely
        </nf-raised-button>
        <div class="btn-yellow btn-100 loader" *ngIf="isLoader">
          <div class="full-loader"></div>
        </div>
      </form>
    </div>
  </div>
  <div class="order-empty">
    <div class="order-empty-content">
      <h4>And up that goes</h4>
      <p>There's nothing in your order history.</p>
      <img src="assets/images/order-empty-icon.svg" />
      <nf-raised-button
        [style]="{ 'font-weight': '600' }"
        type="button"
        routerLink="/"
      >
        go back to marketplace
      </nf-raised-button>
    </div>
  </div>
</div>
