<div class="main-modal">
  <nf-loader *ngIf="showLoader" [loaderText]="'Adding Update...'"></nf-loader>
  <div class="main-modal-container">
    <div class="modal-header" id="update-modal-header">
      <div id="update-modal-header-title" class="update-modal-header-title">
        Adding an update
      </div>
      <div class="update-modal-header-content">
        <div
          class="update-modal-header-customise"
          id="update-modal-header-link"
        >
          <span>
            <mat-icon>launch</mat-icon>
            <a
              class="content-guidelines-link"
              href="https://cdn.nowfloats.com/manage/assets/Content/files/13%20ideas%20to%20update%20better%20content.pdf"
              target="_blank"
              >Content guidelines</a
            >
          </span>
        </div>
        <div class="update-modal-header-custom">
          <label style="cursor: pointer">
            <mat-checkbox (change)="changeEditor()" class="rich-text-checkbox">
              <span class="update-modal-header-customise custom-header-noul"
                >Rich text</span
              >
            </mat-checkbox>
          </label>
        </div>
      </div>

      <div (click)="closeModal()" class="content-wrapper-closeicon">
        <mat-icon>close</mat-icon>
      </div>
    </div>
    <div class="modal-body" id="update-modal-body">
      <div id="update-modal-body-content">
        <div class="w-full" id="update-modal-body-border">
          <div id="update-modal-photo-content" class="fileinput fileinput-new">
            <div
              class="fileinput-new thumbnail"
              id="update-modal-photo-display"
            >
              <div *ngIf="!primaryFileBase64" id="zeroth-case-add-photo">
                <mat-icon id="zeroth-case-update-image">local_see</mat-icon>
                <p>
                  JPEG/PNG (image size below 1MB and minimum dimesion is 300 ×
                  300 pixels)
                </p>
              </div>
              <div *ngIf="primaryFileBase64">
                <img
                  src="assets/img/Close-white-button.svg"
                  alt="cross-icon"
                  class="img-close-btn"
                  (click)="removeImage()"
                />
                <span class="product-image-holder">
                  <img [src]="primaryFileBase64" />
                </span>
              </div>
              <button id="update-image-add" (click)="fileInput.click()">
                <input
                  type="file"
                  name="image-file"
                  #fileInput
                  accept="image/*"
                  id="product-image-file-input"
                  style="display: none"
                  (change)="uploadPrimaryFile($event)"
                />
                {{ primaryFileBase64 ? 'Change Image' : 'Add Image' }}
              </button>
            </div>
          </div>
          <div id="update-modal-body-textarea">
            <textarea
              *ngIf="!isRich"
              id="updateTextId"
              placeholder="Update your website regularly and relevantly to grow the search traffic..."
              class="form-control"
              style="display: inline-block"
              [(ngModel)]="message"
            ></textarea>
            <quill-editor
              *ngIf="isRich"
              [modules]="quillModules"
              [sanitize]="true"
              [styles]="{ height: '192px' }"
              [placeholder]="''"
              (onEditorChanged)="richEditor($event)"
            ></quill-editor>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer" id="update-modal-footer">
      <div id="update-modal-social-sharing-section">
        <span class="send-update-option"> Also send this to:</span>
        <br />
        <br />
        <div id="modal-footer-sharing-area">
          <div class="mt-3">
            <span>
              <span class="update-modal-header-customise custom-header-noul">
                <mat-checkbox
                  matTooltip="Share To Your Twitter Followers"
                  matTooltipClass="checkbox-tooltip"
                  matTooltipPosition="above"
                  [checked]="isTwitter"
                  class="footer-checkbox"
                  (click)="changeCheckbox($event, 'twitter')"
                >
                  <span class="mr-2"> My Twitter page </span>
                </mat-checkbox>
              </span>
            </span>
            <span>
              <span class="update-modal-header-customise custom-header-noul">
                <mat-checkbox
                  matTooltip="Share To Your FB Page"
                  matTooltipClass="checkbox-tooltip"
                  matTooltipPosition="above"
                  [checked]="isFacebook"
                  class="footer-checkbox"
                  (click)="changeCheckbox($event, 'facebook')"
                >
                  <span class="mr-2"> My Fb page </span>
                </mat-checkbox>
                <!-- <mat-checkbox
                  (change)="onCheckedItem($event)"
                  [(ngModel)]="checked"
                  (click)="$event.preventDefault()"
                >
                  Item</mat-checkbox
                > -->
              </span>
            </span>
            <span>
              <span class="update-modal-header-customise custom-header-noul">
                <mat-checkbox
                  matTooltip="Share To Your Google My Business page"
                  matTooltipClass="checkbox-tooltip"
                  matTooltipPosition="above"
                  [checked]="isGmb"
                  class="footer-checkbox"
                  (click)="changeCheckbox($event, 'gmb')"
                >
                  <span class="mr-2"> My GMB page </span>
                </mat-checkbox>
              </span>
            </span>
            <span>
              <span class="update-modal-header-customise custom-header-noul">
                <mat-checkbox
                  matTooltip="Share To Your Subscribers"
                  matTooltipClass="checkbox-tooltip"
                  matTooltipPosition="above"
                  [checked]="isSubscriber"
                  class="footer-checkbox"
                  (change)="isSubscriber = !isSubscriber"
                >
                  <span class="mr-2"> My website subscribers </span>
                </mat-checkbox>
              </span>
            </span>
          </div>
        </div>
      </div>
      <div class="js-warning flex ml-3 items-center gap-1">
        <svg
          style="vertical-align: middle"
          width="13"
          height="14"
          viewBox="0 0 13 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.5 1C9.81371 1 12.5 3.68629 12.5 7C12.5 10.3137 9.81371 13 6.5 13C3.18629 13 0.5 10.3137 0.5 7C0.5 3.68629 3.18629 1 6.5 1Z"
            stroke="var(--primary-color)"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M6.5 9.40039V7.00039"
            stroke="var(--primary-color)"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M6.5 4.59961H6.506"
            stroke="var(--primary-color)"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
        <span class="js-warning-text"
          >Adding any javascript or browser support scripts in the updates may
          lead to a bad experience for your users.</span
        >
      </div>
      <div id="modal-footer-buttons" class="">
        <nf-raised-button
          [style]="{ 'font-weight': '600' }"
          (click)="postUpdate()"
        >
          POST NOW
        </nf-raised-button>
      </div>
    </div>
  </div>
</div>
