import { NfClientService } from './../client/client.service';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NF_MENU_CONFIG } from './config/menu.config';
import { NfAppExpCode, NfApplication, NfAppName } from './menu.enum';
import { NfMenuConfig } from './menu.type';
import { NfClient } from '../client/client.enum';
import { MLC_MENU_CONFIG } from './mlc-config/mlc-menu-config';

@Injectable({
  providedIn: 'root',
})
export class NfMenuService {
  private _configs: NfMenuConfig[] = [];
  public boostLoginTitle = 'Boost 360: Login';
  public jioLoginTitle = 'JioOnline: Login';

  constructor(private title: Title, public clientService: NfClientService) {}

  getMenuConfig(application: NfApplication, appExpCode: NfAppExpCode) {
    return this._configs.find(
      (config) =>
        config.application === application && config.appExpCode === appExpCode
    );
  }

  setTitleOfFavicon(application: NfApplication, appExpCode: any) {
    console.log(application);
    
    if (application == NfApplication.Login) {
      if (this.clientService.currentClientConfig().client == NfClient.Boost) {
        this.title.setTitle(this.boostLoginTitle);
      }
      if (
        this.clientService.currentClientConfig().client == NfClient.JioOnline
      ) {
        this.title.setTitle(this.jioLoginTitle);
      }
      return;
    }
    let selectedTab: any = this._configs.find(
      (config) =>
        config.application === application && config.appExpCode === appExpCode
    );
    // console.log(selectedTab);
    if (selectedTab && selectedTab.menu && selectedTab.menu.items) {
      let tab = selectedTab.menu.items.filter(
        (elem: any) => elem.url == window.location.pathname
      );
      if (this.clientService.currentClientConfig().client == NfClient.Boost) {
        this.title.setTitle(tab[0]?.meta?.boostTitle);
      } else if (
        this.clientService.currentClientConfig().client == NfClient.JioOnline
      ) {
        this.title.setTitle(tab[0]?.meta?.jioTitle);
      }
    } else {
      if (selectedTab && selectedTab.menu && selectedTab.menu.title) {
        if (this.clientService.currentClientConfig().client == NfClient.Boost) {
          this.title.setTitle('Boost 360: ' + selectedTab.menu.title);
          // this.title.setTitle(selectedTab.menu.meta?.boostTitle);
        } else if (
          this.clientService.currentClientConfig().client == NfClient.JioOnline
        ) {
          this.title.setTitle('JioOnline: ' + selectedTab.menu.title);
          // this.title.setTitle(selectedTab.menu.meta?.jioTitle);
        }
      }
    }
  }

  setTitle(appExp: any) {
    const path = window.location.pathname;
    if (path.includes(NfAppName.Dashboard))
      this.setTitleOfFavicon(NfApplication.Dashboard, appExp);
    if (path.includes(NfAppName.ManageUser))
      this.setTitleOfFavicon(NfApplication.ManageUser, appExp);
    if (path.includes(NfAppName.ManageContent))
      this.setTitleOfFavicon(NfApplication.ManageContent, appExp);
    if (path.includes(NfAppName.Ecommerce))
      this.setTitleOfFavicon(NfApplication.Ecommerce, appExp);
    if (path.includes(NfAppName.ManageReport))
      this.setTitleOfFavicon(NfApplication.ManageReport, appExp);
    if (path.includes(NfAppName.AddonMarketplace))
      this.setTitleOfFavicon(NfApplication.AddonMarketplace, appExp);
    if (path.includes(NfAppName.Setting))
      this.setTitleOfFavicon(NfApplication.Setting, appExp);
    if (path.includes(NfAppName.Login))
      this.setTitleOfFavicon(NfApplication.Login, appExp);
  }

  setMenuConfig(isMLC?: boolean) {
    if (isMLC) this._configs = MLC_MENU_CONFIG;
    else this._configs = NF_MENU_CONFIG;

    // console.log(this._configs);
  }
}
