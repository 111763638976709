import { MatTabsModule } from '@angular/material/tabs';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NfTabsComponent } from './tabs.component';



@NgModule({
  declarations: [
    NfTabsComponent
  ],
  imports: [
    CommonModule,
    MatTabsModule,

  ],
  exports:[NfTabsComponent]

})
export class NfTabsModule { }
