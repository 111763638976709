import { PriceManager } from './../../services/price-manager.service';
import { Component, OnInit } from '@angular/core';
import { RenewalHistory } from './../../model/renewalHistory';
import { CartServiceService } from './../../services/cart-service.service';
import { CommonService } from './../../services/common.service';
import { FeatureService } from './../../services/features-service.service';
import { OrderHistoryModalComponent } from '../order-history-modal/order-history-modal.component';
import { MatDialogRef } from '@angular/material/dialog';
import { NgDialogAnimationService } from 'ng-dialog-animation';
@Component({
  selector: 'app-renewal-history-modal',
  templateUrl: './renewal-history-modal.component.html',
  styleUrls: ['./renewal-history-modal.component.scss'],
})
export class RenewalHistoryModalComponent implements OnInit {
  renewHistoryData = new RenewalHistory();
  fpId: any;
  clientId: any;
  showLoader: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<RenewalHistoryModalComponent>,
    public dialog: NgDialogAnimationService,
    private service: CommonService,
    public dataService: FeatureService,
    public cartService: CartServiceService,
    public priceManager: PriceManager
  ) {}

  ngOnInit(): void {
    this.fpId = this.cartService.getFpID();
    this.clientId = this.cartService.getClientID();

    this.getApi();
  }

  getApi() {
    this.showLoader = true;
    this.service.getMyAdOns().subscribe((data: any) => {
      this.renewHistoryData = data as RenewalHistory;
      this.showLoader = false;
    });
  }

  _date_field(date: any) {
    if (date) {
      let x = date.split('(');
      let y = x[1].split(')');
      let z = new Date(+y[0]);
      return z;
    } else {
      return null;
    }
  }

  openModal(): void {
    // const dialogRef = this.dialog.open(OrderHistoryModalComponent, {
    //   panelClass: ['renewal-history-modal', 'order-history-modal'],
    // });

    // , 'order-history-modal'

    this.dialogRef.close();

    const dialogRef = this.dialog.open(OrderHistoryModalComponent, {
      width: '428px',
      animation: {
        to: 'left',
      },
      position: { right: '0', top: '0', bottom: '0' },
      panelClass: ['slide-modal', 'order-history-modal'],
    });
  }

  onOpenModel(data: any) {
    if (data) {
      this.dataService.setRenewHistory(data);
      // this.openModal();
      this.dialogRef.close({ data: 'openHistory' });
    }
  }
}
