export class Error {
  ErrorCode?: any;
  ErrorList?: any;
}

export class Coordinates {
  Latitude?: any;
  Longitude?: any;
}

export class Address {
  AddressLine1!: string;
  AddressLine2!: string;
  City!: string;
  Pincode!: string;
  State!: string;
  District!: string;
  Coordinates!: Coordinates;
}

export class GstDetails {
  GSTIN!: string;
  PrimaryNatureOfBusinessActivity!: string;
  NatureOfBusinessActivity!: string[];
  Address!: Address;
  GSTINStatusCode!: number;
  GSTINStatus!: string;
  LastUpdatedDate!: string;
  LegalName!: string;
  TradeName!: string;
  PAN!: string;
}

export class GstDetailsRes {
  StatusCode!: number;
  Error!: Error;
  Result!: GstDetails;
}
