<div class="page-modal">
  <div class="modal-body">
    <div class="label">Link a Domain (Included in your Boost Package)</div>
    <div class="buttons-wrap">
      <br />
      <br />
      <!-- <button class="btn btn-already-domain" (click)="openOldDomain()">
        ALREADY HAVE A DOMAIN
      </button>
      <br />
      <div class="text-center">or</div>
      <br /> -->
      <button class="btn btn-already-domain" (click)="openBookDomain()">
        BOOK A NEW DOMAIN
      </button>
    </div>
  </div>
</div>
