<div class="modal-content">
  <img
    (click)="closeModal(false)"
    class="modal-dismiss"
    src="assets/img/closeicon.svg"
  />
  <div class="modal-body">
    <p class="desc">Connect this channel from our content sharing page.</p>
    <nf-raised-button
      [style]="{ 'font-size': '15px' }"
      (click)="closeModal(true)"
      routerLink="/manage-content/content-sharing-setting"
      >Open</nf-raised-button
    >
  </div>
</div>
