<div class="addons-detail-modal-wrapper" *ngIf="renewHistory">
  <div class="addons-detail-header">
    <i class="back-arrow" (click)="dialogRef.close()">
      <img src="assets/images/back-modal-arrow.svg" />
    </i>
    <p>
      ORDER ID: #{{ renewHistory.orderId }}
      <span *ngIf="renewHistory.ToBeActivatedOn">
        {{
          _date_field(renewHistory.ToBeActivatedOn) | date: 'MMM d, y - h:mm a'
        }}</span
      >
    </p>
    <div
      class="get-invoice-bx"
      *ngIf="renewHistory.invoiceUrl"
      (click)="onOpenInvoiceUrl(renewHistory.invoiceUrl)"
    >
      <img src="assets/images/print-icon.svg" />
      get invoice
    </div>
  </div>
  <div class="recommended-wrapper">
    <div class="renewal-modal-content">
      <div class="addon-content">
        <h5><sub>₹</sub>{{ renewHistory.paidAmount }}</h5>
        <p *ngIf="renewHistory.PaymentMethod">
          Successfully paid via {{ renewHistory.PaymentMethod }}
          <!--   HDFC Bank Credit card XXXX5675
                    on 18 nov, 2020 8:29 am -->
        </p>
      </div>
      <div class="subscription-wrapper">
        <div class="subscription-cart">
          <div class="add-cart-header">
            <h4>Perticulars</h4>
            <strong>
              {{ renewHistory.purchasedPackageDetails?.WidgetPacks.length }}
              itmes</strong
            >
          </div>

          <div
            class="addon-text-wrapper"
            *ngFor="
              let data of renewHistory.purchasedPackageDetails?.WidgetPacks
            "
          >
            <h4>{{ data.Name }}</h4>
            <div class="addon-price">
              <p>
                {{ data.Price | currency: '₹' }}/month
                <span *ngIf="data.Discount && data.Discount != 0">
                  {{ data.Price | currency: '₹' }}/month
                </span>
              </p>
              <b *ngIf="data.Discount && data.Discount != 0"
                >- {{ data.Discount }}%</b
              >
            </div>
          </div>
          <div class="add-cart-header">
            <h4>Validity</h4>
            <strong>
              {{ renewHistory.purchasedPackageDetails?.ValidityInMths }}
              Months</strong
            >
          </div>

          <div class="amount-bx">
            <table>
              <tr *ngIf="renewHistory.baseAmount">
                <td>Cart amount</td>
                <td>{{ renewHistory.baseAmount | currency: '₹' }}</td>
              </tr>
              <tr *ngIf="renewHistory.discount">
                <td>Coupon discount</td>
                <td>{{ renewHistory.discount | currency: '₹' }}</td>
              </tr>

              <tr *ngIf="renewHistory.taxAmount">
                <td>IGST (18%)</td>
                <td>{{ renewHistory.taxAmount | currency: '₹' }}</td>
              </tr>
              <tr *ngIf="renewHistory.paidAmount">
                <td>Order Total</td>
                <td>{{ renewHistory.paidAmount | currency: '₹' }}</td>
              </tr>
            </table>
            <div *ngIf="renewHistory.paidAmount" class="total-ammount-bx">
              <p class="amount-paid">Amount Paid</p>
              <div class="total-price">
                {{ renewHistory.paidAmount | currency: '₹' }}
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="continue-btn-bx">

                    <button type="button" class="btn-border-rounded" (click)="openModal()">Repeat Order</button>
                </div> -->
      </div>
    </div>
  </div>
</div>

<div class="addons-detail-modal-wrapper" *ngIf="!renewHistory">
  No Renew History Found
</div>
