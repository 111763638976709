import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LocalStorageKeys } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate() {
    const authToken = localStorage.getItem(LocalStorageKeys.auth_token);
    const appExpCode = localStorage.getItem(LocalStorageKeys.app_exp_code);
    const user = localStorage.getItem(LocalStorageKeys.user);
    const token = localStorage.getItem(LocalStorageKeys.token);
    const partnerValidationValue = JSON.parse(
      localStorage.getItem('partnerValidation')!
    );
    if (authToken && appExpCode && user && token) {
      const marketPlace = '/addon-marketplace/marketplace';
      const renewalHistory = '/setting/renewal-history';
      if (
        window.location.pathname === marketPlace &&
        !partnerValidationValue.IsMarketPlaceEnable &&
        !(
          window.location.search === '?nfmanualpackactivationw1t4a=' ||
          window.location.search === '?nfmanualpackactivationw1t4a'
        )
      ) {
        this.redirectLogin();
        return false;
      } else if (
        window.location.pathname === renewalHistory &&
        !partnerValidationValue.IsRenewalHistoryEnable
      ) {
        this.redirectLogin();
        return false;
      }
      return true;
    } else {
      this.redirectLogin();
      return false;
    }
  }
  redirectLogin() {
    localStorage.clear();
    this.router.navigate(['/login/mobile']);
  }
}
