import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CouponService {
  constructor() {}

  public isCoupon: any;
  public couponName: any;

  getCoupon() {
    return this.isCoupon;
  }

  setCoupon(data: any) {
    this.isCoupon = data;
  }

  setCouponName(data: any) {
    this.couponName = data;
  }
  getCouponName() {
    return this.couponName;
  }
}
