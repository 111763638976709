import { ModuleWithProviders, NgModule } from '@angular/core';
import { NfCoreLibComponent } from './core-lib.component';
import { NF_CORE_LIB_CONFIG } from './core-lib.config';
import { NfCoreLibConfig } from './core-lib.type';

@NgModule({
  declarations: [NfCoreLibComponent],
  imports: [],
  exports: [],
})
export class NfCoreLibModule {
  static forRoot(
    config: NfCoreLibConfig
  ): ModuleWithProviders<NfCoreLibModule> {
    return {
      ngModule: NfCoreLibModule,
      providers: [{ provide: NF_CORE_LIB_CONFIG, useValue: config }],
    };
  }
}
