import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Clients } from '../constants/client.constant';
import { ConsumerService } from '../services/consumer.service';

@Component({
  selector: 'app-unauthorished-access-page',
  templateUrl: './unauthorished-access-page.component.html',
  styleUrls: ['./unauthorished-access-page.component.scss'],
})
export class UnauthorishedAccessPageComponent implements OnInit {
  timeLeft: number = 6;
  interval: any;

  constructor(
    private consumerService: ConsumerService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        // this.consumerService.setClientDetails(this.getClientData);
        this.consumerService.getClientDetails.subscribe((consumer: any) => {
          if (
            consumer &&
            consumer.Name &&
            consumer.Name.toLowerCase() == Clients.jioOnline
          ) {
            window.parent.location.replace('https://jioonline.nowfloats.com/');
          } else {
            this.router.navigateByUrl('/dashboard');
          }
        });
      }
    }, 1000);
  }
}
