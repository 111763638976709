<div class="need-help-container">
  <img class="needHelpWomen" src="assets/loginImages/needHelpWomen.svg" />

  <div>
    <div class="need-help-icons">
      <img
        (click)="closeModal()"
        class="BlackCancle"
        src="assets/loginImages/Cancel.png"
      />
    </div>
    <div class="needHelpTitle">
      <h4>We’re under maintenance.</h4>
    </div>
    <div class="login-down-msg">
      <p>
        This is to inform you that the Dashboard portal will be down for the
        next few days due to cloud downtime. We apologize for the inconvenience
        during this time.You can use our apps to access your account.
      </p>
      <p>
        Thank you for being a valued customer. We are committed to providing you
        with the best service possible. We promise to get back stronger to
        provide you with the best experience.
      </p>
      <p>
        Please contact our team at <span (click)="openNeedHelp()">here</span>
      </p>
    </div>
  </div>
</div>
