export enum NfApplication {
  Dashboard,
  ManageUser,
  Ecommerce,
  ManageContent,
  ManageReport,
  AddonMarketplace,
  Setting,
  Login,
}

export enum NfAppName {
  Dashboard = 'dashboard',
  ManageUser = 'manage-user',
  Ecommerce = 'e-commerce',
  ManageContent = 'manage-content',
  ManageReport = 'manage-report',
  AddonMarketplace = 'addon-marketplace',
  Setting = 'setting',
  Login = 'login',
}

export enum NfAppExpCode {
  DOC = 'DOC',
  HOS = 'HOS',
  HOT = 'HOT',
  CAF = 'CAF',
  EDU = 'EDU',
  SPA = 'SPA',
  SAL = 'SAL',
  MFG = 'MFG',
  RTL = 'RTL',
  SVC = 'SVC',
}
