<div class="compare-modal-wrapper">
  <div class="h-3 relative">
    <span class="modal-close" (click)="dialogRef.close()">
      <img src="assets/img/cross-page-icon.svg" />
    </span>
  </div>
  <div class="wrapper">
    <div class="leftWrap">
      <div class="tutorialCount">
        Tutorial ({{ selectedVideo.number }} of {{ videos.length }})
      </div>
      <div class="iframeWrapper">
        <iframe
          [src]="selectedVideo.Url | safeUrl"
          frameborder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          class="youtube-iframe"
        ></iframe>
      </div>
      <div class="textWrap">
        {{ selectedVideo.Title }}
      </div>
      <div class="secondaryHeader">
        {{ selectedVideo.SecondaryHeader }}
      </div>
      <div class="steps">
        <ul class="ulSteps">
          <li *ngFor="let list of selectedVideo.TutorialContent">
            {{ list.lstSteps }}
          </li>
        </ul>
      </div>
    </div>
    <div class="rightWrap">
      <div class="tutorialCount">All Tutorials</div>
      <ul class="ulList">
        <li *ngFor="let vid of videos" (click)="onSelectedVideo(vid)">
          <div class="cardWrapList">
            <div class="videoIconList">
              <img src="{{ vid.PosterUrl }}" />
              <div
                class="overLay"
                [ngClass]="
                  selectedVideo.number == vid.number ? 'overLayActive' : ''
                "
              >
                <span> Currently Playing </span>
              </div>
            </div>
            <div class="textIconList">
              <div class="titleIconList">
                {{ vid.Title }}
              </div>
              <div class="timeIconList">
                {{ vid.timing }}
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
