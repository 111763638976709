import { NfAppExpCode, NfApplication } from '../menu.enum';
import { NfMenuConfig } from '../menu.type';

const menuName = 'MANAGE CONTENT';
// const titleName = 'Boost 360: Catalog Management';
export const NF_MANAGE_CONTENT_MENU_CONFIG: NfMenuConfig[] = [
  {
    application: NfApplication.ManageContent,
    appExpCode: NfAppExpCode.DOC,
    menu: {
      name: menuName,
      items: [
        {
          name: 'Service Catalogue',
          url: '/manage-content/all-service-catalogue',
          meta: {
            boostTitle: 'Boost 360: Catalog Management',
            jioTitle: 'JioOnline: Catalog Management'
          }
        },
        {
          name: "Doctor's Profile",
          url: '/manage-content/doctors-profile',
          meta: {
            boostTitle: 'Boost 360: Order Analytics',
            jioTitle: 'JioOnline: Order Analytics'
          }
        },
        {
          name: 'Latest Updates',
          url: '/manage-content/latest-updates',
          meta: {
            boostTitle: 'Boost 360: Latest Updates',
            jioTitle: 'JioOnline: Latest Updates'
          }
        },
        {
          name: 'Image Gallery',
          url: '/manage-content/image-gallery',
          meta: {
            boostTitle: 'Boost 360: Your business image gallery',
            jioTitle: 'JioOnline: Your business image gallery'
          }
        },
        {
          name: 'Business Profile',
          url: '/manage-content/business-profile',
          meta: {
            boostTitle: 'Boost 360: Account Details',
            jioTitle: 'JioOnline: Account Details'
          }
        },
        {
          name: 'Custom Pages',
          url: '/manage-content/custom-pages',
          meta: {
            boostTitle: 'Boost 360: Your Custom Pages',
            jioTitle: 'JioOnline: Your Custom Pages'
          }
        },
        {
          name: 'Testimonials',
          url: '/manage-content/testimonials',
          meta: {
            boostTitle: 'Boost 360: Testimonials',
            jioTitle: 'JioOnline: Testimonials'
          }
        },
        {
          name: 'Content Sharing Setting',
          url: '/manage-content/content-sharing-setting',
          meta: {
            boostTitle: 'Boost 360: Third Party Integration',
            jioTitle: 'JioOnline: Third Party Integration'
          }
        },
        {
          name: 'Re-Publish Website',
          url: '/manage-content/republish-website',
          meta: {
            boostTitle: 'Boost 360: Re-Publish',
            jioTitle: 'JioOnline: Re-Publish'
          }
        },
      ],
    },
  },
  {
    application: NfApplication.ManageContent,
    appExpCode: NfAppExpCode.HOS,
    menu: {
      name: menuName,

      items: [
        {
          name: 'Service Catalogue',
          url: '/manage-content/all-service-catalogue',
          meta: {
            boostTitle: 'Boost 360: Catalog Management',
            jioTitle: 'JioOnline: Catalog Management'
          }
        },
        {
          name: 'Staff Listing',
          url: '/manage-content/staff-listing',
          meta: {
            boostTitle: 'Boost 360: Order Analytics',
            jioTitle: 'JioOnline: Order Analytics'
          }
        },
        {
          name: 'Latest Updates',
          url: '/manage-content/latest-updates',
          meta: {
            boostTitle: 'Boost 360: Latest Updates',
            jioTitle: 'JioOnline: Latest Updates'
          }
        },
        {
          name: 'Image Gallery',
          url: '/manage-content/image-gallery',
          meta: {
            boostTitle: 'Boost 360: Your business image gallery',
            jioTitle: 'JioOnline: Your business image gallery'
          }
        },
        {
          name: 'Business Profile',
          url: '/manage-content/business-profile',
          meta: {
            boostTitle: 'Boost 360: Account Details',
            jioTitle: 'JioOnline: Account Details'
          }
        },
        {
          name: 'Custom Pages',
          url: '/manage-content/custom-pages',
          meta: {
            boostTitle: 'Boost 360: Your Custom Pages',
            jioTitle: 'JioOnline: Your Custom Pages'
          }
        },
        {
          name: 'Testimonials',
          url: '/manage-content/testimonials',
          meta: {
            boostTitle: 'Boost 360: Testimonials',
            jioTitle: 'JioOnline: Testimonials'
          }
        },
        {
          name: 'Content Sharing Setting',
          url: '/manage-content/content-sharing-setting',
          meta: {
            boostTitle: 'Boost 360: Third Party Integration',
            jioTitle: 'JioOnline: Third Party Integration'
          }
        },
        {
          name: 'Re-Publish Website',
          url: '/manage-content/republish-website',
          meta: {
            boostTitle: 'Boost 360: Re-Publish',
            jioTitle: 'JioOnline: Re-Publish'
          }
        },
      ],
    },
  },
  {
    application: NfApplication.ManageContent,
    appExpCode: NfAppExpCode.HOT,
    menu: {
      name: menuName,

      items: [
        {
          name: 'Room Catalogue',
          url: '/manage-content/room-catalogue',
          meta: {
            boostTitle: 'Boost 360: Order Analytics',
            jioTitle: 'JioOnline: Order Analytics'
          }
        },
        {
          name: 'Latest Updates',
          url: '/manage-content/latest-updates',
          meta: {
            boostTitle: 'Boost 360: Latest Updates',
            jioTitle: 'JioOnline: Latest Updates'
          }
        },
        {
          name: 'Image Gallery',
          url: '/manage-content/image-gallery',
          meta: {
            boostTitle: 'Boost 360: Your business image gallery',
            jioTitle: 'JioOnline: Your business image gallery'
          }
        },
        {
          name: 'Business Profile',
          url: '/manage-content/business-profile',
          meta: {
            boostTitle: 'Boost 360: Account Details',
            jioTitle: 'JioOnline: Account Details'
          }
        },
        {
          name: 'Custom Pages',
          url: '/manage-content/custom-pages',
          meta: {
            boostTitle: 'Boost 360: Your Custom Pages',
            jioTitle: 'JioOnline: Your Custom Pages'
          }
        },
        {
          name: 'Testimonials',
          url: '/manage-content/testimonials',
          meta: {
            boostTitle: 'Boost 360: Testimonials',
            jioTitle: 'JioOnline: Testimonials'
          }
        },
        {
          name: 'Other Sections',
          url: '/manage-content/other-sections',
          meta: {
            boostTitle: 'Boost 360: Customizations',
            jioTitle: 'JioOnline: Customizations'
          }
        },
        {
          name: 'Content Sharing Setting',
          url: '/manage-content/content-sharing-setting',
          meta: {
            boostTitle: 'Boost 360: Third Party Integration',
            jioTitle: 'JioOnline: Third Party Integration'
          }
        },
        {
          name: 'Re-Publish Website',
          url: '/manage-content/republish-website',
          meta: {
            boostTitle: 'Boost 360: Re-Publish',
            jioTitle: 'JioOnline: Re-Publish'
          }
        },
      ],
    },
  },
  {
    application: NfApplication.ManageContent,
    appExpCode: NfAppExpCode.CAF,
    menu: {
      name: menuName,

      items: [
        {
          name: 'Item Catalogue',
          url: '/manage-content/item-catalogue',
          meta: {
            boostTitle: 'Boost 360: Order Analytics',
            jioTitle: 'JioOnline: Order Analytics'
          }
        },
        {
          name: 'Latest Updates',
          url: '/manage-content/latest-updates',
          meta: {
            boostTitle: 'Boost 360: Latest Updates',
            jioTitle: 'JioOnline: Latest Updates'
          }
        },
        {
          name: 'Image Gallery',
          url: '/manage-content/image-gallery',
          meta: {
            boostTitle: 'Boost 360: Your business image gallery',
            jioTitle: 'JioOnline: Your business image gallery'
          }
        },
        {
          name: 'Business Profile',
          url: '/manage-content/business-profile',
          meta: {
            boostTitle: 'Boost 360: Account Details',
            jioTitle: 'JioOnline: Account Details'
          }
        },
        {
          name: 'Custom Pages',
          url: '/manage-content/custom-pages',
          meta: {
            boostTitle: 'Boost 360: Your Custom Pages',
            jioTitle: 'JioOnline: Your Custom Pages'
          }
        },
        {
          name: 'Testimonials',
          url: '/manage-content/testimonials',
          meta: {
            boostTitle: 'Boost 360: Testimonials',
            jioTitle: 'JioOnline: Testimonials'
          }
        },
        {
          name: 'Other Sections',
          url: '/manage-content/other-sections',
          meta: {
            boostTitle: 'Boost 360: Customizations',
            jioTitle: 'JioOnline: Customizations'
          }
        },
        {
          name: 'Content Sharing Setting',
          url: '/manage-content/content-sharing-setting',
          meta: {
            boostTitle: 'Boost 360: Third Party Integration',
            jioTitle: 'JioOnline: Third Party Integration'
          }
        },
        {
          name: 'Re-Publish Website',
          url: '/manage-content/republish-website',
          meta: {
            boostTitle: 'Boost 360: Re-Publish',
            jioTitle: 'JioOnline: Re-Publish'
          }
        },
      ],
    },
  },
  {
    application: NfApplication.ManageContent,
    appExpCode: NfAppExpCode.EDU,
    menu: {
      name: menuName,

      items: [
        {
          name: 'Courses Catalogue',
          url: '/manage-content/courses-catalogue',
          meta: {
            boostTitle: 'Boost 360: Order Analytics',
            jioTitle: 'JioOnline: Order Analytics'
          }
        },
        {
          name: 'Latest Updates',
          url: '/manage-content/latest-updates',
          meta: {
            boostTitle: 'Boost 360: Latest Updates',
            jioTitle: 'JioOnline: Latest Updates'
          }
        },
        {
          name: 'Image Gallery',
          url: '/manage-content/image-gallery',
          meta: {
            boostTitle: 'Boost 360: Your business image gallery',
            jioTitle: 'JioOnline: Your business image gallery'
          }
        },
        {
          name: 'Business Profile',
          url: '/manage-content/business-profile',
          meta: {
            boostTitle: 'Boost 360: Account Details',
            jioTitle: 'JioOnline: Account Details'
          }
        },
        {
          name: 'Custom Pages',
          url: '/manage-content/custom-pages',
          meta: {
            boostTitle: 'Boost 360: Your Custom Pages',
            jioTitle: 'JioOnline: Your Custom Pages'
          }
        },
        {
          name: 'Testimonials',
          url: '/manage-content/testimonials',
          meta: {
            boostTitle: 'Boost 360: Testimonials',
            jioTitle: 'JioOnline: Testimonials'
          }
        },
        {
          name: 'Other Sections',
          url: '/manage-content/other-sections',
          meta: {
            boostTitle: 'Boost 360: Customizations',
            jioTitle: 'JioOnline: Customizations'
          }
        },
        {
          name: 'Content Sharing Setting',
          url: '/manage-content/content-sharing-setting',
          meta: {
            boostTitle: 'Boost 360: Third Party Integration',
            jioTitle: 'JioOnline: Third Party Integration'
          }
        },
        {
          name: 'Re-Publish Website',
          url: '/manage-content/republish-website',
          meta: {
            boostTitle: 'Boost 360: Re-Publish',
            jioTitle: 'JioOnline: Re-Publish'
          }
        },
      ],
    },
  },
  {
    application: NfApplication.ManageContent,
    appExpCode: NfAppExpCode.SPA,
    menu: {
      name: menuName,

      items: [
        {
          name: 'Service Catalogue',
          url: '/manage-content/all-service-catalogue',
          meta: {
            boostTitle: 'Boost 360: Catalog Management',
            jioTitle: 'JioOnline: Catalog Management'
          }
        },
        // {
        //   name: 'Booking Setup',
        //   url: '/manage-content/booking-setup',
        // meta: {
        //   boostTitle: 'Boost 360: Booking Setup',
        //   jioTitle: 'JioOnline: Booking Setup'
        // }
        // },
        {
          name: 'Latest Updates',
          url: '/manage-content/latest-updates',
          meta: {
            boostTitle: 'Boost 360: Latest Updates',
            jioTitle: 'JioOnline: Latest Updates'
          }
        },
        {
          name: 'Image Gallery',
          url: '/manage-content/image-gallery',
          meta: {
            boostTitle: 'Boost 360: Your business image gallery',
            jioTitle: 'JioOnline: Your business image gallery'
          }
        },
        {
          name: 'Business Profile',
          url: '/manage-content/business-profile',
          meta: {
            boostTitle: 'Boost 360: Account Details',
            jioTitle: 'JioOnline: Account Details'
          }
        },
        {
          name: 'Custom Pages',
          url: '/manage-content/custom-pages',
          meta: {
            boostTitle: 'Boost 360: Your Custom Pages',
            jioTitle: 'JioOnline: Your Custom Pages'
          }
        },
        {
          name: 'Testimonials',
          url: '/manage-content/testimonials',
          meta: {
            boostTitle: 'Boost 360: Testimonials',
            jioTitle: 'JioOnline: Testimonials'
          }
        },
        {
          name: 'Other Sections',
          url: '/manage-content/other-sections',
          meta: {
            boostTitle: 'Boost 360: Customizations',
            jioTitle: 'JioOnline: Customizations'
          }
        },
        {
          name: 'Content Sharing Setting',
          url: '/manage-content/content-sharing-setting',
          meta: {
            boostTitle: 'Boost 360: Third Party Integration',
            jioTitle: 'JioOnline: Third Party Integration'
          }
        },
        {
          name: 'Re-Publish Website',
          url: '/manage-content/republish-website',
          meta: {
            boostTitle: 'Boost 360: Re-Publish',
            jioTitle: 'JioOnline: Re-Publish'
          }
        },
      ],
    },
  },
  {
    application: NfApplication.ManageContent,
    appExpCode: NfAppExpCode.SAL,
    menu: {
      name: menuName,

      items: [
        {
          name: 'Service Catalogue',
          url: '/manage-content/all-service-catalogue',
          meta: {
            boostTitle: 'Boost 360: Catalog Management',
            jioTitle: 'JioOnline: Catalog Management'
          }
        },
        // {
        //   name: 'Booking Setup',
        //   url: '/manage-content/booking-setup',
        // meta: {
        //   boostTitle: 'Boost 360: Booking Setup',
        //   jioTitle: 'JioOnline: Booking Setup'
        // }
        // },
        {
          name: 'Latest Updates',
          url: '/manage-content/latest-updates',
          meta: {
            boostTitle: 'Boost 360: Latest Updates',
            jioTitle: 'JioOnline: Latest Updates'
          }
        },
        {
          name: 'Image Gallery',
          url: '/manage-content/image-gallery',
          meta: {
            boostTitle: 'Boost 360: Your business image gallery',
            jioTitle: 'JioOnline: Your business image gallery'
          }
        },
        {
          name: 'Business Profile',
          url: '/manage-content/business-profile',
          meta: {
            boostTitle: 'Boost 360: Account Details',
            jioTitle: 'JioOnline: Account Details'
          }
        },
        {
          name: 'Custom Pages',
          url: '/manage-content/custom-pages',
          meta: {
            boostTitle: 'Boost 360: Your Custom Pages',
            jioTitle: 'JioOnline: Your Custom Pages'
          }
        },
        {
          name: 'Other Sections',
          url: '/manage-content/other-sections',
          meta: {
            boostTitle: 'Boost 360: Customizations',
            jioTitle: 'JioOnline: Customizations'
          }
        },
        {
          name: 'Content Sharing Setting',
          url: '/manage-content/content-sharing-setting',
          meta: {
            boostTitle: 'Boost 360: Third Party Integration',
            jioTitle: 'JioOnline: Third Party Integration'
          }
        },
        {
          name: 'Re-Publish Website',
          url: '/manage-content/republish-website',
          meta: {
            boostTitle: 'Boost 360: Re-Publish',
            jioTitle: 'JioOnline: Re-Publish'
          }
        },
      ],
    },
  },
  {
    application: NfApplication.ManageContent,
    appExpCode: NfAppExpCode.MFG,
    menu: {
      name: menuName,

      items: [
        {
          name: 'Product Catalogue',
          url: '/manage-content/product-catalogue',
          meta: {
            boostTitle: 'Boost 360: Catalog Management',
            jioTitle: 'JioOnline: Catalog Management'
          }
        },
        {
          name: 'Latest Updates',
          url: '/manage-content/latest-updates',
          meta: {
            boostTitle: 'Boost 360: Latest Updates',
            jioTitle: 'JioOnline: Latest Updates'
          }
        },
        {
          name: 'Image Gallery',
          url: '/manage-content/image-gallery',
          meta: {
            boostTitle: 'Boost 360: Your business image gallery',
            jioTitle: 'JioOnline: Your business image gallery'
          }
        },
        {
          name: 'Business Profile',
          url: '/manage-content/business-profile',
          meta: {
            boostTitle: 'Boost 360: Account Details',
            jioTitle: 'JioOnline: Account Details'
          }
        },
        {
          name: 'Custom Pages',
          url: '/manage-content/custom-pages',
          meta: {
            boostTitle: 'Boost 360: Your Custom Pages',
            jioTitle: 'JioOnline: Your Custom Pages'
          }
        },
        {
          name: 'Testimonials',
          url: '/manage-content/testimonials',
          meta: {
            boostTitle: 'Boost 360: Testimonials',
            jioTitle: 'JioOnline: Testimonials'
          }
        },
        {
          name: 'Other Sections',
          url: '/manage-content/other-sections',
          meta: {
            boostTitle: 'Boost 360: Customizations',
            jioTitle: 'JioOnline: Customizations'
          }
        },
        {
          name: 'Content Sharing Setting',
          url: '/manage-content/content-sharing-setting',
          meta: {
            boostTitle: 'Boost 360: Third Party Integration',
            jioTitle: 'JioOnline: Third Party Integration'
          }
        },
        {
          name: 'Re-Publish Website',
          url: '/manage-content/republish-website',
          meta: {
            boostTitle: 'Boost 360: Re-Publish',
            jioTitle: 'JioOnline: Re-Publish'
          }
        },
      ],
    },
  },
  {
    application: NfApplication.ManageContent,
    appExpCode: NfAppExpCode.RTL,
    menu: {
      name: menuName,

      items: [
        {
          name: 'Product Catalogue',
          url: '/manage-content/product-catalogue',
          meta: {
            boostTitle: 'Boost 360: Catalog Management',
            jioTitle: 'JioOnline: Catalog Management'
          }
        },
        {
          name: 'Latest Updates',
          url: '/manage-content/latest-updates',
          meta: {
            boostTitle: 'Boost 360: Latest Updates',
            jioTitle: 'JioOnline: Latest Updates'
          }
        },
        {
          name: 'Image Gallery',
          url: '/manage-content/image-gallery',
          meta: {
            boostTitle: 'Boost 360: Your business image gallery',
            jioTitle: 'JioOnline: Your business image gallery'
          }
        },
        {
          name: 'Business Profile',
          url: '/manage-content/business-profile',
          meta: {
            boostTitle: 'Boost 360: Account Details',
            jioTitle: 'JioOnline: Account Details'
          }
        },
        {
          name: 'Custom Pages',
          url: '/manage-content/custom-pages',
          meta: {
            boostTitle: 'Boost 360: Your Custom Pages',
            jioTitle: 'JioOnline: Your Custom Pages'
          }
        },
        {
          name: 'Testimonials',
          url: '/manage-content/testimonials',
          meta: {
            boostTitle: 'Boost 360: testimonials',
            jioTitle: 'JioOnline: testimonials'
          }
        },
        // {
        //   name: 'Other Sections',
        //   url: '/manage-content/other-sections',
        //   meta: {
        //     boostTitle: 'Boost 360: Customizations',
        //     jioTitle: 'JioOnline: Customizations'
        //   }
        // },
        {
          name: 'Content Sharing Setting',
          url: '/manage-content/content-sharing-setting',
          meta: {
            boostTitle: 'Boost 360: Third Party Integration',
            jioTitle: 'JioOnline: Third Party Integration'
          }
        },
        {
          name: 'Re-Publish Website',
          url: '/manage-content/republish-website',
          meta: {
            boostTitle: 'Boost 360: Re-Publish',
            jioTitle: 'JioOnline: Re-Publish'
          }
        },
      ],
    },
  },
  {
    application: NfApplication.ManageContent,
    appExpCode: NfAppExpCode.SVC,
    menu: {
      name: menuName,

      items: [
        {
          name: 'Service Catalogue',
          url: '/manage-content/all-service-catalogue',
          meta: {
            boostTitle: 'Boost 360: Order Analytics',
            jioTitle: 'JioOnline: Order Analytics'
          }
        },
        {
          name: 'Booking Setup',
          url: '/manage-content/booking-setup',
          meta: {
            boostTitle: 'Boost 360: Booking Setup',
            jioTitle: 'JioOnline: Booking Setup'
          }
        },
        {
          name: 'Latest Updates',
          url: '/manage-content/latest-updates',
          meta: {
            boostTitle: 'Boost 360: Latest Updates',
            jioTitle: 'JioOnline: Latest Updates'
          }
        },
        {
          name: 'Image Gallery',
          url: '/manage-content/image-gallery',
          meta: {
            boostTitle: 'Boost 360: Your business image gallery',
            jioTitle: 'JioOnline: Your business image gallery'
          }
        },
        {
          name: 'Business Profile',
          url: '/manage-content/business-profile',
          meta: {
            boostTitle: 'Boost 360: Account Details',
            jioTitle: 'JioOnline: Account Details'
          }
        },
        {
          name: 'Custom Pages',
          url: '/manage-content/custom-pages',
          meta: {
            boostTitle: 'Boost 360: Your Custom Pages',
            jioTitle: 'JioOnline: Your Custom Pages'
          }
        },
        {
          name: 'Testimonials',
          url: '/manage-content/testimonials',
          meta: {
            boostTitle: 'Boost 360: Testimonials',
            jioTitle: 'JioOnline: Testimonials'
          }
        },
        {
          name: 'Other Sections',
          url: '/manage-content/other-sections',
          meta: {
            boostTitle: 'Boost 360: Customizations',
            jioTitle: 'JioOnline: Customizations'
          }
        },
        {
          name: 'Content Sharing Setting',
          url: '/manage-content/content-sharing-setting',
          meta: {
            boostTitle: 'Boost 360: Third Party Integration',
            jioTitle: 'JioOnline: Third Party Integration'
          }
        },
        {
          name: 'Re-Publish Website',
          url: '/manage-content/republish-website',
          meta: {
            boostTitle: 'Boost 360: Re-Publish',
            jioTitle: 'JioOnline: Re-Publish'
          }
        },
      ],
    },
  },
];
