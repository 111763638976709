export class AddressDetails {
  City?: string;
  Country?: string;
  Line1?: string;
  State?: string;
}

export class BusinessDetails {
  CountryCode?: string;
  Email?: string;
  PhoneNumber?: string;
}

export class TaxDetails {
  GSTIN!: string;
}

export class GST {
  Gst?: string;
  isAvlGst?: boolean;
}

export class BillingDetails {
  AddressDetails = new AddressDetails();
  BusinessDetails = new BusinessDetails();
  ClientId?: string;
  CountryCode?: string;
  DeviceType?: string;
  Email!: string;
  InternalSourceId?: string;
  MobileNumber?: string;
  Name?: string;
  TaxDetails = new TaxDetails();
  gstNumber?: string;
}

export class BillingDetailsRes {
  StatusCode?: number;
  Error = new Error();
  Result = new BillingDetails();
}
