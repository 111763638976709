<div class="loader-container">
  <div class="loader">
    <img
      class="loader-spinner"
      src="assets/urlimg/loading-spinner-grey.gif"
      alt="spinner"
    />
    <p class="loader-text">
      {{ loaderText ? loaderText : 'Loading...' }}
    </p>
  </div>
</div>
