import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'nf-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class NfLoaderComponent implements OnInit {
  @Input() loaderText?: string;

  constructor() {}

  ngOnInit(): void {
    // console.log(this.loaderText);
  }
}
