import { Injectable } from '@angular/core';
import { MlcService } from 'projects/main-app/src/app/services/mlc.service';
import { LocalStorageKeys } from 'projects/main-app/src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MerchantService {
  private merchantData$ = new BehaviorSubject<any>(null);

  constructor(private mlcService: MlcService) {}

  get getMerchantData() {
    return this.merchantData$.asObservable();
  }

  setMerchantData(data: any) {
    // dummy
    //  data[0].drs_total = 85;
    if (this.mlcService.isMLCStore) {
      sessionStorage.setItem(LocalStorageKeys.drs_total, data[0].drs_total);
    } else localStorage.setItem(LocalStorageKeys.drs_total, data[0].drs_total);
    this.merchantData$.next(data);
  }

  private merchantData2$ = new BehaviorSubject<any>(null);

  get getMerchantDetailByID() {
    return this.merchantData2$.asObservable();
  }

  setMerchantDetailByID(data: any) {
    this.merchantData2$.next(data);
  }
}
