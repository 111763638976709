import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConsumerData } from '../model/consumerData';

@Injectable({
  providedIn: 'root',
})
export class ConsumerService {
  // customerDetails: any;
  private consumerDetails = new BehaviorSubject<any>(null);
  private clientDetails = new BehaviorSubject<any>(null);

  constructor() {}

  setConsumerData(consumerDetails: any) {
    this.consumerDetails.next(consumerDetails);
  }

  get getConsumerData() {
    return this.consumerDetails.asObservable();
  }

  setClientDetails(clientDetails: any) {
    this.clientDetails.next(clientDetails);
  }

  get getClientDetails() {
    return this.clientDetails.asObservable();
  }
}
