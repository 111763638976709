import { Feature } from './../model/home';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  private _feature = new BehaviorSubject<any>(null);
  private _pack = new BehaviorSubject<any>(null);
  private _feature2 = new BehaviorSubject<any>(null);
  private _renewHistory = new BehaviorSubject<any>(null);

  constructor() {}

  setRenewHistory(data: any) {
    this._renewHistory.next(data);
  }
  get getRenewHistory(): Observable<any> {
    return this._renewHistory.asObservable();
  }

  setFeatures(feature: any) {
    this._feature.next(feature);
  }
  setPackage(pack: any) {
    this._pack.next(pack);
  }
  setFeaturesArray(feature: Feature[]) {
    this._feature2.next(feature);
  }
  get getFeatureArray(): Observable<Feature[]> {
    return this._feature2.asObservable();
  }
  get getFeature(): Observable<Feature> {
    return this._feature.asObservable();
  }

  get getPack(): Observable<Feature> {
    return this._pack.asObservable();
  }
}
