import { Injectable } from '@angular/core';
import { flatten, unflatten } from 'flat';

import { NfCoreLibService } from '../core-lib.service';
import { NF_CLIENT_CONFIG } from './client.config';
import { NfClient } from './client.enum';
import { NfClientConfig } from './client.type';

@Injectable({ providedIn: 'root' })
export class NfClientService {
  private _clients: NfClientConfig[] = [];

  constructor(private coreLibService: NfCoreLibService) {
    this._setClients();
  }

  /**
   * This method returns the default client
   * which is boost client
   *
   * @returns NfClientConfig object
   */
  public defaultClientConfig(): NfClientConfig {
    const defaultClient: NfClientConfig = NF_CLIENT_CONFIG[NfClient.Boost];

    defaultClient.clientId = this.coreLibService.coreConfig.production
      ? defaultClient.prodClientId!
      : defaultClient.stageClientId!;

    return defaultClient;
  }

  /**
   * This method returns the current client
   *
   * @returns NfClientConfig object
   */
  public currentClientConfig(): NfClientConfig {
    return this.findConfigByClient(this.coreLibService.coreConfig.client)!;
  }

  /**
   * This method finds the NfClientConfig according
   * to the given parameter
   *
   * @param client type of client Boost, Jio, etc.
   * @returns NfClientConfig object or undefined
   */
  public findConfigByClient(client: NfClient) {
    return this._clients.find((_client) => _client.client === client);
  }

  public updateConfigByClient(
    client: NfClient,
    config: Partial<NfClientConfig>
  ) {
    const index = this._clients.findIndex(
      (_client) => _client.client === client
    );
    if (index >= 0) {
      const updatedConfig: any = flatten({ ...config });
      const existingConfig: any = flatten({ ...this._clients[index] });
      Object.keys(updatedConfig).forEach((key) => {
        existingConfig[key] = updatedConfig[key];
      });
      this._clients[index] = unflatten(existingConfig);
      return this._clients[index];
    }
    return;
  }

  private _setClients() {
    this._clients = NF_CLIENT_CONFIG;
    const config = this.findConfigByClient(
      this.coreLibService.coreConfig.client
    );
    const clientId = this.coreLibService.coreConfig.production
      ? config?.prodClientId
      : config?.stageClientId;
    this.updateConfigByClient(this.coreLibService.coreConfig.client, {
      clientId,
    });
  }
}
