<div class="addons-detail-modal-wrapper">
  <div
    class="addons-detail-header"
    *ngIf="cartService.typeOfPayment == 'AutoRenew'"
  >
    <i class="modal-backicon">
      <!-- <img src="assets/images/modal-back-icon.svg"> -->
    </i>
    Auto Renewal Subscription
    <span class="modal-close" (click)="dialogRef.close()">
      <img src="assets/images/modal-close-icon.svg" />
    </span>
  </div>

  <div
    class="addons-detail-header"
    *ngIf="cartService.typeOfPayment != 'AutoRenew'"
  >
    <i class="modal-backicon">
      <!-- <img src="assets/images/modal-back-icon.svg"> -->
    </i>
    PAYMENT SUCCESS!
    <span class="modal-close" (click)="dialogRef.close()">
      <img src="assets/images/modal-close-icon.svg" />
    </span>
  </div>

  <div class="recommended-wrapper">
    <div class="auto-renewal-content">
      <img class="success-icon" src="assets/images/icon-order-success.svg" />
      <h3>Order Successfully placed</h3>
      <h6 *ngIf="nameArr && nameArr.length">
        <span>
          {{ nameArr[0] }}
        </span>
        <span *ngIf="nameArr && nameArr.length > 1">
          + {{ nameArr.length - 1 }} features
        </span>

        For
        {{
          cartService.totalAmountWithGST
            ? cartService.totalAmountWithGST
            : (0 | currency: '₹')
        }}/month
      </h6>
      <p
        *ngIf="
          getPurchase &&
          getPurchase.razorpay_order_id &&
          getPurchase.razorpay_payment_id
        "
      >
        Order placed on Order ID #{{
          getPurchase.razorpay_order_id
        }}
        Transaction ID #{{ getPurchase.razorpay_payment_id }}
      </p>
    </div>

    <!-- <div class="renewal-on" *ngIf="cartService.typeOfPayment == 'remindRenew'">
            <img src="assets/images/calender-icon-success.svg">
            <div class="renewal-on-text">
                <h4>Auto Renewal is ON</h4>
                <p>
                    Using HDFC Bank Credit card ending with 5675
                    Next auto-renew date is 12 Jan 2020
                </p>
            </div>
        </div> -->

    <!-- <div class="button-bx">
            <button type="button" class="btn-yellow">CHECK ACTIVATION STATUS</button>
        </div> -->

    <!-- <a class="need-help" href="">Need Help?</a> -->
  </div>
</div>
