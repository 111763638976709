export const URL = {
  JioURL:
    'https://us-central1-jioonline-prod.cloudfunctions.net/onUpdateDrsMetricsPostApi',
  BoostURL:
    'https://us-central1-nowfloats-boost.cloudfunctions.net/onUpdateDrsMetricsPostApi',
};

export const JioID = {
  stageId: 'D01489A9F554586895577B2569EFA9B1D13BB4423A473E79AB54DD424124BE70',
  prodId: '2D5C6BB4F46457422DA36B4977BD12E37A92EEB13BB4423A548387BA54DCEBD5',
};
