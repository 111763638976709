import { Directive, ElementRef, OnInit } from '@angular/core';
import { NfClient, NfClientService } from '@nf/core';

@Directive({
  selector: '[boost]',
})
export class BoostDirective implements OnInit {
  constructor(private el: ElementRef, private clientService: NfClientService) {}

  ngOnInit(): void {
    if (this.clientService.currentClientConfig().client != NfClient.Boost)
      this.el.nativeElement.style.display = 'none';
  }
}
