import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FxLayoutDirective } from './directives/fx-layout.directive';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ToasterService } from './services/toaster.service';

@NgModule({
  declarations: [FxLayoutDirective],
  imports: [CommonModule, MatSnackBarModule],
  exports: [FxLayoutDirective, CommonModule],
  providers: [ToasterService],
})
export class SharedModule {}
