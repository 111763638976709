export class ErrorList {}

export class Error {
  ErrorCode?: any | undefined;
  ErrorList: ErrorList | undefined;
}

export class ProductClassification {
  packType: number | undefined;
  productLine: number[] | undefined;
}

export class SupportedPaymentMethod {
  PaymentSource: string | undefined;
  Type: number | undefined;
  TargetPaymentUri?: any | undefined;
  Key: string | undefined;
  Secret: string | undefined;
  RedirectUri: string | undefined;
  WebHookUri: string | undefined;
  PaddleCoupon?: any | undefined;
}

export class Tax {
  Value: number | undefined;
  Key: string | undefined;
  AmountType: number | undefined;
}

export class UnitVariant {
  unitType: number | undefined;
  price: number | undefined;
}

export class ExternalApplicationDetail {
  Type: number | undefined;
  ExternalSourceId: string | undefined;
}

export class Property {
  Key: string | undefined;
  Value: string | undefined;
}

export class WidgetPack {
  Name: string | undefined;
  Desc: string | undefined;
  ExpiryInMths: number | undefined;
  WidgetKey: string | undefined;
  Properties: Property[] | undefined;
  CreatedOn: Date | undefined;
  ExpiryDate: Date | undefined;
  ExpiryInDays: number | undefined;
  Price: number | undefined;
  Discount: number | undefined;
}

export class Screenshot {
  imageUri: string | undefined;
  imageDesc?: any | undefined;
}

export class PurchasedPackageDetails {
  _id: string | undefined;
  productClassification: ProductClassification | undefined;
  Identifier: string | undefined;
  IsArchived: boolean | undefined;
  SupportedPaymentMethods: SupportedPaymentMethod[] | undefined;
  Taxes: Tax[] | undefined;
  revenueShare?: any | undefined;
  CreatedOn: Date | undefined;
  unitVariants: UnitVariant[] | undefined;
  packageCategory?: any | undefined;
  ExternalApplicationDetails: ExternalApplicationDetail[] | undefined;
  Price: number | undefined;
  ExpiryInMths: number | undefined;
  CurrencyCode: string | undefined;
  Name: string | undefined;
  Desc: string | undefined;
  Type: number | undefined;
  ValidCountry: string[] | undefined;
  ValidCity?: any | undefined;
  Priority: number | undefined;
  ValidityInMths: number | undefined;
  WidgetPacks: WidgetPack[] | undefined;
  Screenshots: Screenshot[] | undefined;
  PrimaryImageUri: string | undefined;
  maxDiscount?: any | undefined;
  renewalChannelId?: any | undefined;
  upSellChannelId?: any | undefined;
  supportChannelId?: any | undefined;
  packageVisibilityType: number | undefined;
}

export class Result {
  _id: string | undefined;
  CreatedOn: Date | undefined;
  ToBeActivatedOn: Date | undefined;
  externalSourceId?: any | undefined;
  _nfInternalERPInvoiceId?: any | undefined;
  _nfInternalERPId?: any | undefined;
  _nfInternalERPSaleDate: Date | undefined;
  invoiceUrl?: any | undefined;
  isActive: boolean | undefined;
  markedForDeletion: boolean | undefined;
  packageSaleTransactionType: number | undefined;
  purchasedPackageDetails: PurchasedPackageDetails | undefined;
  discount: number | undefined;
  baseAmount: number | undefined;
  taxAmount: number | undefined;
  isDynamicPackage: boolean | undefined;
  paymentTransactionId?: any | undefined;
  IsPartOfComboPlan: boolean | undefined;
  ComboPackageId: string | undefined;
  clientId: string | undefined;
  clientProductId: string | undefined;
  NameOfWidget: string | undefined;
  widgetKey: string | undefined;
  paidAmount: number | undefined;
  fpId: string | undefined;
  totalMonthsValidity: number | undefined;
  currencyCode: string | undefined;
  orderId?: any | undefined;
  PaymentMethod?: any | undefined;
}

export class RenewalHistory {
  StatusCode: number | undefined;
  Error: Error | undefined;
  Result = new Array<Result>();
}
