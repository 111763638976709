import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TruncatePipe } from './truncate.pipe';
import { SafeUrlPipe } from './safeUrl.pipe';
import { FormsModule } from '@angular/forms';
import { AddonsComponent } from '../../my-addons/addons/addons.component';
import { AddonsDetailModalComponent } from '../../modals/addons-detail-modal/addons-detail-modal.component';
import { RecommendationsModalComponent } from '../../modals/recommendations-modal/recommendations-modal.component';
import { CompareModalComponent } from '../../modals/compare-modal/compare-modal.component';
import { CartSummaryComponent } from '../../cart-summary/cart-summary.component';
import { RequestCallbackModalComponent } from '../../modals/request-callback-modal/request-callback-modal.component';
import { RenewalHistoryModalComponent } from '../../modals/renewal-history-modal/renewal-history-modal.component';
import { OrderHistoryModalComponent } from '../../modals/order-history-modal/order-history-modal.component';
import { AutoRenewSubscriptionModalComponent } from '../../modals/auto-renew-subscription-modal/auto-renew-subscription-modal.component';
import { RenewSubscriptionModalComponent } from '../../modals/renew-subscription-modal/renew-subscription-modal.component';
import { PaymentSuccessModalComponent } from '../../modals/payment-success-modal/payment-success-modal.component';
import { UnauthorishedAccessPageComponent } from '../../unauthorished-access-page/unauthorished-access-page.component';
import { ReferComponent } from '../../modals/refer/refer.component';
import { WebsiteModalComponent } from '../../modals/website-modal/website-modal.component';
// import { InitialLoaderComponent } from '../../initial-loader/initial-loader.component';
import { ToastrModule } from 'ngx-toastr';
import { LottieModule } from 'ngx-lottie';
import { playerFactory } from '../../app.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MatTabsModule } from '@angular/material/tabs';
import { BillingDetailsComponent } from '../../modals/billing-details/billing-details.component';
import { NfButtonModule, NfLoaderModule } from '@nf/ui';
import { NgDialogAnimationService } from 'ng-dialog-animation';
@NgModule({
  declarations: [
    TruncatePipe,
    SafeUrlPipe,
    AddonsComponent,
    AddonsDetailModalComponent,
    RecommendationsModalComponent,
    CompareModalComponent,
    CartSummaryComponent,
    RequestCallbackModalComponent,
    RenewalHistoryModalComponent,
    OrderHistoryModalComponent,
    AutoRenewSubscriptionModalComponent,
    RenewSubscriptionModalComponent,
    PaymentSuccessModalComponent,
    UnauthorishedAccessPageComponent,
    ReferComponent,
    WebsiteModalComponent,
    // InitialLoaderComponent,
    BillingDetailsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    CarouselModule,
    ToastrModule.forRoot(),
    LottieModule.forRoot({ player: playerFactory }),
    MatTabsModule,
    NfLoaderModule,
    NfButtonModule,
  ],
  exports: [TruncatePipe, SafeUrlPipe],
  providers: [NgDialogAnimationService],
})
export class SharedModule {}
