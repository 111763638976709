<div class="page-modal">
  <div class="modal-body">
    <div class="label">Have an existing domain</div>
    <div class="buttons-wrap">
      <div class="content">
        If you have an existing domain, we strongly recommend that you use it
        for your NowFloats Website. Online discovery will be faster as search
        engines already recognize your domain. Choose from the options below and
        send us a request for mapping your existing domain:
      </div>
      <br />
      <div class="content">
        <div class="custom-control custom-radio">
          <input
            id="existingDomain"
            type="radio"
            class="custom-control-input input-circle"
            value="existingDomain"
            name="domain"
            [(ngModel)]="domainSubject"
          />
          <label class="custom-control-label" for="existingDomain"
            >Point your existing domain to NowFloats website.</label
          >
        </div>
        <br />
        <div class="custom-control custom-radio">
          <input
            id="subDomain"
            type="radio"
            class="custom-control-input input-circle"
            value="subDomain"
            name="domain"
            [(ngModel)]="domainSubject"
          />
          <label class="custom-control-label" for="subDomain"
            >Put your NowFloats website as a sub domain.</label
          >
        </div>
      </div>
      <div class="textAreaWrap w-1/1">
        <textarea
          id="domainDescription"
          style="resize: none; min-height: 100px"
          rows="8"
          type="text"
          name="domainDescription"
          class="form-control border-radius"
          [(ngModel)]="domainDescription"
        ></textarea>
      </div>
      <div class="modal-footer" id="update-modal-footer">
        <div id="modal-footer-buttons" class="">
          <button class="btn modal-footer-cancel-btn" (click)="closeModal()">
            Back
          </button>
        </div>
        <div id="modal-footer-buttons" class="ml-4">
          <button class="btn modal-footer-post-btn" (click)="activateDomain()">
            Submit
          </button>
        </div>
      </div>
      <!-- <button class="btn btn-default">CANCEL</button>
        <button class="btn btn-yellow">BOOK A NEW DOMAIN</button> -->
    </div>
  </div>
</div>
