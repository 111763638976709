import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'nf-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class NfPageHeaderComponent implements OnInit {
  @Input() headerText?: string;
  public count?: number;
  @Input() set totalCount(value: number) {
    this.count = value;
  }
  constructor() {}
  // set allowDay(value: boolean) {
  //   this._allowDay = value;
  ngOnInit(): void {}

  ngOnChanges(): void {}
}
