import { CanAutoUnsubscribe, NfApiParams, NfApiService } from '@nf/common';
import { URL, JioID } from '../configs/config';
import { Subscription } from 'rxjs';
import { CheckUtil } from './check-utils';

@CanAutoUnsubscribe
export class DRScoreUpdate {
  private static UpdateDrScoreJioOnlineUrl = URL.JioURL;
  private static UpdateDrScoreBoostUrl = URL.BoostURL;

  public static updateDRSSub?: Subscription;

  public static markEventTrue(
    service: NfApiService,
    clientId: string,
    fpTag: string,
    metricKey: string
  ) {
    if (CheckUtil.isNullEmptyString(fpTag)) {
      return;
    }
    let drScoreUpdate = {
      client_id: clientId,
      fp_tag: fpTag,
      key: metricKey,
      value: true,
    };

    this.updateDRScoreEvent(service, drScoreUpdate);
  }

  public static setEventValue(
    service: NfApiService,
    clientId: string,
    fpTag: string,
    metricKey: string,
    value: number
  ) {
    if (CheckUtil.isNullEmptyString(metricKey)) {
      return;
    }
    let drScoreUpdate = {
      client_id: clientId,
      fp_tag: fpTag,
      key: metricKey,
      value: value,
    };
    this.updateDRScoreEvent(service, drScoreUpdate);
  }

  public static updateDRScoreEvent(service: NfApiService, body: any) {
    let url = this.getUrlByClientId(body.client_id);
    this.updateDRSSub = service
      .post(body, {
        config: {
          baseUrl: url,
        },
        apiPath: '',
      })
      .subscribe(
        (res: any) => {
          console.log(res);
        },
        (err) => {}
      );
  }

  static getUrlByClientId(id: string) {
    if (id == JioID.stageId || id == JioID.prodId) {
      return this.UpdateDrScoreJioOnlineUrl;
    }
    return this.UpdateDrScoreBoostUrl;
  }
}
