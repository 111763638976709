export class CouponSystem {
  cartValue: string | any;
  couponCode: string | any;
  fpId: string | any;
}

export class CouponSystemResponse {
  success: boolean | any;
  message: string | any;
  discountAmount: number | any;
}
