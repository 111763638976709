<div class="modal-container">
  <div class="modal-header">
    <div class="close">
      <mat-icon (click)="closeModal()">close</mat-icon>
    </div>
    <div class="title">select store</div>
  </div>

  <div class="modal-body">
    <div class="tab-content">
      <nf-tabs [dataArray]="tabs" (tabSelected)="tabSelected($event)"></nf-tabs>
      <div class="active-tab" *ngIf="activeLink == tabs[0].key">
        <div class="search-store">
          <input
            type="text"
            placeholder="Type store name..."
            [(ngModel)]="searchActive"
          />
        </div>
        <div class="stores">
          <a
            *ngFor="let item of activeAuthToken | searchFilter: searchActive"
            [routerLink]="['/dashboard']"
            [queryParams]="{ storeId: item.FloatingPointId }"
            target="_blank"
            class="store-item"
          >
            <div class="right-side">
              <div class="state pr-2">
                <mat-icon class="state-icon active"
                  >fiber_manual_record</mat-icon
                >
              </div>
              <div class="details">
                <div class="store-name">{{ item.Name }}</div>
                <div class="store-address">({{ item.Address }})</div>
              </div>
            </div>
            <div class="left-side">
              <span>open</span>
              <mat-icon>open_in_new</mat-icon>
            </div>
          </a>
        </div>
      </div>
      <div class="expired-tab" *ngIf="activeLink == tabs[1].key">
        <div class="search-store">
          <input
            type="text"
            placeholder="Type store name..."
            [(ngModel)]="searchDeActive"
          />
        </div>
        <div class="stores">
          <a
            *ngFor="
              let item of deActiveAuthToken | searchFilter: searchDeActive
            "
            [routerLink]="['/dashboard']"
            [queryParams]="{ storeId: item.FloatingPointId }"
            class="store-item"
          >
            <div class="right-side">
              <div class="state pr-2">
                <mat-icon class="state-icon de-active"
                  >fiber_manual_record</mat-icon
                >
              </div>
              <div class="details">
                <div class="store-name">{{ item.Name }}</div>
                <div class="store-address">({{ item.Address }})</div>
              </div>
            </div>
            <div class="left-side">
              <span>open</span>
              <mat-icon>open_in_new</mat-icon>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
