import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageKeys } from 'projects/main-app/src/environments/environment';
import { MlcService } from '../../services/mlc.service';

@Component({
  selector: 'main-website-updated-modal',
  templateUrl: './website-updated-modal.component.html',
  styleUrls: ['./website-updated-modal.component.scss'],
})
export class WebsiteUpdatedModalComponent implements OnInit {
  URL = '';

  constructor(
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<WebsiteUpdatedModalComponent>,
    private mlcService: MlcService
  ) {
    const authToken = localStorage.getItem(LocalStorageKeys.auth_token);

    if (this.mlcService.isMLCStore && this.mlcService.rootAliasUri) {
      this.URL = this.mlcService.rootAliasUri;
    } else {
      if (authToken && authToken.length)
        this.URL = JSON.parse(authToken).RootAliasUri;
    }
  }

  ngOnInit(): void {}

  closeModal() {
    this.dialogRef.close();
  }

  copyToClipBoard() {
    this.toastr.success('Link copied successfully', 'Link Copied', {
      positionClass: 'toast-bottom-right',
    });
  }
}
