import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'setting-domain-not-allowed',
  templateUrl: './domain-not-allowed.component.html',
  styleUrls: ['./domain-not-allowed.component.scss'],
})
export class DomainNotAllowedComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<DomainNotAllowedComponent>) {}

  ngOnInit(): void {}

  closeModal() {
    this.dialogRef.close();
  }
}
