import { NfMenuConfig } from '../menu.type';
import { MLC_DASHBOARD_MENU_CONFIG } from './mlc-dashboard-menu-config';
import { MLC_ECOMMERCE_MENU_CONFIG } from './mlc-ecommerce-menu.config';
import { MLC_MANAGE_CONTENT_MENU_CONFIG } from './mlc-manage-content-menu.config';
import { MLC_SETTING_MENU_CONFIG } from './mlc-setting-menu.config';

export const MLC_MENU_CONFIG: NfMenuConfig[] = [
  ...MLC_DASHBOARD_MENU_CONFIG,
  ...MLC_MANAGE_CONTENT_MENU_CONFIG,
  ...MLC_ECOMMERCE_MENU_CONFIG,
  ...MLC_SETTING_MENU_CONFIG,
];
