import { NfAppExpCode } from '@nf/core';

export function getTestimonialPermission(appExp: string): boolean {
  switch (appExp) {
    case NfAppExpCode.HOT:
      return false;

    case NfAppExpCode.MFG:
      return false;

    case NfAppExpCode.DOC:
      return false;

    case NfAppExpCode.HOS:
      return false;

    case NfAppExpCode.CAF:
      return false;

    case NfAppExpCode.EDU:
      return true;

    case NfAppExpCode.SPA:
      return false;

    case NfAppExpCode.SVC:
      return false;

    case NfAppExpCode.SAL:
      return false;

    case NfAppExpCode.RTL:
      return true;

    default:
      return false;
  }
}
