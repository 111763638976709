import { PriceManager } from './../../services/price-manager.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FeatureService } from './../../services/features-service.service';
import { CartServiceService } from './../../services/cart-service.service';
import { Subscription } from 'rxjs';
import { ConsumerService } from './../../services/consumer.service';
import { WebengageService } from './../../services/webengage.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-addons-detail-modal',
  templateUrl: './addons-detail-modal.component.html',
  styleUrls: ['./addons-detail-modal.component.scss'],
  providers: [MatSnackBar],
})
export class AddonsDetailModalComponent implements OnInit, OnDestroy {
  public bgColor: any;
  public isPackage: boolean = false;
  public isBack: boolean = false;
  constructor(
    public featureService: FeatureService,
    public cartService: CartServiceService,
    private consumerService: ConsumerService,
    private webEngageService: WebengageService,
    public dialogRef: MatDialogRef<AddonsDetailModalComponent>,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public priceManager: PriceManager
  ) {}

  public feature: any;
  public itemInCart = '';
  public buttonName = 'ADD TO CART';
  private clientSubscription!: Subscription;

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy() {
    if (this.clientSubscription && !this.clientSubscription.closed) {
      this.clientSubscription.unsubscribe();
    }
  }

  onBack(packageIs: boolean) {
    if (packageIs) {
      let data: any;
      this.featureService.getPack.subscribe((element) => (data = element));
      this.featureService.setFeatures(data);

      // this.modalRef = this.modalService.show(
      //   AddonsDetailModalComponent,
      //   Object.assign({}, { class: 'addons-detail-modal' })
      // );

      const dialogRef = this.dialog.open(AddonsDetailModalComponent, {
        panelClass: 'addons-detail-modal',
      });
    } else {
      const dialogRef = this.dialog.open(AddonsDetailModalComponent, {
        panelClass: 'addons-detail-modal',
      });

      // this.modalRef = this.modalService.show(
      //   RecommendationsModalComponent,
      //   Object.assign({}, { class: 'addons-detail-modal' })
      // );
    }
    this.dialogRef.close();
  }

  onClickOnButton() {
    if (this.buttonName == 'ADDED' || this.buttonName == 'REMOVE') {
      this.cartService.deleteCartItems(this.feature);
      this.getData();
    } else {
      this.cartService.setCartItems(this.feature);
      this.getData();
    }
  }

  getData() {
    this.featureService.getFeature.subscribe((data) => (this.feature = data));
    if (this.feature) {
      const itemData = this.cartService.checkItemIsPresentInCart(
        this.feature._kid
      );
      if (itemData && itemData.length > 0) {
        this.buttonName = 'ADDED';
      } else {
        this.buttonName = 'ADD TO CART';
      }
    }

    if (
      this.feature &&
      this.feature.included_features &&
      this.feature.included_features.length
    ) {
      this.webEngageService._webengage.track('Feature packs Clicked', {
        Click: '',
        ADDONS_MARKETPLACE: '',
        'Package Identifier': this.feature._kid,
        Validity: this.feature.min_purchase_months,
        'Package Tag': '',
        event_value: '',
        'Discount %': this.feature.overall_discount_percent,
        event_name: '',
        event_label: '',
        'Package Name': this.feature.name,
      });

      this.webEngageService._webengage.track(
        `ADD-ONS Marketplace Package Bundle Loaded`,
        {
          event_name: 'ADD-ONS Marketplace Package Bundle Loaded',
          event_label: 'Load',
          'Package Name': this.feature.name,
        }
      );
    }
  }

  // check item is present in cart or not && set the value of the button
  _buttonCart(value: any) {
    if (value) {
      const itemData = this.cartService.checkItemIsPresentInCart(value._kid);
      if (itemData && itemData.length > 0) {
        return 'ADDED';
      } else {
        return 'ADD TO CART';
      }
    } else {
      return 'ADD TO CART';
    }
  }

  // On click on button ( add to cart or remove from cart)
  onClickButtonCart(value: any, type: string) {
    this.clientSubscription = this.consumerService.getClientDetails.subscribe(
      (data) => {
        if (data) {
          this.bgColor = data.ManageConfiguration.ClientBrandingColorCode;
        }
      }
    );

    if (type == 'ADD TO CART') {
      var packageName = '';
      if (value && value.included_features) {
        value.included_features.forEach((element: any) => {
          if (packageName) {
            packageName = packageName + ',' + element.feature_code;
          } else {
            packageName = element.feature_code;
          }
        });
        this.webEngageService._webengage.track(
          `ADD-ONS Marketplace Package added to cart`,
          {
            Validity: value.min_purchase_months,
            'Package Name': value.name ?? '',
            'Discount %': value.overall_discount_percent ?? 0,
            'Package Price': value.totalPrice ?? 0,
            'Discounted Price': value.discountedPrice ?? 0,
            'Package Tag': packageName ?? '',
          }
        );
      } else {
        this.webEngageService._webengage.track(
          `ADD-ONS Marketplace Feature added to cart`,
          {
            // validity: value.min_purchase_months,
            'Addon Name': value.name ?? '',
            'Addon Discount %': value.discount_percent ?? 0,
            'Addon Price': value.price ?? 0,
            'Addon Discounted Price':
              value.price - (value.price * value.discount_percent) / 100 ?? 0,
            'Addon Feature Key': value.boost_widget_key ?? 0,
          }
        );
      }

      this.cartService.setCartItems(value);
      this.openSnackBar(value.name + ' is added to cart');
    } else {
      this.cartService.deleteCartItems(value);
      this.openSnackBar(value.name + ' is removed from cart');
    }
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      duration: 2000, // in seconds
      panelClass: ['snackbar-color'],
    });
  }

  // On Hover state will be changed to remove (button name or color)
  itemInCartHover(state: string, inCartState: any, id: string) {
    if (state == 'enter') {
      if (inCartState == 'ADDED') {
        this.itemInCart = id;
        this.buttonName = 'REMOVE';
      }
    } else {
      if (this.buttonName == 'REMOVE') {
        this.itemInCart = '';
        this.buttonName = 'ADDED';
      }
    }
  }

  openModalDeatails(isPackage: boolean): void {
    // this.modalRef = this.modalService.show(
    //   AddonsDetailModalComponent,
    //   {
    //     initialState: {

    //     },
    //     class:  'addons-detail-modal'
    //   }
    // );
    // this.modalRef.content.isBack = false;

    const dialogRef = this.dialog.open(AddonsDetailModalComponent, {
      data: {
        isPackage: isPackage,
      },
    });
  }

  onOpenModel(data: any) {
    this.featureService.setFeatures(data);
    this.openModalDeatails(true);
  }
}
