import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LocalStorageKeys } from 'projects/main-app/src/environments/environment';
import { AuthToken } from '../select-store/select-store.model';

@Component({
  selector: 'main-active-store',
  templateUrl: './all-store.component.html',
  styleUrls: ['./all-store.component.scss'],
})
export class AllStoreComponent implements OnInit {
  public stores: AuthToken[] = [];
  public search: string = '';

  constructor(private dialogRef: MatDialogRef<AllStoreComponent>) {
    const user = JSON.parse(localStorage.getItem(LocalStorageKeys.user)!);

    if (user)
      for (let i = 0; i < user.authTokens.length; i++) {
        this.stores = [...this.stores, user.authTokens[i]];
      }
  }

  ngOnInit(): void {}

  closeModal() {
    this.dialogRef.close();
  }
}
