import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BookNewDomainComponent } from '../book-new-domain/book-new-domain.component';
import { OldDomainComponent } from '../old-domain/old-domain.component';

@Component({
  selector: 'setting-link-domain',
  templateUrl: './link-domain.component.html',
  styleUrls: ['./link-domain.component.scss'],
})
export class LinkDomainComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<LinkDomainComponent>,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  closeModal() {
    this.dialogRef.close();
  }

  openBookDomain() {
    const dialogRef = this.dialog.open(BookNewDomainComponent, {
      // disableClose: true,
    });
  }

  openOldDomain() {
    const dialogRef = this.dialog.open(OldDomainComponent, {
      // disableClose: true,
    });
  }
}
