import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
@Pipe({
  name: 'safeUrl',
})
export class SafeUrlPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}
  transform(value: any): any {
    // const x = value.split('/');
    // const videoLinks = this.domSanitizer.bypassSecurityTrustResourceUrl(
    //     'https://www.youtube.com/embed/' + x[x.length - 1]
    //   );
    const videoLinks = this.domSanitizer.bypassSecurityTrustResourceUrl(
      // value
      encodeURI(value)
    );
    return videoLinks;
  }
}
