import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CanAutoUnsubscribe, NfApiService } from '@nf/common';
import { NfClientService } from '@nf/core';
import { ToastrService } from 'ngx-toastr';
import { MlcService } from 'projects/main-app/src/app/services/mlc.service';
import { LocalStorageKeys } from 'projects/main-app/src/environments/environment';
@Component({
  selector: 'setting-old-domain',
  templateUrl: './old-domain.component.html',
  styleUrls: ['./old-domain.component.scss'],
})
@CanAutoUnsubscribe
export class OldDomainComponent implements OnInit {
  public domain: string = '.COM';
  public rootAliasUri: string = '';
  public domainDescription: string = '';
  public floatingPointDetails: any;
  public fpTag: string = '';
  public fpId: string = '';
  public domainSubject: string = '';

  constructor(
    public dialogRef: MatDialogRef<OldDomainComponent>,
    private clientService: NfClientService,
    private apiService: NfApiService,
    private toastr: ToastrService,
    private mlcService: MlcService
  ) {
    if (this.mlcService.isMLCStore) {
      this.fpId = this.mlcService.fpId;
      this.fpTag = this.mlcService.fpTag;
      this.rootAliasUri = this.mlcService.rootAliasUri;
    } else {
      const authToken = localStorage.getItem(LocalStorageKeys.auth_token);
      if (authToken && authToken.length) {
        const parsedData = JSON.parse(authToken);
        this.fpId = parsedData.FloatingPointId;
        this.fpTag = parsedData.FloatingPointTag;
        this.rootAliasUri = parsedData.RootAliasUri;
      }
    }
    this.domainDescription = `I would like to map my existing domain to my NowFloats site ${this.rootAliasUri} Please reach out to me to discuss this in detail.`;
  }

  ngOnInit(): void {
    this.getFloatingPointDetailsById();
  }

  getFloatingPointDetailsById() {
    const body = {
      clientId: this.clientService.defaultClientConfig().clientId,
      floatingPointId: this.fpId,
    };
    this.apiService
      .post<any>(body, {
        apiPath: '/internal/v1/GetFloatingPointDetailsById',
      })
      .subscribe(
        (res: any) => {
          this.floatingPointDetails = res;
        },
        (error) => {
          this.toastr.error(
            'Somthing went wrong while getting Floating Point Details By Id',
            'Error'
          );
        }
      );
  }

  activateDomain() {
    const domainData = {
      clientId: this.clientService.defaultClientConfig().clientId,
      FPTag: this.fpTag,
      Subject: ` ${this.getSubject()}  [${this.fpTag}]`,
      Message: this.domainDescription,
    };
    this.apiService
      .put<any>(domainData, {
        config: {
          baseUrl: 'https://ria.withfloats.com',
        },
        apiPath: `/api/Service/EmailRIASupportTeamV2`,
        params: new HttpParams()
          .append('authClientId', this.fpId)
          .append('fpTag', this.fpTag),
      })
      .subscribe(
        (isAvailable: any) => {
          this.closeModal();
        },
        (error: any) => {
          this.closeModal();
        }
      );
  }

  closeModal() {
    this.dialogRef.close();
  }

  getSubject() {
    if (this.domainSubject == 'existingDomain') {
      return 'Point your existing domain to NowFloats website.';
    }
    if (this.domainSubject == 'subDomain') {
      return 'Put your NowFloats website as a sub domain.';
    }
    return '';
  }
}
