import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-success-modal',
  templateUrl: './payment-success-modal.component.html',
  styleUrls: ['./payment-success-modal.component.scss'],
})
export class PaymentSuccessModalComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
