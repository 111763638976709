import { HttpRequest } from './http-request';
import { NfApiParams } from '@nf/common';
import { environment } from 'projects/manage-user-app/src/environments/environment.prod';

export class ApiRequestGenerator {
  static getAllStaff() {
    const req = new HttpRequest(GET_ALL_STAFF_DATA, BASE_URL_NOWFLOATS);
    return req;
  }
  
  static getAllServices() {
    const req = new HttpRequest(GET_ALL_SERVICES, BASE_URL_NOWFLOATS);
    return req;
  }
  static getAllServicesListing() {
    const req = new HttpRequest(GET_ALL_SERVICES_LISTING, BASE_URL_NOWFLOATS);
    return req;
  }
  static createStaff() {
    const req = new HttpRequest(CREATE_STAFF_URL, BASE_URL_NOWFLOATS);
    return req;
  }
  static updateStaff() {
    const req = new HttpRequest(UPDATE_STAFF_URL, BASE_URL_NOWFLOATS);
    return req;

  }
  static addorUpdateStaffTiming(type: string) {
    let url = '';
    if (type == 'add') url = CREATE_STAFF_TIMING_URL;
    else url = UPDATE_STAFF_TIMING_URL;

    const req = new HttpRequest(url, BASE_URL_NOWFLOATS);
    return req;
  }
  static updateStaffImage() {
    const req = new HttpRequest(UPDATE_STAFF_IMAGE, BASE_URL_NOWFLOATS);
    return req;
  }

  static deleteStaffData() {
    const req = new HttpRequest(DELETE_STAFF_URL, BASE_URL_NOWFLOATS);
    return req;
  }

  static getAllNotificationCount(clientId:string, fpId:string){
    let params = new NfApiParams()
    .append('clientId', clientId)
    .append('fpId', fpId)
    .append('isRead', false)
    const req = new HttpRequest(GET_ALL_NOTIFICATION_COUNT,undefined,params);
    return req;
  }

  static getAllNotificationList(clientId:string,fpId:string,offset:number){
    let params = new NfApiParams()
    .append('clientId', clientId)
    .append('fpId', fpId)
    .append('isRead', "")
    .append('offset',offset)
    .append('limit',100);

    const req = new HttpRequest(GET_ALL_NOTIFICATIONS_LIST,undefined,params);
    return req;
  }
  static markNotificationAsRead(clientId:string, notId:string){
    let params = new NfApiParams()
    .append('clientId',clientId)
    .append('notificationId',notId).append('isRead',"true");

    const req = new HttpRequest(MARK_NOTIFICATION_AS_READ,undefined,params);
    return req;
  }
  static markNotificationAsTargetAchieved(clientId:string,fpId:string,notId:string){
    let params = new NfApiParams()
    .append('clientId',clientId)
    .append('fpId',fpId)
    .append('notificationId',notId).append('isTargetAchieved',"true")
    .append('Type',"UPDATE");

    const req = new HttpRequest(MARK_NOTIFICAITON_AS_ACHIEVED,undefined,params);
    return req;
  }
  static getAllOrders(clientId:string){
    let params = new NfApiParams()
    .append('clientId',clientId)

    const req = new HttpRequest(GET_ALL_ORDERS, BASE_URL_ASSURED_PURCHASE,params)
    return req;
  }
  static getOrderDetailsById(clientId:string,orderId:string){
   let params = new NfApiParams()
   .append('clientId',clientId)
   .append('orderId',orderId);
   
   const req = new HttpRequest(GET_ORDER_DETAILS_BY_ID,BASE_URL_ASSURED_PURCHASE,params);
   return req;
  }

  static initiateOrderV2P5(clientId:string){
    let params = new NfApiParams()
      .append('clientId',clientId);

    const req = new HttpRequest(INITIATE_ORDER_V2P5,BASE_URL_ASSURED_PURCHASE,params);
    return req;
  }

  static getAllServicesForSpa(){
    const req = new HttpRequest(GET_ALL_SERVICES_SPA,BASE_URL_NOWFLOATS);
    return req;
  }
  static getBookingSlots(){
    const req = new HttpRequest(GET_BOOKING_SLOTS,BASE_URL_NOWFLOATS);
    return req;
  }
  static updateOrderExtraProperties(clientId:string){
    let params = new NfApiParams()
      .append('clientId',clientId);
    
    const req = new HttpRequest(UPDATE_ORDER_EXTRA_PROPERTIES,BASE_URL_ASSURED_PURCHASE,params);
    return req;
  }
  static requestOrderFeedback(clientId:string){
    let params = new NfApiParams()
    .append('clientId',clientId)

    const req = new HttpRequest(REQUEST_ORDER_FEEDBACK,BASE_URL_ASSURED_PURCHASE,params);
    return req;
  }
  static markPaymentReceivedForPlaceOrderByMerchant(clientId:string){
    let params = new NfApiParams().append('clientId',clientId);

    const req = new HttpRequest(MARK_PAYMENT_DONE_BY_MERCHANT_FOR_PLACED_ORDER,BASE_URL_ASSURED_PURCHASE,params);
    return req;
  }
  static confirmOrder(orderId:string,clientId:string){
    let params = new NfApiParams()
    .append('clientId',clientId)
    .append('orderId',orderId);

    const req = new HttpRequest(CONFIRM_ORDER,BASE_URL_ASSURED_PURCHASE,params);
    return req;
  }
  static sendPaymentReminderForOrder(orderId:string, clientId:string){
    let params = new NfApiParams()
    .append('orderId',orderId)
    .append('clientId',clientId);

    const req = new HttpRequest(SEND_ORDER_PAYMENT_REMINDER,BASE_URL_ASSURED_PURCHASE,params);
    return req;
  }
  static markOrderAsShipped(cId:string){
    let params = new NfApiParams()
    .append('clientId',cId);

    const req = new HttpRequest(MARK_ORDER_SHIPPED,BASE_URL_ASSURED_PURCHASE,params);
    return req;
  }
  static markOrderAsDelivered(orderId:string,cId:string){
    let params = new NfApiParams()
    .append('orderId',orderId)
    .append('clientId',cId);

    const req = new HttpRequest(MARK_ORDER_AS_DELIVERED,BASE_URL_ASSURED_PURCHASE,params);
    return req;
  }

  static getOrderStatusSummary(startDate:string,endDate:string,sellerId:string){
    let params = new NfApiParams()
    .append('sellerId',sellerId)
    .append('startDate',startDate)
    .append('endDate',endDate)

    const req = new HttpRequest(GET_ORDER_STATUS_SUMMARY,BASE_URL_ASSURED_PURCHASE,params);
    return req;
  }
}

const BASE_URL_NOWFLOATS = 'https://api.nowfloats.com';
const BASE_URL_ASSURED_PURCHASE = environment.assuredPurchaseBaseUrl;
const GET_ALL_STAFF_DATA = '/Staff/v1/GetStaffListing';
const GET_ALL_SERVICES = '/Product/v13/GetServiceListings';
const GET_ALL_SERVICES_LISTING = '/Product/v13/GetAllServiceListings';
const GET_ALL_SERVICES_SPA = '/Product/v13/GetAllServiceListings';

const CREATE_STAFF_URL = '/Staff/v1/Create';
const UPDATE_STAFF_URL = '/Staff/v1/Update';
const CREATE_STAFF_TIMING_URL = '/Staff/v1/AddStaffTiming';
const UPDATE_STAFF_TIMING_URL = '/Staff/v1/UpdateStaffTiming';
const UPDATE_STAFF_IMAGE = '/Staff/v1/UpdateStaffImage';

const DELETE_STAFF_URL = '/Staff/v1/Delete';

const GET_ALL_NOTIFICATION_COUNT = '/Discover/v1/floatingpoint/notificationscount';
const GET_ALL_NOTIFICATIONS_LIST = '/Discover/v1/floatingpoint/getnotifications';
const MARK_NOTIFICATION_AS_READ = '/Discover/v1/floatingpoint/notification/notificationStatus';
const MARK_NOTIFICAITON_AS_ACHIEVED = '/Discover/v1/floatingpoint/notification/changenotificationstatus';

const GET_ALL_ORDERS = '/api/assuredPurchase/v2/ListAllOrders';
const GET_ORDER_DETAILS_BY_ID = '/api/AssuredPurchase/v2/GetOrderDetails';
const INITIATE_ORDER_V2P5 = '/api/assuredPurchase/v2.5/InitiateOrder';
const GET_BOOKING_SLOTS = '/staff/v1/GetBookingSlots';
const UPDATE_ORDER_EXTRA_PROPERTIES = '/api/assuredPurchase/v2/UpdateExtraPropertiesInformation';
const REQUEST_ORDER_FEEDBACK = '/api/assuredPurchase/v2/RequestOrderFeedback';
const MARK_PAYMENT_DONE_BY_MERCHANT_FOR_PLACED_ORDER = '/api/assuredPurchase/v2/MarkPaymentReceivedForPlacedOrderByMerchant';
const CONFIRM_ORDER = '/api/assuredPurchase/v2/ConfirmOrder';
const SEND_ORDER_PAYMENT_REMINDER = '/api/assuredPurchase/v2/SendPaymentReminder';
const MARK_ORDER_SHIPPED = '/api/assuredPurchase/v2/MarkOrderAsShipped';
const MARK_ORDER_AS_DELIVERED = '/api/assuredPurchase/v2/MarkOrderAsDelivered';
const GET_ORDER_STATUS_SUMMARY = '/api/AssuredPurchase/OrderStatusSummary';