import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CanAutoUnsubscribe, NfApiParams, NfApiService } from '@nf/common';
import { NfClientService } from '@nf/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'main-need-help-modal',
  templateUrl: './need-help-modal.component.html',
  styleUrls: ['./need-help-modal.component.scss'],
})
@CanAutoUnsubscribe
export class NeedHelpModalComponent implements OnInit {
  public showShimmer: boolean = false;
  public phone: string = '';
  public email: string = '';

  //Subscription variables
  public infoSub?: Subscription;

  constructor(
    public dialogRef: MatDialogRef<NeedHelpModalComponent>,
    private apiService: NfApiService,
    private clientService: NfClientService
  ) {
    this.getInfo();
  }

  ngOnInit(): void {}

  getInfo() {
    this.showShimmer = true;
    this.infoSub = this.apiService
      .get<any>({
        apiPath: '/internal/v1/89430285023849023892/GetBasePlugin',
        params: new NfApiParams().append(
          'clientId',
          localStorage.getItem('clientId')!
        ),
      })
      .subscribe(
        (res) => {
          this.showShimmer = false;
          if (res) {
            if (res.CustomerCare) this.phone = res.CustomerCare;
            if (res.CustomerEngagementTeamEmailAddress)
              this.email = res.CustomerEngagementTeamEmailAddress;
          }
        },
        (err) => {
          // this.showShimmer = false;
        }
      );
  }

  closeModal() {
    this.dialogRef.close();
  }
}
