import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'mc-connect-social',
  templateUrl: './connect-social.component.html',
  styleUrls: ['./connect-social.component.scss'],
})
export class ConnectSocialComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<ConnectSocialComponent>) {}

  ngOnInit(): void {}

  closeModal(event: boolean) {
    this.dialogRef.close(event);
  }
}
