import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { MlcService } from 'projects/main-app/src/app/services/mlc.service';
import { LocalStorageKeys } from 'projects/main-app/src/environments/environment';

@Component({
  selector: 'mc-update-posted',
  templateUrl: './update-posted.component.html',
  styleUrls: ['./update-posted.component.scss'],
})
export class UpdatePostedComponent implements OnInit {
  public URL: string;

  constructor(
    private toastr: ToastrService,
    private mlcService: MlcService,
    public dialogRef: MatDialogRef<UpdatePostedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    const authToken = localStorage.getItem(LocalStorageKeys.auth_token);
    let rootAlias = '';

    if (this.mlcService.isMLCStore) {
      rootAlias = this.mlcService.rootAliasUri;
    } else {
      if (authToken && authToken.length)
        rootAlias = JSON.parse(authToken).RootAliasUri;
    }

    const description = this.data.message
      ?.substring(0, 20)
      .replace(/ /g, '-')
      .toLowerCase();

    this.URL = `${rootAlias}/latest-update/${description}/${this.data.currentUpdateCount}`;
  }

  ngOnInit(): void {}

  closeModal() {
    this.dialogRef.close();
  }

  copyToClipBoard() {
    this.toastr.success('Link copied successfully', 'Link Copied', {
      positionClass: 'toast-bottom-right',
    });
  }
}
