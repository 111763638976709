import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private isLoading$ = new BehaviorSubject<any>(true);

  get getLoading() {
    return this.isLoading$.asObservable();
  }

  setLoading(data: any) {
    this.isLoading$.next(data);
  }
}
