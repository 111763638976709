<div id="ordersRange" class="pull-right date-range-picker">
    <div class="daterangeWrap">
      <div class="selected-data" (click)="openSelector()">
        <span>
          <mat-icon>calendar_today</mat-icon> {{ selectedSaleDate }}
        </span>

        <img src="../../../assets/img/select-triangle.svg" alt="" />
      </div>
      <!-- <div class="openDateSelector" *ngIf="openCloseSelect" (click)="openSelector()"  ></div> -->
      <div *ngIf="openCloseSelect" class="selector-options">
        <ul>
          <li
            *ngFor="let date of batchDateArr; let i = index"
            [ngClass]="{ selected: dateRangeData.batchType == date.batchType }"
          >
            <div
              (click)="onDateSelect(date, 'SALE')"
              *ngIf="date.batchType != 'CUSTOM'"
            >
              {{ date.text }}
            </div>
            <div
              (click)="openDatePicker(campaignOnePicker)"
              *ngIf="date.batchType == 'CUSTOM'"
            >
              {{ date.text }}
            </div>
          </li>
          <li class="datepickerLi">
            <mat-form-field
              class="example-form-field"
              style="display: block; position: relative"
            >
              <mat-date-range-input
                [formGroup]="saleFormGroup"
                [rangePicker]="campaignOnePicker"
                [comparisonStart]="saleFormGroup.value.start"
                [comparisonEnd]="saleFormGroup.value.end"
              >
                <input
                  matStartDate
                  placeholder="Custom Range"
                  formControlName="start"
                />
                <input
                  matEndDate
                  (dateChange)="onCustomRangeCheck('CUSTOM', 'SALE')"
                  placeholder="End date"
                  formControlName="end"
                />
              </mat-date-range-input>
              <mat-date-range-picker
                #campaignOnePicker
              ></mat-date-range-picker>
            </mat-form-field>
          </li>
        </ul>
      </div>
    </div>
  </div>
