<div class="header-jio">
  <div class="header-nav">
    <div class="header-item">
      <a [href]="mobilityUrl" target="_blank">mobility</a>
    </div>
    <div class="header-item">
      <a [href]="fiberUrl" target="_blank">jiofiber</a>
    </div>
    <div class="header-item selected-item">
      <a href="javascript:void(0)">business</a>
    </div>
  </div>

  <div class="header-logo">
    <a [href]="logoUrl" target="_blank">
      <img
        src="assets/jio-online-images/JioBusiness-Blue-Logo-RGB.png"
        alt="Jio Logo"
      />
    </a>
  </div>
</div>
