<mat-drawer-container [hasBackdrop]="true" class="slide-container">
  <mat-drawer #sidenav mode="over" opened="true" position="end" disableClose>
    <div class="slider-header" *ngIf="hideConfig && hideConfig.header">
      <ng-content select=".sliderHeader"></ng-content>
    </div>
    <!-- dialog body -->
    <div class="slider-body" *ngIf="hideConfig && hideConfig.body">
      <ng-content select=".sliderBody"></ng-content>
    </div>
    <!-- dialog footer -->
    <div>
      <ng-content select=".sliderFooter" *ngIf="hideConfig && hideConfig.footer"></ng-content>
    </div>
  </mat-drawer>
  <mat-drawer-content> </mat-drawer-content>
</mat-drawer-container>
