import { NfAppExpCode, NfApplication } from '../menu.enum';
import { NfMenuConfig } from '../menu.type';

const menuName = 'MANAGE CONTENT';
export const MLC_MANAGE_CONTENT_MENU_CONFIG: NfMenuConfig[] = [
  {
    application: NfApplication.ManageContent,
    appExpCode: NfAppExpCode.MFG,
    menu: {
      name: menuName,
      items: [
        {
          name: 'All Updates',
          url: '/enterprise/manage-content/all-updates',
          meta: {
            boostTitle: 'Boost 360: All Updates',
            jioTitle: 'JioOnline: All Updates',
          },
        },
        {
          name: 'All Images',
          url: '/enterprise/manage-content/mlc-image',
          meta: {
            boostTitle: 'Boost 360: All Images',
            jioTitle: 'JioOnline: All Images',
          },
        },
        {
          name: 'Custom Pages',
          url: '/enterprise/manage-content/custom-pages',
          meta: {
            boostTitle: 'Boost 360: Your Custom Pages',
            jioTitle: 'JioOnline: Your Custom Pages',
          },
        },
        {
          name: 'Product Gallery',
          url: '/enterprise/manage-content/mlc-product',
          meta: {
            boostTitle: 'Boost 360: Product Gallery',
            jioTitle: 'JioOnline: Product Gallery',
          },
        },
      ],
    },
  },
];
