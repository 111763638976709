import { Component, Input, OnInit } from '@angular/core';

interface Hide {
  header?: boolean,
  body?: boolean,
  footer?: boolean
}

@Component({
  selector: 'mc-general-slider',
  templateUrl: './general-slider.component.html',
  styleUrls: ['./general-slider.component.scss'],
})
export class GeneralSliderComponent implements OnInit {
  @Input() set hide(val: Hide) {
    if (val) {
      Object.keys(val).map( (key: string) => (this.hideConfig as any)[key] = (val as any)[key] );
    }
  };
  hideConfig: Hide = {
    header: true,
    body: true,
    footer: true
  };
  constructor() {}

  ngOnInit(): void {}
}
