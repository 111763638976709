import { NfClient, NfCoreLibConfig } from '@nf/core';
import { NfApiConfig, NfApiTokenType, NfApiTokenStorageType } from '@nf/common';

const production = true;

const coreConfig: NfCoreLibConfig = {
  production,
  client: NfClient.Boost,
};

// const apiConfig: NfApiConfig = {
//   baseUrl: 'https://boost.nowfloats.com',
//   tokenKey: 'Authorization',
//   tokenType: NfApiTokenType.BEARER,
//   storedIn: NfApiTokenStorageType.COOKIE,
// };

const apiConfig: NfApiConfig = {
  baseUrl: 'https://api2.withfloats.com',
  tokenKey: 'token',
  tokenType: NfApiTokenType.BEARER,
  authData: 'user',
  storedIn: NfApiTokenStorageType.LOCAL_STORAGE,
  refreshToken: 'refresh-token',
};

export enum LocalStorageKeys {
  auth_token = 'authToken',
  app_exp_code = 'AppExperienceCode',
  user = 'user',
  token = 'token',
  drs_total = 'drs-total',
  refreshToken = 'refresh-token',
  mainToken = 'main-token',
}

export const environment = {
  production,
  coreConfig,
  apiConfig,
  assuredPurchaseBaseUrl: 'https://assuredpurchase.withfloats.com',
};
