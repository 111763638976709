<table mat-table [dataSource]="tableContent" class="table-calls">
  <ng-container matColumnDef="{{ i.displayKey }}" *ngFor="let i of tableStyle">
    <div *ngIf="i.type == 'text'">
      <th mat-header-cell *matHeaderCellDef class="table-heading">
        {{ i.displayValue }}
        <span class="imp-star" *ngIf="i.isRequired">*</span>
      </th>
      <td mat-cell *matCellDef="let element" class="table-data">
        <div class="number-container">
          <!-- <mat-icon *ngIf="i.icon" [ngClass]="iconClass" class="missed-icon">
            call_end
          </mat-icon> -->

          <mat-icon
            *ngIf="i.icon && element.callStatus == callStatus.missed"
            class="missed-icon text-red-600"
          >
            call_end
          </mat-icon>
          <mat-icon
            *ngIf="i.icon && element.callStatus == callStatus.received"
            class="missed-icon text-green-400"
          >
            call
          </mat-icon>

          <span>
            {{ element[i.displayKey] }}
          </span>
        </div>
      </td>
    </div>

    <div *ngIf="i.type == 'date'">
      <th mat-header-cell *matHeaderCellDef class="table-heading">
        {{ i.displayValue }}
        <span class="imp-star" *ngIf="i.isRequired">*</span>
      </th>
      <td mat-cell *matCellDef="let element" class="table-data">
        <div class="number-container">
          <span>
            {{ element[i.displayKey] | date: i.format }}
          </span>
        </div>
      </td>
    </div>

    <div *ngIf="i.type == 'amount'">
      <th mat-header-cell *matHeaderCellDef class="table-heading">
        {{ i.displayValue }}
        <span class="imp-star" *ngIf="i.isRequired">*</span>
      </th>
      <td mat-cell *matCellDef="let element" class="table-data">
        <div class="number-container">
          <span>
            {{ element[i.displayKey] | currency: element.currencyCode:'code' }}
          </span>
        </div>
      </td>
    </div>

    <div *ngIf="i.type == 'audio'">
      <th mat-header-cell *matHeaderCellDef class="table-heading">
        {{ i.displayValue }}
        <span class="imp-star" *ngIf="i.isRequired">*</span>
      </th>
      <td mat-cell *matCellDef="let element" class="table-data">
        <audio
          controls
          *ngIf="element[i.displayKey] && element[i.displayKey] != 'None'"
        >
          <source [src]="element[i.displayKey]" type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      </td>
    </div>

    <div *ngIf="i.type == 'select'">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && allSelected"
          [indeterminate]="selection.hasValue() && !allSelected"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          *ngIf="
            i.filterKey && i.filterValue && row[i.filterKey] == i.filterValue
          "
          (click)="handleSelection($event)"
          (change)="selectCheckbox($event, row)"
          [checked]="selection.isSelected(row)"
        >
        </mat-checkbox>
      </td>
    </div>

    <!-- <div *ngIf="i.dataType == 'IMAGE'">
    <th mat-header-cell *matHeaderCellDef>
      {{ i.displayValue }}
      <span class="imp-star" *ngIf="i.isRequired">*</span>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="profile-img-container">
        <div
          class="img-container"
          *ngIf="element && element[i.displayKey]?.url"
        >
          <img
            *ngIf="element[i.displayKey].url"
            [src]="element[i.displayKey].url"
            alt="{{ i.displayKey }}"
            class="table-img"
          />
          <mat-icon
            matTooltip="Remove Image"
            matTooltipClass="card-tooltip"
            matTooltipPosition="above"
            *ngIf="element[i.displayKey].url && element.isEdit"
            (click)="removeImage(element, i.displayKey)"
            class="cancel-icon"
            >cancel</mat-icon
          >
        </div>
        <button
          (click)="fileInput.click()"
          class="table-icon-btn img-select-btn"
          *ngIf="
            element && element.isEdit && !element[i.displayKey].url
          "
        >
          <input
            type="file"
            name="image-file"
            #fileInput
            accept="image/*"
            style="display: none"
            (change)="uploadImage($event, element, i.displayKey)"
          />
          <mat-icon>add</mat-icon>
          <span>Select Image</span>
        </button>
        <input
          type="text"
          [(ngModel)]="element[i.displayKey].description"
          [disabled]="!element.isEdit"
          placeholder="{{ i.displayKey }}"
          class="table-text-input img-text-input"
        />
      </div>
    </td>
  </div>

  <div *ngIf="i.displayKey == 'CreatedOn'">
    <th mat-header-cell *matHeaderCellDef>{{ i.displayValue }}</th>
    <td mat-cell *matCellDef="let element">
      <input
        type="date"
        [ngModel]="element[i.displayKey] | date: 'yyyy-MM-dd'"
        (ngModelChange)="element[i.displayKey] = $event"
        class="table-text-input cursorDisabled"
        [disabled]="!element.isEdit"
      />
    </td>
  </div>

  <div *ngIf="i.displayKey == 'edit'">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button
        matTooltip="Edit"
        matTooltipClass="card-tooltip"
        matTooltipPosition="above"
        (click)="handleEditOption(element)"
        *ngIf="!element.isEdit"
        class="table-icon-btn"
      >
        <mat-icon>edit</mat-icon>
      </button>
      <button
        matTooltip="Save"
        matTooltipClass="card-tooltip"
        matTooltipPosition="above"
        (click)="handleSaveOption(element)"
        *ngIf="element.isEdit"
        class="table-icon-btn"
      >
        <mat-icon>save</mat-icon>
      </button>
    </td>
  </div>

  <div *ngIf="i.displayKey == 'delete'">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button
        matTooltip="Delete"
        matTooltipClass="card-tooltip"
        matTooltipPosition="above"
        (click)="handleDelete(element)"
        class="table-icon-btn"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </td>
  </div> -->
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr class="row-hover" mat-row *matRowDef="let row; columns: columns"></tr>
</table>
