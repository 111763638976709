import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.css'],
})
export class VideoModalComponent implements OnInit {
  consumerData: any;
  selectedVideo: any;
  videos = [
    {
      number: 1,
      MainHeader: 'How to navigate the JioOnline dashboard',
      SecondaryHeader: 'Let us learn how to navigate the JioOnline dashboard.',
      Url: 'https://cdn.nowfloats.com/manage/assets/Content/videos/HowTonavigateDashBoard.mp4',
      Title: 'How to navigate the JioOnline dashboard',
      TutorialContentHeader: 'Tutorial contents',
      PosterUrl: 'assets/img/Thumbnails/NavigateDashboard.png',
      PosterUrlLarge: 'assets/img/Thumbnails/NavigateDashboardLarge.png',
      timing: '01:56',
      TutorialContent: [
        {
          lstSteps:
            'The JioOnline Web portal can be accessed through the JioBusiness Digital Self-Care Portal.',
        },
        {
          lstSteps:
            'Go to https://www.jio.com/business/ and sign in to the Self-Care Portal.',
        },
        {
          lstSteps: `Select JioFiber from the dropdown, select the relevant billing location and click 'Submit'.`,
        },
        {
          lstSteps: `Click on the 'Access your JioOnline Account' banner and you will be redirected to a page where you can manage your account.`,
        },
        {
          lstSteps: `Click on 'Open JioOnline Web' to see the dashboard from where the entire business site can be managed. The dashboard menu is the main base of operation for your website. You can manage all aspects of your website and business here.`,
        },
        {
          lstSteps:
            'Whenever you make a change on the web portal – like adding updates or changing your business hours, click on your business name to see your website reflecting these changes immediately.',
        },
        {
          lstSteps: `The customer activity summary shows the most valuable metrics of the performance of your website. From customer enquiries, orders or appointments, revenue, to call logs, it's all there. These metrics vary from category to category.For example, a doctor will be able to track his Online Video Consultations and In - Clinic Appointments.A restaurant owner will be able to see his Online Orders, Table Reservations and so on.`,
        },
        {
          lstSteps:
            'Website stats show you the number of people who have visited your website.',
        },
        {
          lstSteps:
            'Unique Visitors shows the number of unique users who have visited your website within the selected date range.',
        },
        {
          lstSteps:
            'Visits Received includes both new and returning users within the selected date range.',
        },
        {
          lstSteps:
            'Your website visitor count will gradually build up once you add a certain amount of content to your JioOnline website, and you will start noticing more business queries and calls coming in from the website itself.',
        },
      ],
    },
    {
      number: 2,
      MainHeader: 'How to respond to customer enquiries',
      SecondaryHeader:
        'Let us learn how to respond to customer enquiries received via your JioOnline website.',
      Url: 'https://cdn.nowfloats.com/manage/assets/Content/videos/HowToRespondToCustomerEnquiries.mp4',
      Title: 'How to respond to customer enquiries',
      TutorialContentHeader: 'Tutorial contents',
      PosterUrl: 'assets/img/Thumbnails/CustomerEnquiry.png',
      PosterUrlLarge: 'assets/img/Thumbnails/CustomerEnquiryLarge.png',
      timing: '01:30',
      TutorialContent: [
        {
          lstSteps:
            'Step 1. Go to Enquiries in the Header Menu and click on Customer Messages. All the messages/enquiries from potential customers are stored here.',
        },
        {
          lstSteps: `Simply click on 'Reply' and use the contact details provided by them to get in touch via call or email.`,
        },
        {
          lstSteps: 'b) How can customers connect with you via WhatsApp',
        },
        {
          lstSteps: `Once you have connected your WhatsApp number to your JioOnline website, a 'Chat with us' floating button will start appearing on your website.`,
        },
        {
          lstSteps: `When a customer clicks on this 'Chat with us' button, it opens a quick inquiry box. Customers can then enter their message or enquiry in the pop-up along with their mobile number and click on 'Start Chatting' to begin a conversation.`,
        },
        {
          lstSteps:
            'Whenever a visitor drops a query, you also receive an instant notification via email/sms so you never miss out on any business opportunities.',
        },
        {
          lstSteps:
            'Pro-tip: Remember, the faster you respond to a business query, the higher the chances of converting the person into a customer.',
        },
      ],
    },
    {
      number: 3,
      MainHeader: 'How to post an image',
      SecondaryHeader:
        'Let us learn how to post an image on your JioOnline website.',
      Url: 'https://cdn.nowfloats.com/manage/assets/Content/videos/PostAnimage-final.mp4',
      Title: 'How to post an image',
      TutorialContentHeader: 'Tutorial contents',
      PosterUrl: 'assets/img/Thumbnails/PostAnImage.png',
      PosterUrlLarge: 'assets/img/Thumbnails/PostAnImageLarge.png',
      timing: '02:01',
      TutorialContent: [
        {
          lstSteps:
            'Step 1. Go to Website Content in the Header Menu and click on Image Gallery. You can update your logo image, featured image, background image, and favicon here.',
        },
        {
          lstSteps: `Step 2. Click the 'Gallery Images' bar and then click on 'Pick images from your device' to upload images from your desktop. You can even upload multiple images at once.`,
        },
        {
          lstSteps: `Step 3. Click on 'start upload' and the image will be added to your photo gallery.`,
        },
        {
          lstSteps: `Similarly, you can click the 'Logo Image' bar and click on 'Upload' to add a business logo.`,
        },
        {
          lstSteps: `Your business logo appears on the top left corner of your website.`,
        },
        {
          lstSteps: `To upload a featured image, click the 'Featured Image' bar and click on 'Upload'.`,
        },
        {
          lstSteps:
            'The featured image appears along with the business description and enhances the look of your website.',
        },
        {
          lstSteps: `To upload a background image, click the 'Background Images' bar and click on 'Upload'.`,
        },
        {
          lstSteps: `To upload a favicon image, click the 'Favicon Image' bar and click on 'Upload'.`,
        },
        {
          lstSteps: `Pro Tip: Try to follow the image guidelines to give your customers the best experience on your website.`,
        },
      ],
    },
    {
      number: 4,
      MainHeader: 'How To Update Your Business Details',
      SecondaryHeader:
        'Let us learn how to update your business details on your JioOnline website.',
      Url: 'https://cdn.nowfloats.com/manage/assets/Content/videos/Howtoupdateanbusinessdetails.mp4',
      Title: 'How To Update Your Business Details',
      TutorialContentHeader: 'Tutorial contents',
      PosterUrl: 'assets/img/Thumbnails/UpdateBusinessDetails.png',
      PosterUrlLarge: 'assets/img/Thumbnails/UpdateBusinessDetailsLarge.png',
      timing: '01:41',
      TutorialContent: [
        {
          lstSteps:
            'Step 1: Go to Website Content in the Header Menu and click on Business Profile.You can update your business description, address and business hours here.',
        },
        {
          lstSteps: `Step 2: Simply type in your business description in the text field.The business description appears on the homepage of your website and tells people what you do and why they should trust you.`,
        },
        {
          lstSteps:
            'Simply type in your business description in the text field.The business description appears on the homepage of your website and tells people what you do and why they should trust you.',
        },
        {
          lstSteps: `Step 3: Enter the complete address for the location of your business.Make the required changes to ensure that your Google Maps location is correct. An accurate address will help your customers find your business easily.`,
        },
        {
          lstSteps: `Step 4: Enter your business hours in the Store Timings section.Set the timings for each day by clicking on open / close and entering the 'Start Time' and 'End Time' respectively.You can also choose to set the same time for the whole week by clicking on this checkbox.`,
        },
        {
          lstSteps: `Step 5: Once you are done with all the changes, click the 'Save Changes' button to update them on the website.`,
        },
        {
          lstSteps:
            'Pro Tip: Keep your business details such as address, phone number and timings up-to-date, to make sure customers can reach you.',
        },
      ],
    },
    {
      number: 5,
      MainHeader: 'How to connect your website to social channels',
      SecondaryHeader:
        'Let us learn how to connect your website to social channels on JioOnline.',
      Url: 'https://cdn.nowfloats.com/manage/assets/Content/videos/HowToConnectSocialChannels.mp4',
      Title: 'How to connect your website to social channels',
      TutorialContentHeader: 'Tutorial contents',
      PosterUrl: 'assets/img/Thumbnails/SocialChannels.png',
      PosterUrlLarge: 'assets/img/Thumbnails/SocialChannelsLarge.png',
      timing: '01:15',
      TutorialContent: [
        {
          lstSteps: `Step 1. Go to 'Website Content' in the header menu and click on 'My Digital Channels'.`,
        },
        {
          lstSteps: `Here you can see the various platforms available for you to sync with your JioOnline website.`,
        },
        {
          lstSteps:
            'Step 2. Connect your Facebook Page, Twitter, Google My Business and WhatsApp Business profiles by simply logging into your accounts and following the on-screen instructions.',
        },
        {
          lstSteps: `In case you don't already have a Facebook Page, Twitter and Google My Business Profile for your business, you can create them easily by visiting their respective websites.`,
        },
        {
          lstSteps: `Step 3. Once you're done, you can see all the social channels you have connected to your JioOnline website under the 'Active Syncs' section.`,
        },
        {
          lstSteps:
            'Pro Tip: To increase the reach of your business updates, products or services and to expand the visibility of your business, connect as many social channels as you can.',
        },
      ],
    },
    {
      number: 6,
      MainHeader: 'No updates yet!',
      SecondaryHeader:
        'Let us learn how to post an update on your JioOnline website.',
      Url: 'https://cdn.nowfloats.com/manage/assets/Content/videos/HowTopostUpdate.mp4',
      Title: 'How to post an update',
      TutorialContentHeader: 'Tutorial contents',
      PosterUrl: 'assets/img/Thumbnails/PostAnUpdate.png',
      PosterUrlLarge: 'assets/img/Thumbnails/PostAnUpdateLarge.png',
      timing: '01:09',
      TutorialContent: [
        {
          lstSteps: `Step 1. Click the plus button on your dashboard and select 'Post an update'.`,
        },
        {
          lstSteps: `Step 2. Simply type in your message or offer in the text field. You can also attach an image with the text by clicking on 'add photo'.`,
        },
        {
          lstSteps:
            'Step 3. While creating an update, click on the social media icons below to share it on various social media platforms.',
        },
        {
          lstSteps: `Step 4. Click on 'update' and the content will be published on your website and your social media profiles  - instantly.`,
        },
        {
          lstSteps: `Pro Tip: Posting 2-3 updates a week can help you get better visibility and attract more customers to your business.`,
        },
      ],
    },
  ];

  constructor(
    public dialogRef: MatDialogRef<VideoModalComponent>,
    private domSanitizer: DomSanitizer
  ) {}
  baseUrl: any = `https://cdn.nowfloats.com/manage/assets/Content/videos/HowTo navigate DashBoard.mp4`;

  ngOnInit() {
    this.baseUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
      this.baseUrl
    );
    this.selectedVideo = this.videos[0];
  }
  onSelectedVideo(vid: any) {
    this.selectedVideo = vid;
  }
}
