import { CommonModule } from '@angular/common';
import { NfPageHeaderComponent } from './page-header.component';
import { NgModule } from '@angular/core';


@NgModule({
  declarations: [NfPageHeaderComponent],
  imports:[CommonModule],
  exports:[NfPageHeaderComponent]
})
export class NfPageHeaderModule { 

}

