<div class="page-modal">
  <div class="header-modal">
    <div class="content-wrapper-closeicon" (click)="closeModal()">
      <mat-icon>close</mat-icon>
    </div>
    <span class="title-header-modal"> Domain/Email</span>
  </div>

  <div class="modal-body">Domain/email booking is not available.</div>
</div>
