import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CartServiceService } from './../../services/cart-service.service';
import { WebengageService } from './../../services/webengage.service';
@Component({
  selector: 'app-auto-renew-subscription-modal',
  templateUrl: './auto-renew-subscription-modal.component.html',
  styleUrls: ['./auto-renew-subscription-modal.component.scss'],
})
export class AutoRenewSubscriptionModalComponent implements OnInit {
  getPurchase: any;
  typeOfPayment: any;
  nameArr: any = [];
  @Input() cartItems: any = [];

  constructor(
    public cartService: CartServiceService,
    private webEngageService: WebengageService,
    public dialogRef: MatDialogRef<AutoRenewSubscriptionModalComponent>,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.webEngageService._webengage.track(`ADD-ONS Marketplace Order Confirm`);
    this.typeOfPayment = this.cartService.getTypeOfPayment();
    this.getPurchase = this.cartService.getPaymentDetails();
    if (this.cartItems && this.cartItems.length) {
      this.cartItems.forEach((element: { name: any }) => {
        if (element) {
          this.nameArr.push(element.name);
        }
      });
    }
  }
}
