<div class="need-help-container">
  <img boost class="needHelpWomen" src="assets/images/needHelpWomen.svg" />
  <img
    jio-online
    class="needHelpWomen"
    src="assets/jio-online-images/jio-bot.svg"
  />
  <div>
    <div class="need-help-icons">
      <img
        (click)="closeModal()"
        class="BlackCancle"
        src="assets/images/Cancel.png"
      />
    </div>
    <div class="needHelpTitle">
      <h4>We’re happy to help.</h4>
      <p>Get in touch using any of the medium from below.</p>
    </div>
    <div class="needHelpDetails">
      <div class="needHelpDetails-email">
        <ngx-shimmer-loading
          *ngIf="showShimmer"
          [shape]="'rect'"
          [width]="'24rem'"
          [height]="'35px'"
        >
        </ngx-shimmer-loading>
        <div *ngIf="!showShimmer" class="flex">
          <!-- <img
            class="needHelp-detail-icon"
            src="assets/images/EnvelopeOpen.png"
          /> -->
          <div class="needHelp-detail-icon">
            <svg
              width="20"
              height="19"
              viewBox="0 0 20 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.970703 7.46132V17.204C0.970703 17.4028 1.04966 17.5934 1.19021 17.7339C1.33075 17.8745 1.52138 17.9534 1.72014 17.9534H18.2078C18.4065 17.9534 18.5971 17.8745 18.7377 17.7339C18.8782 17.5934 18.9572 17.4028 18.9572 17.204V7.46132L9.96395 1.46582L0.970703 7.46132Z"
                stroke="var(--primary-color)"
                stroke-width="1.74414"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.32965 12.707L1.20264 17.7413"
                stroke="var(--primary-color)"
                stroke-width="1.74414"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.7267 17.7413L11.5996 12.707"
                stroke="var(--primary-color)"
                stroke-width="1.74414"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.9572 7.46094L11.5991 12.707H8.32877L0.970703 7.46094"
                stroke="var(--primary-color)"
                stroke-width="1.74414"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <a
            class="needHelpDetails-span underline cursor-pointer outline-none"
            [href]="'mailto:' + email"
            target="_blank"
          >
            {{ email }}
          </a>
        </div>
        <a
          *ngIf="!showShimmer"
          [matTooltip]="'Mail To ' + email"
          matTooltipClass="need-help-tooltip"
          matTooltipPosition="above"
          mat-stroked-button
          class="need-help-btn need-help-btn-email"
          color="primary"
          [href]="'mailto:' + email"
          target="_blank"
        >
          EMAIL
        </a>
      </div>
      <div class="needHelpDetails-mobile">
        <ngx-shimmer-loading
          *ngIf="showShimmer"
          [shape]="'rect'"
          [width]="'24rem'"
          [height]="'35px'"
        >
        </ngx-shimmer-loading>
        <div *ngIf="!showShimmer" class="flex">
          <!-- <img class="needHelp-detail-icon" src="assets/images/PhoneCall.png" /> -->
          <div class="needHelp-detail-icon">
            <svg
              width="21"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.9062 4.15234C17.1767 4.49403 18.3351 5.16354 19.2654 6.09383C20.1957 7.02411 20.8652 8.18251 21.2069 9.45298"
                stroke="var(--primary-color)"
                stroke-width="1.74414"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15.1309 7.04883C15.8931 7.25384 16.5882 7.65555 17.1464 8.21372C17.7045 8.77189 18.1062 9.46693 18.3112 10.2292"
                stroke="var(--primary-color)"
                stroke-width="1.74414"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.63617 12.0982C10.4136 13.6875 11.7018 14.9699 13.2947 15.7401C13.4112 15.7953 13.5401 15.8192 13.6687 15.8095C13.7973 15.7998 13.9212 15.7567 14.0281 15.6846L16.3734 14.1207C16.4771 14.0515 16.5965 14.0093 16.7206 13.9979C16.8448 13.9865 16.9698 14.0063 17.0844 14.0554L21.4721 15.9358C21.6211 15.9991 21.7456 16.1092 21.8266 16.2493C21.9077 16.3895 21.941 16.5523 21.9216 16.713C21.7829 17.7982 21.2534 18.7957 20.4323 19.5186C19.6111 20.2415 18.5547 20.6403 17.4606 20.6404C14.0817 20.6404 10.8411 19.2981 8.4518 16.9088C6.06251 14.5195 4.72021 11.2789 4.72021 7.89993C4.72027 6.80592 5.1191 5.74944 5.84201 4.9283C6.56492 4.10717 7.56236 3.57769 8.64754 3.439C8.80831 3.41955 8.97105 3.45287 9.11123 3.53394C9.25142 3.61502 9.36146 3.73945 9.42478 3.8885L11.3068 8.28C11.3555 8.39359 11.3754 8.51746 11.3646 8.64057C11.3538 8.76368 11.3128 8.88222 11.2451 8.98562L9.68659 11.3669C9.61566 11.4741 9.57373 11.5977 9.56489 11.7259C9.55605 11.8541 9.58061 11.9824 9.63617 12.0982V12.0982Z"
                stroke="var(--primary-color)"
                stroke-width="1.74414"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <span class="needHelpDetails-span">{{ phone }}</span>
        </div>
        <a
          *ngIf="!showShimmer"
          [matTooltip]="'Call On ' + phone"
          matTooltipClass="need-help-tooltip"
          matTooltipPosition="above"
          mat-stroked-button
          class="need-help-btn need-help-btn-mobile"
          color="primary"
          [href]="'tel:' + phone"
        >
          CALL
        </a>
      </div>
    </div>
  </div>
</div>
