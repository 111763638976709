<div class="modal" style="display: block">
  <div style="padding: 20px 0px">
    <div>
      <p
        style="font-size: 16px; font-weight: 900; color: #828282"
        class="modal-title text-center"
      >
        Re-publishing...
      </p>
    </div>
    <div>
      <img
        src="./assets/images/republish-website-loader.gif"
        style="width: 100%; height: 51px"
      />
    </div>
  </div>
</div>
