import { Inject, Injectable } from '@angular/core';
import { NF_CORE_LIB_CONFIG } from './core-lib.config';
import { NfCoreLibConfig } from './core-lib.type';

@Injectable({
  providedIn: 'root',
})
export class NfCoreLibService {
  constructor(@Inject(NF_CORE_LIB_CONFIG) private config: NfCoreLibConfig) {}

  get coreConfig() {
    return this.config;
  }
}
