import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { NfClient } from '../client/client.enum';
import { NfClientService } from '../client/client.service';
import { NfTheme } from './theme.enum';

@Injectable({
  providedIn: 'root',
})
export class NfThemeService {
  private _renderer!: Renderer2;
  private _head!: HTMLElement;
  private _themeLinks: HTMLElement[] = [];

  constructor(
    private clientService: NfClientService,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this._head = document.head;
    this._renderer = this.rendererFactory.createRenderer(null, null);
  }

  public async setTheme(client?: NfClient) {
    client = client ?? this.clientService.currentClientConfig().client;
    await this._manageTheme(this._getThemeClassName(client));
  }

  private async _manageTheme(themeClassName: NfTheme) {
    await this._loadTheme(`${themeClassName}.css`);
    this._loadColors();
    if (this._themeLinks.length == 2) {
      this._renderer.removeChild(this._head, this._themeLinks.shift());
    }
  }

  private _loadColors() {
    const client = this.clientService.currentClientConfig();
    if (client && client.theming && client.theming.colors) {
      // Set Colors
      this.document.documentElement.style.setProperty(
        '--primary-color',
        client.theming.colors.primaryColor!
      );
      this.document.documentElement.style.setProperty(
        '--accent-color',
        client.theming.colors.accentColor!
      );
      this.document.documentElement.style.setProperty(
        '--warn-color',
        client.theming.colors.warnColor!
      );
      this.document.documentElement.style.setProperty(
        '--color-white',
        client.theming.colors.colorWhite!
      );
      this.document.documentElement.style.setProperty(
        '--color-secondary',
        client.theming.colors.colorSecondary!
      );
      this.document.documentElement.style.setProperty(
        '--primary-text-color',
        client.theming.colors.primaryTextColor!
      );
      this.document.documentElement.style.setProperty(
        '--color-grey',
        client.theming.colors.primaryGrey!
      );
      this.document.documentElement.style.setProperty(
        '--secondary-grey',
        client.theming.colors.secondaryGrey!
      );
      this.document.documentElement.style.setProperty(
        '--tertiary-grey',
        client.theming.colors.tertiaryGrey!
      );
    }
  }

  private _getThemeClassName(client?: NfClient) {
    client = client ?? this.clientService.currentClientConfig().client;
    switch (client) {
      case NfClient.Boost:
        return NfTheme.BoostTheme;
      case NfClient.JioOnline:
        return NfTheme.JioOnlineTheme;
      default:
        return NfTheme.BoostTheme;
    }
  }

  private async _loadTheme(filename: string) {
    return new Promise((resolve) => {
      const linkEl: HTMLElement = this._renderer.createElement('link');
      this._renderer.setAttribute(linkEl, 'rel', 'stylesheet');
      this._renderer.setAttribute(linkEl, 'type', 'text/css');
      this._renderer.setAttribute(linkEl, 'href', filename);
      this._renderer.setProperty(linkEl, 'onload', resolve);
      this._renderer.appendChild(this._head, linkEl);
      this._themeLinks = [...this._themeLinks, linkEl];
    });
  }
}
