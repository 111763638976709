import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BookNewDomainComponent } from './book-new-domain/book-new-domain.component';
import { OldDomainComponent } from './old-domain/old-domain.component';
import moment from 'moment';

import {
  NfApiService,
  NfApiParams,
  NfApiTokenType,
  NfApiTokenStorageType,
  NfApiHeaders,
  CanAutoUnsubscribe,
} from '@nf/common';
import { NfClientService } from '@nf/core';
import { ToastrService } from 'ngx-toastr';
import { DomainDetails } from './domain-and-email.model';
import { DomainBookingInprocessComponent } from './domain-booking-inprocess/domain-booking-inprocess.component';
import { DomainNotAllowedComponent } from './domain-not-allowed/domain-not-allowed.component';
import { LinkDomainComponent } from './link-domain/link-domain.component';
import { DICTIONARY } from './domain-email-dictionary';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MlcService } from 'projects/main-app/src/app/services/mlc.service';
import { LocalStorageKeys } from 'projects/main-app/src/environments/environment';

@Component({
  selector: 'setting-domain-and-email',
  templateUrl: './domain-and-email.component.html',
  styleUrls: ['./domain-and-email.component.scss'],
})
@CanAutoUnsubscribe
export class DomainAndEmailComponent implements OnInit {
  public showLoader = false;
  public fpTag: string = '';
  public fpId: string = '';
  public domainDetails!: DomainDetails;
  public domainBookingStatus: string = '';
  public featureDetails: any;

  //subscription variables
  public domainDetailsSub?: Subscription;
  public featureSub?: Subscription;
  public fpDetailsSub?: Subscription;
  public dialogSub?: Subscription;

  constructor(
    public dialogRef: MatDialogRef<DomainAndEmailComponent>,
    public dialog: MatDialog,
    public apiService: NfApiService,
    private toastr: ToastrService,
    private clientService: NfClientService,
    private router: Router,
    private mlcService: MlcService
  ) {
    if (this.mlcService.isMLCStore) {
      this.fpId = this.mlcService.fpId;
      this.fpTag = this.mlcService.fpTag;
    } else {
      const authToken = localStorage.getItem(LocalStorageKeys.auth_token);
      if (authToken && authToken.length) {
        const parsedData = JSON.parse(authToken);
        this.fpId = parsedData.FloatingPointId;
        this.fpTag = parsedData.FloatingPointTag;
      }
    }
  }

  ngOnInit() {
    this.getDomainDetails();
  }

  closeModal() {
    this.dialogRef.close();
  }

  getDomainDetails() {
    const authToken = localStorage.getItem(LocalStorageKeys.auth_token);
    const parsedData = JSON.parse(authToken!);

    if (!parsedData.RootAliasUri.includes('.nowfloats.com')) {
      this.router.navigate(['/setting/domain-and-email']);
      this.closeModal();
    } else {
      this.showLoader = true;
      this.domainDetailsSub = this.apiService
        .get<any>({
          config: {
            baseUrl: 'https://plugin.withfloats.com',
          },
          apiPath: `/DomainService/v3/GetDomainDetailsForFloatingPoint/${this.fpTag}`,
          params: new NfApiParams().append(
            'clientId',
            this.clientService.defaultClientConfig().clientId
          ),
        })
        .subscribe(
          (domainDetails: DomainDetails) => {
            this.domainDetails = domainDetails;
            this.getFeatureDetails(domainDetails);
          },
          (error) => {
            this.toastr.error(
              'Somthing went wrong while getting domain data',
              'Error'
            );
            this.domainBookingStatus = 'NODOMAIN';
            this.openDomainModal(this.domainBookingStatus);
            this.showLoader = false;
          }
        );
    }
  }

  getFeatureDetails(domainDetails: DomainDetails) {
    this.featureSub = this.apiService
      .get<any>({
        config: {
          baseUrl: 'https://featureprocessor.withfloats.com',
        },
        apiPath: `/Features/v1/GetFeatureDetails`,
        params: new NfApiParams()
          .append('clientId', this.clientService.defaultClientConfig().clientId)
          .append('fpId', this.fpId),
      })
      .subscribe(
        (featureList: any[]) => {
          this.featureDetails = featureList.find(
            (feature) => feature.featureKey == 'DOMAINPURCHASE'
          );
          this.setDomainStatusByFeatureDetail(
            this.featureDetails,
            domainDetails
          );
          this.showLoader = false;
        },
        (error) => {
          this.toastr.error(
            'Somthing went wrong while getting domain data',
            'Error'
          );
          this.showLoader = false;
        }
      );
  }

  setDomainStatusByFeatureDetail(
    featureDetails: any,
    domainDetails: DomainDetails
  ) {
    let todaysDate = new Date();
    let diff = moment(featureDetails?.expiryDate).diff(
      moment(todaysDate),
      'days'
    );

    diff < 0
      ? (this.domainBookingStatus = 'EXPIRED')
      : (this.domainBookingStatus = 'ACTIVE');
    if (domainDetails.hasDomain) {
      if (domainDetails.isFailed) {
        this.domainBookingStatus = 'FAILED';
      }
      this.domainBookingStatus;
      if (domainDetails.isPending) {
        this.domainBookingStatus = 'INPROCESS';
      }
      if (domainDetails.isLinked) {
        this.domainBookingStatus = 'LINKED';
      }
    } else {
      this.domainBookingStatus = 'NODOMAIN';
    }
    this.openDomainModal(this.domainBookingStatus);
    this.showLoader = false;
  }

  openDomainModal(domainBookingStatus: string) {
    switch (domainBookingStatus) {
      case 'ACTIVE':
      case 'EXPIRED':
        this.closeModal();
        this.router.navigate(['/setting/domain-and-email']);
        break;
      case 'INPROCESS':
        this.openDomainInprocess();
        break;
      case 'NODOMAIN':
        // this.router.navigate(['/setting/domain-and-email']);

        this.getFloatingPointDetailsById();
        // this.openNotAllowed()
        break;
      default:
        break;
    }
  }

  getFloatingPointDetailsById() {
    this.showLoader = true;
    const body = {
      clientId: this.clientService.defaultClientConfig().clientId,
      floatingPointId: this.fpId,
    };
    this.fpDetailsSub = this.apiService
      .post<any>(body, {
        apiPath: '/internal/v1/GetFloatingPointDetailsById',
      })
      .subscribe(
        (res: any) => {
          const checkDomainWidget =
            res.FPWebWidgets.indexOf('DOMAINPURCHASE') >= 0;
          if (
            res.PaymentLevel >= 10 &&
            res.PaymentState == 1 &&
            !res.ParentId &&
            checkDomainWidget &&
            !this.isAlreadyBooked(res)
          ) {
            this.closeModal();
            this.router.navigate(['/setting/domain-and-email']);
          } else {
            if (!checkDomainWidget) {
              this.openNotAllowed(); // expiry modal
            } else {
              this.openLinkDomain();
            }
          }
          this.showLoader = false;
        },
        (error) => {
          this.toastr.error(
            'Somthing went wrong while getting Floating Point Details By Id',
            'Error'
          );
          this.showLoader = false;
        }
      );
  }
  openNotAllowed() {
    const dialogRef = this.dialog.open(DomainNotAllowedComponent, {
      disableClose: true,
    });
    this.dialogSub = dialogRef.afterClosed().subscribe((res) => {
      this.closeModal();
    });
  }

  openDomainInprocess() {
    const dialogRef = this.dialog.open(DomainBookingInprocessComponent, {
      backdropClass: 'dark-backdrop',
    });
    this.dialogSub = dialogRef.afterClosed().subscribe((res) => {
      this.closeModal();
    });
  }

  isAlreadyBooked(res: any) {
    if (
      res.RootAliasUri.toLowerCase().includes('.' + this.brandDomain() + '.com')
    )
      return false;
    return true;
  }
  brandDomain() {
    return Object.keys(DICTIONARY).includes(
      window.location.href.split('/')[2].split('.').length > 1
        ? window.location.href
            .split('/')[2]
            .split('.')
            .slice(1)
            .join('.')
            .toUpperCase()
        : window.location.href.split('/')[2].split(':')[0].toUpperCase()
    )
      ? window.location.href.split('/')[2].split('.').slice(1).join('.')
      : 'NOWFLOATS.COM';
  }
  openLinkDomain() {
    const dialogRef = this.dialog.open(LinkDomainComponent, {});
    this.dialogSub = dialogRef.afterClosed().subscribe((res) => {
      this.closeModal();
    });
  }
}
