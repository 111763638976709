export class TaxDetails {
  TanNumber: string | undefined;
  Tax: number | undefined;
  GSTIN: string | undefined;
  TDS: number | undefined;
}

export class PaymentDetails {
  PaymentChannelProvider: string | undefined;
  Discount: number | undefined;
  TotalPrice: number | undefined;
  CurrencyCode: string | undefined;
  TaxDetails = new TaxDetails();
}

export class ConsumptionConstraint {
  MetricKey: string | undefined;
  MetricValue: number | undefined;
}

export class Expiry {
  Key: string | undefined;
  Value: number | undefined;
}

export class BundlesAddOns {
  WidgetKey!: string;
  Name!: string;
  Desc!: string;
  ConsumptionConstraint = new ConsumptionConstraint();
  Discount!: number;
  Price!: number;
  Images!: any[];
  Expiry = new Expiry();
  Category!: string;
  RecurringPaymentFrequency!: string;
  IsCancellable!: boolean;
  IsRecurringPayment!: boolean;
  Quantity!: number;
  DependentWidget!: string;
  NetPrice!: number;
  Properties!: any[];
  BaseWidgetId!: string;
}

export class Widget {
  addOnDetail?: BundlesAddOns;
  WidgetKey!: string;
  Name!: string;
  Desc!: string;
  ConsumptionConstraint = new ConsumptionConstraint();
  Discount!: number;
  Price!: number;
  Images!: any[];
  Expiry = new Expiry();
  Category!: string;
  RecurringPaymentFrequency!: string;
  IsCancellable!: boolean;
  IsRecurringPayment!: boolean;
  Quantity!: number;
  DependentWidget!: string;
  NetPrice!: number;
  Properties!: any[];
  BaseWidgetId!: string;
}

export class WidgetForZeroPayment {
  WidgetKey!: string;

  ConsumptionConstraint = new ConsumptionConstraint();
  Discount!: number;
  Price!: number;

  Expiry = new Expiry();

  RecurringPaymentFrequency!: string;
  IsCancellable!: boolean;
  IsRecurringPayment!: boolean;
  Quantity!: number;

  NetPrice!: number;
}

// Images: [],
// Category: '',
// DependentWidget : '',
// Properties: [],
// BaseWidgetId: element._kid??'',
// Name: element.name??'',
// Desc: element.description??'',

export class ExtraPurchaseOrderDetails {
  ClaimeeEmail!: string;
}

export class PurchaseOrder {
  Widgets!: Widget[];
  CouponCode: string | undefined;
  NetPrice: number | undefined;
  Discount: number | undefined;
  ExtraPurchaseOrderDetails!: ExtraPurchaseOrderDetails;
}

export class PaymentData {
  PurchaseOrderType: string | undefined;
  FloatingPointId: string | undefined;
  ClientId: string | undefined;
  PaymentDetails!: PaymentDetails;
  PurchaseOrders: PurchaseOrder[] | any;
  CartStateId: string | undefined;
  Widgets!: Widget[];
}

export class ErrorList {}

export class Error {
  ErrorCode?: any | undefined;
  ErrorList: ErrorList | undefined;
}

export class Result {
  TotalPrice: number | undefined;
  TransactionId: string | undefined;
  OrderId: string | undefined;
}

export class RemindMeResponse {
  StatusCode: number | undefined;
  Error: Error | undefined;
  Result: Result | undefined;
}

export class TransactionRequestProperties {
  SubscriptionId: string | undefined;
  PlanId: string | undefined;
}

export class AutoRenewResult {
  TransactionRequestProperties: TransactionRequestProperties | undefined;
  TransactionRequestLink: string | undefined;
  TransactionRequestHtmlBody?: any | undefined;
  TransactionRequestExpiryTime?: any | undefined;
  TotalPrice: number | undefined;
  TransactionId: string | undefined;
  OrderId?: any | undefined;
}

export class AutoRenewResponse {
  StatusCode: number | undefined;
  Error: Error | undefined;
  Result: AutoRenewResult | undefined;
}
