import { PriceManager } from './../../services/price-manager.service';
import { Component, OnInit } from '@angular/core';
import { FeatureService } from './../../services/features-service.service';
import { RenewSubscriptionModalComponent } from '../renew-subscription-modal/renew-subscription-modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-order-history-modal',
  templateUrl: './order-history-modal.component.html',
  styleUrls: ['./order-history-modal.component.scss'],
})
export class OrderHistoryModalComponent implements OnInit {
  renewHistory: any;
  constructor(
    public dialogRef: MatDialogRef<OrderHistoryModalComponent>,
    public dialog: MatDialog,
    public dataService: FeatureService,
    public priceManager: PriceManager
  ) {}

  ngOnInit(): void {
    this.getRenewHistoryDetail();
  }

  _date_field(date: any) {
    if (date) {
      let x = date.split('(');
      let y = x[1].split(')');
      let z = new Date(+y[0]);
      return z;
    } else {
      return null;
    }
  }

  onOpenInvoiceUrl(invoiceUrl: any) {
    if (invoiceUrl) {
      window.parent.open(invoiceUrl);
    }
  }

  getRenewHistoryDetail() {
    this.dataService.getRenewHistory.subscribe(
      (data) => (this.renewHistory = data)
    );
  }

  openModal(): void {
    const dialogRef = this.dialog.open(RenewSubscriptionModalComponent, {
      panelClass: ['renewal-history-modal', 'order-history-modal'],
    });
  }
}
