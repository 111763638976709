<div class="addons-detail-modal-wrapper">
  <div class="request-sent-wrapper" *ngIf="onClickSendRequest">
    <span class="modal-close" (click)="dialogRef.close()">
      <img src="assets/images/modal-close-icon.svg" />
    </span>
    <img src="assets/images/request-sent-icon.svg" />
    <h4>Request Sent</h4>
    <p>You can expect a call from us anytime soon!</p>
  </div>
  <div *ngIf="!onClickSendRequest">
    <div class="addons-detail-header">
      request a call back
      <span class="modal-close" (click)="dialogRef.close()">
        <img src="assets/images/modal-close-icon.svg" />
      </span>
    </div>
    <div class="callback-modal-wrapper">
      <div class="callback-modal-content">
        <form>
          <div class="left-control">
            <label>Your Name</label>
            <input
              readonly
              type="text"
              placeholder=""
              class="disabled"
              value="Ram Singh"
            />
          </div>
          <div class="right-control">
            <label>Your Contact Number</label>
            <input type="text" placeholder="" class="" maxlength="10" />
            <span class="prefix-digit">+91</span>
          </div>
        </form>
      </div>
      <div class="button-bx">
        <button type="button" class="btn-yellow" (click)="requestSent()">
          SEND REQUEST
        </button>
      </div>
    </div>
  </div>
</div>
