<div class="addons-detail-modal-wrapper">
  <div class="addons-detail-header">
    <i class="modal-backicon">
      <!-- <img src="assets/images/modal-back-icon.svg"> -->
    </i>
    Recommendations
    <span class="modal-close" (click)="dialogRef.close()">
      <img src="assets/images/modal-close-icon.svg" />
    </span>
  </div>
  <div class="recommended-wrapper">
    <div class="recommended-modal-content" *ngIf="feature && feature.length">
      <div *ngFor="let data of feature">
        <div
          class="recommended-card"
          (click)="onOpenModel(data); dialogRef.close()"
        >
          <img
            onerror="this.src='assets/images/thumbnail-image.svg';"
            src="{{ data.primary_image?.url }}"
          />
          <p>{{ data.name | truncate: [28, '...'] }}</p>
          <div class="price-text-bx">
            <span
              class="price-text"
              *ngIf="data.price && !data.discountedPrice"
            >
              <!-- {{ data.price | currency: '₹' }}/month -->
              {{ priceManager.getPrice(data.price) }}
            </span>
            <span class="price-text" *ngIf="data.discountedPrice">
              <!-- {{ data.discountedPrice | currency: '₹' }}/month -->
              {{ priceManager.getPrice(data.discountedPrice) }}
            </span>

            <span class="price-text" *ngIf="!data.price">Free</span>
            <span class="price-text" *ngIf="data.price && data.price == 0"
              >Free</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
