<div class="modal-content">
  <div class="modal-body">
    <!-- <img class="circle-tick-icon" src="./assets/images/ezgif.svg" /> -->
    <mat-icon class="circle-tick-icon">check_circle</mat-icon>
    <img
      (click)="closeModal()"
      class="modal-dismiss"
      src="./assets/images/closeicon.svg"
    />
    <div class="update-message-title">Website Updated</div>
    <div class="update-success-description">
      <p>
        Your changes are saved successfully published and live on your website.
        Click on below button to see it live or share with in your connections.
      </p>
    </div>
    <div class="btn-container">
      <div class="update-success-cta">
        <a class="view-website-btn" target="_blank" [href]="URL"
          ><img src="./assets/images/UpwardRightArrow.svg" />Open website</a
        >
      </div>
      <div class="share-whatsapp-cta">
        <a
          class="whatsApp-btn"
          style="color: #6bc95c"
          target="_blank"
          [href]="'https://wa.me/?text=' + URL"
        >
          <svg
            style="vertical-align: middle"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.62851 1.3576C4.5814 1.55003 1.38766 4.9157 1.39994 8.96163C1.40368 10.1939 1.7016 11.357 2.22714 12.3848L1.42024 16.296C1.37659 16.5075 1.56768 16.6928 1.77816 16.643L5.61615 15.735C6.60219 16.2255 7.70972 16.5088 8.88187 16.5266C13.0184 16.5897 16.4676 13.3099 16.5965 9.18077C16.7347 4.75464 13.0765 1.14607 8.62851 1.3576Z"
              fill="#6BC95C"
              stroke="white"
              stroke-width="1.6"
            ></path>
            <g filter="url(#filter0_d_30659_129)">
              <path
                d="M7.20053 5.84059C7.06341 5.53692 6.91899 5.53082 6.78847 5.52554C6.68175 5.52096 6.5596 5.5212 6.43768 5.5212C6.31564 5.5212 6.11727 5.56696 5.94953 5.74942C5.78167 5.93187 5.30859 6.37306 5.30859 7.27033C5.30859 8.16772 5.96472 9.03472 6.05625 9.15652C6.14778 9.2782 7.3228 11.1783 9.18364 11.9094C10.7302 12.5169 11.0448 12.3961 11.3805 12.3657C11.7163 12.3353 12.4637 11.9245 12.6162 11.4987C12.7689 11.0729 12.7689 10.7079 12.7231 10.6316C12.6773 10.5556 12.5552 10.51 12.3722 10.4188C12.1891 10.3277 11.289 9.88635 11.1212 9.82557C10.9533 9.76467 10.8313 9.7344 10.7092 9.91698C10.5871 10.0993 10.2365 10.51 10.1297 10.6316C10.0228 10.7535 9.91598 10.7687 9.73292 10.6774C9.54987 10.586 8.96018 10.3936 8.2607 9.7723C7.71648 9.28899 7.34895 8.69198 7.24211 8.50941C7.13539 8.32707 7.24034 8.23695 7.32256 8.13733C7.47064 7.95793 7.71907 7.63537 7.78009 7.51381C7.84111 7.39201 7.8106 7.28559 7.76489 7.1943C7.71907 7.10313 7.3632 6.20128 7.20053 5.84059Z"
                fill="white"
              ></path>
            </g>
            <defs>
              <filter
                id="filter0_d_30659_129"
                x="3.24056"
                y="3.45316"
                width="11.5772"
                height="11.0108"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood
                  flood-opacity="0"
                  result="BackgroundImageFix"
                ></feFlood>
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                ></feColorMatrix>
                <feOffset></feOffset>
                <feGaussianBlur stdDeviation="1.03402"></feGaussianBlur>
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                ></feColorMatrix>
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_30659_129"
                ></feBlend>
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_30659_129"
                  result="shape"
                ></feBlend>
              </filter>
            </defs>
          </svg>
          <p>Share on WhatsApp</p>
        </a>
      </div>
    </div>
    <div class="update-social-share">
      <div>Share:</div>
      <div
        style="cursor: pointer"
        [cdkCopyToClipboard]="URL"
        (click)="copyToClipBoard()"
      >
        <img src="./assets/images/Vectors.svg" />
      </div>
      <div>
        <a
          target="_blank"
          [href]="'https://www.facebook.com/sharer/sharer.php?u=' + URL"
          ><img src="assets/images/Facebook.svg"
        /></a>
      </div>
      <div>
        <a
          target="_blank"
          [href]="'http://www.twitter.com/intent/tweet?url=' + URL"
          ><img src="./assets/images/Twitter.svg"
        /></a>
      </div>
      <div>
        <a
          target="_blank"
          [href]="'https://www.linkedin.com/sharing/share-offsite/?url=' + URL"
          ><img src="assets/images/LinkedIn.svg"
        /></a>
      </div>
      <div>
        <a
          target="_blank"
          [href]="'mailto:?subject=Latest Update&amp;body=' + URL"
          ><img src="./assets/images/Mail.svg"
        /></a>
      </div>
    </div>
  </div>
</div>
