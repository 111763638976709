import { Component, OnInit } from '@angular/core';
import { NfCoreLibService } from './core-lib.service';

@Component({
  selector: 'nf-core-lib',
  templateUrl: './core-lib.component.html',
  styles: [],
})
export class NfCoreLibComponent implements OnInit {
  constructor(public coreLibService: NfCoreLibService) {}

  ngOnInit(): void {}
}
