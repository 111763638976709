import { PriceManager } from './../services/price-manager.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { CartServiceService } from '../services/cart-service.service';
import { HomeWeb } from '../model/home';
import { Location } from '@angular/common';
import { RecommendationsModalComponent } from '../modals/recommendations-modal/recommendations-modal.component';
import { FeatureService } from '../services/features-service.service';
import { CouponSystem, CouponSystemResponse } from '../model/coupon';
import { CommonService } from '../services/common.service';
import { CouponService } from '../services/coupon.service';
import {
  PaymentData,
  PaymentDetails,
  PurchaseOrder,
  RemindMeResponse,
  Widget,
  WidgetForZeroPayment,
} from '../model/payment';
import { AutoRenewSubscriptionModalComponent } from '../modals/auto-renew-subscription-modal/auto-renew-subscription-modal.component';
import { Router } from '@angular/router';
import { ConsumerService } from '../services/consumer.service';
import { TokenService } from '../services/token.service';
import { Subscription } from 'rxjs';
import { HomeService } from '../services/home.service';
import { WebengageService } from '../services/webengage.service';
import { WindowRefService } from '../window-ref.service';
import { environment } from './../../environments/environment';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BillingDetailsComponent } from '../modals/billing-details/billing-details.component';

@Component({
  selector: 'app-cart-summary',
  templateUrl: './cart-summary.component.html',
  styleUrls: ['./cart-summary.component.scss'],
  providers: [MatSnackBar],
  animations: [
    trigger('showHide', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(300, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate(300, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class CartSummaryComponent implements OnInit, OnDestroy {
  public isLoader: boolean = false;
  public minValidityNuber = 1;
  public onView: boolean | undefined;
  public onViewGst: boolean | undefined;
  public onViewEmail!: boolean;
  public onViewAddons: string | any;
  public onViewTan: boolean | undefined;
  public isEmpty = false;
  public items: any;
  public totalAmountWithGST: number = 0;
  public tanNumber = '';
  public includedAddOns: any = [];
  public homeData = new HomeWeb();
  public gstInNumber = '';
  public email = '';
  public validityNumber = 1;
  public couponCode = '';
  public isActiveLabel = 'none';
  public isCouponExisting: any;
  public isApplied: boolean = false;
  public isErrorCoupon = false;
  public discountValue = 0;
  public couponCodeRes = new CouponSystemResponse();
  public arr: any = [];
  public paymentdetails = new PaymentData();
  public purchaseOrderDetails = new PurchaseOrder();
  public cartItems: any = [];
  public couponName: any;
  public errorMsg: string = '';
  public consumerDataSubscription!: Subscription;
  public getCartSubscription!: Subscription;
  public homeDataSubscription!: Subscription;
  public clientSubscription!: Subscription;
  public arr2: any = [];
  public bgColor: any;

  constructor(
    public dialogRef: MatDialogRef<CartSummaryComponent>,
    public dialog: MatDialog,
    public cartService: CartServiceService,
    private _snackBar: MatSnackBar,
    private _location: Location,
    public featureService: FeatureService,
    private service: CommonService,
    public couponService: CouponService,
    private commonService: CommonService,
    private router: Router,
    private consumerService: ConsumerService,
    private tokenService: TokenService,
    private homeService: HomeService,
    private webEngageService: WebengageService,
    private winRef: WindowRefService,
    public cartItemService: CartServiceService,
    public priceManager: PriceManager
  ) {}

  ngOnInit(): void {
    this.webEngageService._webengage.track(`ADD-ONS Marketplace Cart Load`);

    this.consumerDataSubscription =
      this.consumerService.getConsumerData.subscribe((data) => {
        if (!data) {
          this.checkToken();
        }
      });

    this.getCartSubscription = this.cartService.getCart.subscribe((data) => {
      if (data == true) {
        this.isEmpty = false;
      } else {
        this.isEmpty = true;
        this.webEngageService._webengage.track(
          `ADD-ONS Marketplace Empty Cart Loaded`
        );
      }
      this.checkHomePageData();
      this.getCartDetails();
    });
  }

  ngOnDestroy() {
    if (this.getCartSubscription && !this.getCartSubscription.closed) {
      this.getCartSubscription.unsubscribe();
    }
    if (
      this.consumerDataSubscription &&
      !this.consumerDataSubscription.closed
    ) {
      this.consumerDataSubscription.unsubscribe();
    }
    if (this.homeDataSubscription && !this.homeDataSubscription.closed) {
      this.homeDataSubscription.unsubscribe();
    }
  }

  _getTanNumber() {
    if (this.tanNumber || this.tanNumber.length) {
      return this.tanNumber;
    } else {
      return 'ENTER NUMBER';
    }
  }

  onSaveGSTNumber(value: any) {
    if (value.toLowerCase() == 'email') {
      if (this.email) {
        this.cartService.setEmail(this.email);
        this.onViewEmail = !this.onViewEmail;
      }
    } else if (value.toLowerCase() == 'gstin') {
      if (this.gstInNumber) {
        this.cartService.setGSTINNumber(this.gstInNumber);
        this.onViewGst = !this.onViewGst;
      }
    }
  }

  isActivelabel(value: string) {
    if (value == 'none') {
      this.isActiveLabel = 'end';
    } else {
      if (value == 'gst') {
        this.isActiveLabel = 'gst';
      } else if (value == 'coupon') {
        this.isActiveLabel = 'coupon';
      } else if (value == 'email') {
        this.isActiveLabel = 'email';
      }
    }
  }

  checkHomePageData() {
    this.homeDataSubscription = this.homeService.getHomeData.subscribe(
      (data) => {
        this.homeData = data;
      }
    );
  }

  onRemoveItem(data: any) {
    this.clientSubscription = this.consumerService.getClientDetails.subscribe(
      (data) => {
        if (data) {
          this.bgColor = data.ManageConfiguration.ClientBrandingColorCode;
        }
      }
    );

    this.minValidityNuber = 1;
    this.validityNumber = 1;
    this.cartService.deleteCartItems(data);
    this.openSnackBar(data.name + ' is removed from cart');
    this.webEngageService._webengage.track(
      `ADD-ONS Marketplace AddOn crossed/ deleted from ca`
    );
    this.getCartDetails();
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      duration: 2000, // in seconds
      panelClass: ['snackbar-color'],
    });
  }

  getCartDetails() {
    var cartValues = '';
    this.items = this.cartService.getCartItems();
    if (this.items && this.items.length) {
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].included_features) {
          if (
            this.items[i].included_features &&
            this.items[i].min_purchase_months
          ) {
            if (this.minValidityNuber < this.items[i].min_purchase_months) {
              this.minValidityNuber = this.items[i].min_purchase_months;
              this.validityNumber = this.items[i].min_purchase_months;
            }
          }
        }

        if (this.items && this.items[i].name) {
          if (cartValues) {
            cartValues = cartValues + ',' + this.items[i].name;
          } else {
            cartValues = this.items[i].name;
          }
        }
      }
      this.cartService.setvalidityMonths(this.validityNumber);
      this.onApplyCoupon();
      this.cartService.onCalculate();
      this.totalAmountWithGST = this.cartService.totalAmountWithGST;
      let totalAmountWithGST = this.cartService.totalAmountWithGST.toFixed(0);
      this.webEngageService._webengage.track(
        `ADD-ONS Marketplace Full_Cart Loaded`,
        {
          'total amount': Number(totalAmountWithGST),
          'cart size': this.cartItemService.getlengthCartItems(),
          'cart ids': cartValues,
          'cart validity months': this.validityNumber,
        }
      );
    } else {
      this.webEngageService._webengage.track(
        `ADD-ONS Marketplace Empty Cart Loaded`
      );
      this.isEmpty = true;
    }
  }

  onDiscountClick() {
    this.onView = !this.onView;
  }

  onFillInGstClick(value: any) {
    if (value.toLowerCase() == 'email') {
      this.onViewEmail = !this.onViewEmail;
    } else if (value.toLowerCase() == 'gstin') {
      this.onViewGst = !this.onViewGst;
    }
  }

  onViewAddonClick(included_features: any, id: any) {
    if (included_features) {
      this.includedAddOns = [];
      this.includedAddOns = included_features;
    }

    if (id) {
      if (this.onViewAddons == id) {
        this.onViewAddons = null;
      } else {
        this.onViewAddons = id;
      }
    }
    if (this.onViewAddons == id) {
      this.getAddonsData();
    }
  }

  getAddonsData() {
    if (this.includedAddOns && this.includedAddOns.length) {
      for (var i = 0; i < this.includedAddOns.length; i++) {
        const addonDetail = this.homeData.Data[0].features.filter(
          (element) =>
            element.feature_code == this.includedAddOns[i].feature_code
        );
        this.includedAddOns[i].addOnDetail = addonDetail[0];
      }
    }
  }

  goBack() {
    this._location.back();
  }

  onCartEmpty() {
    this.isEmpty = !this.isEmpty;
  }
  onEnterTan(value: any) {
    if (value) {
      this.tanNumber = value;
      this.cartService.setTanNumber(this.tanNumber);
    }
    this.onViewTan = !this.onViewTan;
  }

  openModal(): void {
    const dialogRef = this.dialog.open(BillingDetailsComponent, {
      panelClass: ['renewal-history-modal', 'order-history-modal'],
      data: {
        openRenewModel: true,
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result?.data) {
      }
    });
  }

  onOpenModel() {
    if (
      !this.cartService.totalAmountWithOutGST ||
      this.cartService.totalAmountWithOutGST == 0
    ) {
      const dialogRef = this.dialog.open(BillingDetailsComponent, {
        panelClass: ['renewal-history-modal', 'order-history-modal'],
        data: {
          openRenewModel: false,
        },
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result.data) {
          this.isLoader = true;
          this.setDefaultData();
          this.commonService
            .onFreeCartProceed(
              this.paymentdetails,
              this.cartService.getClientID()
            )
            .subscribe(
              (data) => {
                this.isLoader = false;
                this.errorMsg = '';
                this.checkToken();
                const dialogRef = this.dialog.open(
                  AutoRenewSubscriptionModalComponent,
                  {
                    panelClass: 'addons-detail-modal',
                    data: {
                      cartItems: this.cartService.getCartItems(),
                    },
                  }
                );

                this.cartService.emptyCartItems();
              },
              (error) => {
                this.isLoader = false;
                this.errorMsg = 'Something wen`t wrong!';
              }
            );
        }
      });
    } else {
      if (this.cartService.validityMonths == 1) {
        this.openModal();
      } else {
        const dialogRef = this.dialog.open(BillingDetailsComponent, {
          panelClass: ['renewal-history-modal', 'order-history-modal'],
          data: {
            openRenewModel: false,
          },
        });
        dialogRef.afterClosed().subscribe((result: any) => {
          if (result.data) {
            this.remindMetoRenew();
          }
        });
      }
    }
  }

  remindMetoRenew() {
    this.isLoader = true;
    this.setDefaultDataRemindMeToRenew();
    this.commonService.remindMeToRenew(this.paymentdetails).subscribe(
      (data) => {
        let remindMeRes = data as RemindMeResponse;
        this.cartService.setPurchaseOrder(remindMeRes, 'remindRenew');
        this.isLoader = false;
        this.errorMsg = '';
        this.payWithRazor(remindMeRes);
      },
      (err) => {
        this.errorMsg = 'Something went wrong. Please try again later';
        this.isLoader = false;
      }
    );
  }

  payWithRazor(remindMeRes: any) {
    this.webEngageService._webengage.track(
      `ADD-ONS Marketplace Payment Screen Loaded`
    );

    this.webEngageService._webengage.track(
      `ADD-ONS Marketplace Razor Pay View Loaded`
    );
    let primaycolor: string = '';
    this.consumerService.getClientDetails.subscribe((data) => {
      primaycolor = data?.ManageConfiguration?.ClientBrandingColorCode;
    });
    const options: any = {
      key: environment.razorpayKey,
      amount: this.priceManager.getPriceWithoutMonth(
        remindMeRes.Result?.TotalPrice
      ),
      currency: 'INR',
      name: '', // company name or product name
      description: '', // product description
      image: '', // company logo or product image
      order_id: remindMeRes.Result?.OrderId,
      modal: {
        escape: true,
      },
      notes: {
        // include notes if any
      },
      theme: {
        color: primaycolor,
      },
    };
    options.handler = (response: any, error: any) => {
      options.response = response;
      var cartItemNames = '';
      this.cartItems = this.cartService.getCartItems();
      if (this.cartItems && this.cartItems.length) {
        for (let i = 0; i < this.cartItems.length; i++) {
          if (cartItemNames) {
            cartItemNames = cartItemNames + ',' + this.cartItems[i].name;
          } else {
            cartItemNames = this.cartItems[i].name;
          }
        }
      }

      this.webEngageService._webengage.track(
        `ADDONS_MARKETPLACE Payment Success`,
        {
          revenue: this.priceManager.getPriceWithoutMonth(
            remindMeRes.Result?.TotalPrice
          ),
          cartIds: cartItemNames,
          couponIds: this.couponCode ? this.couponCode : '',
          rev: this.priceManager.getPriceWithoutMonth(
            remindMeRes.Result?.TotalPrice
          ),
        }
      );

      this.cartService.setPaymentDetails(options.response);
      this.dialogRef.close();
      this.openModal();
      this.cartService.emptyCartItems();
      this.checkToken();
      // call your backend api to verify payment signature & capture transaction
    };
    options.modal.ondismiss = () => {
      this.webEngageService._webengage.track(
        `ADD-ONS Marketplace Payment Failed`
      );
      // handle the case when user closes the form while transaction is in progress
    };
    const rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open();
  }

  onOpenViewModal() {
    if (this.homeData && this.homeData.Data && this.homeData.Data.length) {
      this.featureService.setFeaturesArray(
        this.homeData.Data[0].filteredFeatureByCategory
      );
      this.openModalRecommendations();
    }
  }

  openModalRecommendations(): void {
    const dialogRef = this.dialog.open(RecommendationsModalComponent, {
      panelClass: 'addons-detail-modal',
    });
  }

  _getValidity() {
    return this.validityNumber;
  }

  setPayloadForCouponSystem(couponCode: any, fpId: string, amount: any) {
    let couponSystem = new CouponSystem();
    couponSystem.couponCode = couponCode;
    couponSystem.fpId = fpId;
    couponSystem.cartValue = amount;
    return couponSystem;
  }

  checkDiscountCouponsInner() {
    if (
      this.homeData &&
      this.homeData.Data &&
      this.homeData.Data[0].discount_coupons &&
      this.homeData.Data[0].discount_coupons.length
    ) {
      for (var i = 0; i < this.homeData.Data[0].discount_coupons.length; i++) {
        if (this.homeData.Data[0].discount_coupons[i].code == this.couponCode) {
          return this.homeData.Data[0].discount_coupons[i];
        }
      }
      return null;
    } else {
      return null;
    }
  }

  onApplyCoupon() {
    if (this.couponCode) {
      this.webEngageService._webengage.track(
        `ADD-ONS Marketplace Discount Coupon Loaded`,
        {
          coupon: this.couponCode,
        }
      );
      this.couponService.setCouponName(this.couponCode);
      // this.couponSystem.couponCode = this.couponCode;
      // this.couponSystem.fpId = this.cartService.getFpID();
      // this.couponSystem.cartValue = this.cartService.totalAmountWithOutGST;
      let couponSystem = this.setPayloadForCouponSystem(
        this.couponCode,
        this.cartService.getFpID(),
        this.priceManager.getPriceWithoutMonth(
          this.cartService.totalAmountWithOutGST
        )
      );
      let innerApiData: any = this.checkDiscountCouponsInner();
      if (innerApiData) {
        this.isApplied = true;
        this.isErrorCoupon = false;
        let discountedMoney =
          (innerApiData.discount_percent *
            this.cartService.totalAmountWithOutGST) /
          100;
        this.cartService.onSetCouponDiscount(discountedMoney);
        this.couponCodeRes.message = null;
        this.couponCodeRes.discountAmount = discountedMoney;
        this.couponService.setCoupon(this.couponCodeRes);
      } else {
        // this.service.postCouponCode(this.couponSystem).subscribe(
        //   (data) => {
        //     this.couponCodeRes = data as CouponSystemResponse;
        //     if (this.couponCodeRes.success) {
        //       this.isApplied = true;
        //       this.isErrorCoupon = false;
        //       this.cartService.onSetCouponDiscount(
        //         this.couponCodeRes.discountAmount
        //       );
        //       this.couponService.setCoupon(this.couponCodeRes);
        //     } else {
        //       this.isApplied = false;
        //       this.isErrorCoupon = true;
        //     }
        //   },
        //   (error) => {}
        // );
        this.couponcodeApiCall(couponSystem);
      }
    } else {
      if (this.couponService.getCoupon()) {
        // this.couponCodeRes = this.couponService.getCoupon();
        // this.couponName = this.couponService.getCouponName();

        // if (this.couponCodeRes) {
        //   if (this.couponCodeRes.success) {
        //     this.isApplied = true;
        //     this.isErrorCoupon = false;
        //     this.cartService.onSetCouponDiscount(
        //       this.couponCodeRes.discountAmount
        //     );
        //   } else {
        //     this.isApplied = false;
        //     this.isErrorCoupon = true;
        //   }
        // }
        let couponSystem = this.setPayloadForCouponSystem(
          this.couponService.getCouponName(),
          this.cartService.getFpID(),
          this.priceManager.getPriceWithoutMonth(
            this.cartService.totalAmountWithOutGST
          )
        );
        this.couponcodeApiCall(couponSystem);
      }
    }
  }

  couponcodeApiCall(couponSystem: CouponSystem) {
    this.commonService.postCouponCode(couponSystem).subscribe(
      (data: any) => {
        this.couponCodeRes = data as CouponSystemResponse;
        if (this.couponCodeRes.success) {
          this.isApplied = true;
          this.isErrorCoupon = false;
          this.cartService.onSetCouponDiscount(
            this.couponCodeRes.discountAmount
          );
          this.couponService.setCoupon(this.couponCodeRes);
        } else {
          this.isApplied = false;
          this.isErrorCoupon = true;
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  onEditCoupon() {
    this.isApplied = false;
    this.isErrorCoupon = false;
    // this.couponService.setCouponName(null);
    this.cartService.onSetCouponDiscount(0);
    this.couponName = null;
    this.couponService.setCouponName(null);
  }

  // _couponDiscount() {
  //   if (this.isCouponExisting && this.isCouponExisting.length) {
  //     return this.calculateDiscount();
  //   } else {
  //     return this.discountValue;
  //   }
  // }

  // calculateDiscount() {
  //   this.discountValue =
  //     (this.totalAmountWithOutGST * this.isCouponExisting[0].discount_percent) /
  //     100;
  //   return this.discountValue;
  // }

  getAddonsDataBundles() {
    this.includedAddOns = [];
    this.checkHomePageData();
    this.cartItems = this.cartService.getCartItems();
    if (this.cartItems && this.cartItems.length) {
      for (var i = 0; i < this.cartItems.length; i++) {
        if (this.cartItems && this.cartItems[i].included_features) {
          this.includedAddOns = [
            ...this.includedAddOns,
            ...this.cartItems[i].included_features,
          ];
        } else {
          this.includedAddOns = [
            ...this.includedAddOns,
            ...[this.cartItems[i]],
          ];
        }
      }
    }

    if (this.includedAddOns && this.includedAddOns.length) {
      for (var i = 0; i < this.includedAddOns.length; i++) {
        if (this.includedAddOns[i].feature_code) {
          const addonDetail = this.homeData.Data[0].features.filter(
            (element: { feature_code: any }) =>
              element.feature_code.toLowerCase() ==
              this.includedAddOns[i].feature_code.toLowerCase()
          );
          this.includedAddOns[i].addOnDetail = addonDetail[0];
        }
      }
    }
  }

  setDefaultDataRemindMeToRenew() {
    const paymentDetails: PaymentDetails = {
      Discount: this.cartService.couponDiscountPrice ?? 0,
      TotalPrice: this.cartService.totalAmountWithGST ?? 0,
      CurrencyCode: 'INR',
      PaymentChannelProvider: 'RAZORPAY',
      TaxDetails: {
        TanNumber: this.cartService.getTanNumber() ?? '',
        Tax: 18,
        GSTIN: this.cartService.getGSTINNumber() ?? '',
        TDS: 0,
      },
    };
    this.paymentdetails.PaymentDetails = paymentDetails;
    this.paymentdetails.PurchaseOrderType = 'NEW';
    this.paymentdetails.FloatingPointId = this.cartService.getFpID();
    this.paymentdetails.ClientId = this.cartService.getClientID();
    this.paymentdetails.CartStateId = '';
    let purchaseOrderDetails = this.setPurchaseOrdersRemindMeToRenew();
    this.paymentdetails.PurchaseOrders = [purchaseOrderDetails];
  }

  setDefaultData() {
    this.paymentdetails.PurchaseOrderType = 'NEW';
    this.paymentdetails.FloatingPointId = this.cartService.getFpID();
    this.paymentdetails.ClientId = this.cartService.getClientID();
    let dataWidgets = this.setPurchaseOrders();
    this.paymentdetails.Widgets = dataWidgets;
  }

  paymentChannelProviderAutoRenew() {
    this.paymentdetails.PaymentDetails.PaymentChannelProvider =
      'RAZORPAY_SUBSCRIPTION_LINK';
  }
  paymentChannelProviderRemindRenew() {
    this.paymentdetails.PaymentDetails.PaymentChannelProvider = 'RAZORPAY';
  }

  setPurchaseOrders() {
    this.arr = [];
    this.getAddonsDataBundles();
    this.includedAddOns.forEach(
      (element: {
        _kid: string;
        boost_widget_key: string;
        name: string;
        description: string;
        addOnDetail: any;
        price: number;
        discount_percent: number;
        feature_price_discount_percent: number;
      }) => {
        const purchaseOrderDetailsWidgets: WidgetForZeroPayment = {
          WidgetKey: element.addOnDetail
            ? element.addOnDetail.boost_widget_key
            : element.boost_widget_key,
          ConsumptionConstraint: {
            MetricKey: 'DAYS',
            MetricValue:
              30 *
              this.priceManager.getPriceWithoutMonth(
                this.cartService.validityMonths ?? 1
              ),
          },
          Discount: this.cartService.couponDiscountPrice ?? 1,
          // Expiry : {
          //   Key: 'DAYS',
          //   Value: 1
          // },
          Expiry: {
            Key: 'MONTHS',
            Value: this.priceManager.getPriceWithoutMonth(
              this.cartService.validityMonths ?? 1
            ),
          },
          IsCancellable: true,
          IsRecurringPayment: true,
          Price: element.addOnDetail
            ? element.addOnDetail.price
            : element.price,

          NetPrice: element.addOnDetail
            ? element.addOnDetail.price
            : element.price,

          Quantity: 1,
          RecurringPaymentFrequency: 'MONTHLY',
        };
        this.arr.push(purchaseOrderDetailsWidgets);
      }
    );
    return this.arr;
  }

  setPurchaseOrdersRemindMeToRenew() {
    this.arr2 = [];
    this.getAddonsDataBundles();
    this.includedAddOns.forEach(
      (element: {
        _kid: string;
        boost_widget_key: string;
        name: string;
        description: string;
        addOnDetail: any;
        price: number;
        discount_percent: number;
        feature_price_discount_percent: number;
        extended_properties: any;
      }) => {
        const purchaseOrderDetailsWidgets: Widget = {
          WidgetKey: element.addOnDetail
            ? element.addOnDetail.boost_widget_key
            : element.boost_widget_key,
          Name: element.addOnDetail ? element.addOnDetail.name : element.name,
          Desc: element.addOnDetail
            ? element.addOnDetail.description
            : element.description,
          ConsumptionConstraint: {
            MetricKey: 'DAYS',
            MetricValue:
              30 *
              this.priceManager.getPriceWithoutMonth(
                this.cartService.validityMonths ?? 1
              ),
          },
          Discount: this.cartService.couponDiscountPrice ?? 1,
          Price: element.addOnDetail
            ? element.addOnDetail.price ?? 0
            : element.price ?? 0,
          Images: [],
          Expiry: {
            Key: 'MONTHS',
            Value: this.priceManager.getPriceWithoutMonth(
              this.cartService.validityMonths ?? 1
            ),
          },
          Category: '',
          RecurringPaymentFrequency: 'MONTHLY',
          IsCancellable: true,
          IsRecurringPayment: true,
          Quantity: 1,
          DependentWidget: '',

          NetPrice: element.addOnDetail
            ? element.addOnDetail.price ?? 0
            : element.price ?? 0,
          Properties: element.extended_properties ?? [],
          BaseWidgetId: element.addOnDetail
            ? element.addOnDetail._kid
            : element._kid,
        };
        this.arr2.push(purchaseOrderDetailsWidgets);
      }
    );

    const purchaseOrderDetails: PurchaseOrder = {
      CouponCode: this.couponService.getCouponName() ?? '',
      NetPrice: this.priceManager.getPriceWithoutMonth(
        this.cartService.totalAmountWithOutGST ?? 0
      ),

      Discount: this.cartService.couponDiscountPrice ?? 1,
      ExtraPurchaseOrderDetails: {
        ClaimeeEmail: this.cartService.getEmail() ?? '',
      },
      Widgets: this.arr2,
    };
    return purchaseOrderDetails;
  }

  animateClick() {
    // this.state = this.state === 'collapse' ? 'expand' : 'collapse';
  }
  // onCalculateValidity(symbol: string) {
  //   if (symbol == '-') {
  //     if (this.validityNumber > this.minValidityNuber) {
  //       this.validityNumber = this.validityNumber - 1;
  //       this.getCartDetails();
  //     }
  //   } else if (symbol == '+') {
  //     let maxYears = 5;
  //     let maxMonths = 5 * 12;
  //     if (this.priceManager.isAnnualSetUp) {
  //       if (this.validityNumber < maxYears) {
  //         this.validityNumber = this.validityNumber + 1;
  //         this.getCartDetails();
  //       }
  //     } else {
  //       if (this.validityNumber < maxMonths) {
  //         this.validityNumber = this.validityNumber + 1;
  //         this.getCartDetails();
  //       }
  //     }
  //   }
  // }
  onCalculateValidity(symbol: string) {
    if (symbol == '-') {
      if (this.validityNumber > this.minValidityNuber) {
        if (this.priceManager.isAnnualSetUp) {
          this.validityNumber = this.validityNumber - 1;
        } else {
          if (this.validityNumber > 12) {
            if (this.validityNumber % 12 == 0) {
              this.validityNumber = this.validityNumber - 12;
            } else {
              this.validityNumber =
                this.validityNumber - (this.validityNumber % 12);
            }
          } else {
            if (this.validityNumber <= 12) {
              if (this.validityNumber % 3 == 0) {
                this.validityNumber = this.validityNumber - 3;
              } else if (this.validityNumber % 3 == 1) {
                this.validityNumber = this.validityNumber - 1;
              } else if (this.validityNumber % 3 == 2) {
                this.validityNumber = this.validityNumber - 2;
              }
            }
          }
        }
        if (this.validityNumber < 1) {
          this.validityNumber = 1;
        }

        // this.validityNumber = this.validityNumber - 1;
        this.getCartDetails();
      }
    } else if (symbol == '+') {
      let maxYears = 5;
      let maxMonths = 5 * 12;

      if (this.priceManager.isAnnualSetUp) {
        if (this.validityNumber < maxYears) {
          this.validityNumber = this.validityNumber + 1;
          this.getCartDetails();
        }
      } else {
        if (this.validityNumber < maxMonths) {
          if (this.validityNumber == 1) {
            this.validityNumber = this.validityNumber + 2;
          } else if (this.validityNumber >= 12 && this.validityNumber < 60) {
            if (this.validityNumber % 12 == 0) {
              this.validityNumber = this.validityNumber + 12;
            } else {
              this.validityNumber =
                this.validityNumber + (12 - (this.validityNumber % 12));
            }
          } else {
            if (this.validityNumber < 60 && this.validityNumber < 12) {
              if (this.validityNumber % 3 == 0) {
                this.validityNumber = this.validityNumber + 3;
              } else if (this.validityNumber % 3 == 1) {
                this.validityNumber = this.validityNumber + 2;
              } else if (this.validityNumber % 3 == 2) {
                this.validityNumber = this.validityNumber + 1;
              }
            }
          }

          // this.validityNumber = this.validityNumber + 1;
          this.getCartDetails();
        }
      }
    }
  }

  checkToken() {
    const token = this.tokenService.getToken();
    if (token) {
      this.router.navigateByUrl('/?token=' + token);
    } else {
      this.router.navigateByUrl('/');
    }
  }
}
