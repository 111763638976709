export enum NfApiResponseType {
  ARRAY_BUFFER = 'arraybuffer',
  BLOB = 'blob',
  JSON = 'json',
  TEXT = 'text',
}

export enum NfApiTokenType {
  BEARER = 'Bearer',
  BASIC = 'Basic',
}

export enum NfApiTokenStorageType {
  LOCAL_STORAGE = 'localstorage',
  COOKIE = 'cookie',
}
