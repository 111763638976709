import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  constructor(private snackbar: MatSnackBar) {}
  openSnackbar(type: string, message: string) {
    this.snackbar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      duration: 2000, // in seconds
      panelClass: ['snackbar-' + type],
    });
  }
}
