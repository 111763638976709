import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccessToken } from '../model/accessToken';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  baseUrl = `https://boost-dashboard-wrapper.nowfloats.com`;
  couponUrl: string = 'https://coupons.withfloats.com';
  baseUrl3: string = 'https://api.withfloats.com/Payment/v9/floatingpoint/';
  baseUrl2: string = 'https://api2.withfloats.com/';
  httpHeaders: any;

  constructor(
    private httpClient: HttpClient,
    private tokenService: TokenService
  ) {}

  onFreeCartProceed(body: any, clientId: string) {
    return this.httpClient.post(
      this.baseUrl + '/marketplace/activate-purchase-order',
      body,
      {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.tokenService.getToken()}`,
          accessToken: `Bearer ${this.tokenService.getAccessToken()}`,
        }),
      }
    );
  }

  getLoginDetails() {
    return this.httpClient.get(this.baseUrl + '/core/login-details', {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.tokenService.getToken()}`,
        accessToken: `Bearer ${this.tokenService.getAccessToken()}`,
      }),
    });
  }

  getMyAdOns() {
    return this.httpClient.get(this.baseUrl + '/marketplace/purchase-orders', {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.tokenService.getToken()}`,
        accessToken: `Bearer ${this.tokenService.getAccessToken()}`,
      }),
    });
  }

  getWebsiteDetails(websiteId: string) {
    return this.httpClient.get(
      this.baseUrl + `/marketplace/home?website=${websiteId}`,
      {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.tokenService.getToken()}`,
          accessToken: `Bearer ${this.tokenService.getAccessToken()}`,
        }),
      }
    );
  }

  remindMeToRenew(body: any) {
    return this.httpClient.post(
      this.baseUrl + '/marketplace/create-purchase-order-v1',
      body,
      {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.tokenService.getToken()}`,
          accessToken: `Bearer ${this.tokenService.getAccessToken()}`,
        }),
      }
    );
  }
  autoRenew(body: any) {
    return this.httpClient.post(
      this.baseUrl + '/marketplace/create-purchase-order-v2',
      body,
      {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.tokenService.getToken()}`,
          accessToken: `Bearer ${this.tokenService.getAccessToken()}`,
        }),
      }
    );
  }

  getFloatingPointByClientOrFpId() {
    return this.httpClient.get(this.baseUrl + '/marketplace/fp-details', {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.tokenService.getToken()}`,
        accessToken: `Bearer ${this.tokenService.getAccessToken()}`,
      }),
    });
  }

  getPremiumPlans() {
    return this.httpClient.get(this.baseUrl + '/marketplace/premium-plans', {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.tokenService.getToken()}`,
        accessToken: `Bearer ${this.tokenService.getAccessToken()}`,
      }),
    });
  }

  postCouponCode(data: any) {
    return this.httpClient.post(this.couponUrl + '/v1/coupons/redeem', data, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.tokenService.getToken()}`,
        accessToken: `Bearer ${this.tokenService.getAccessToken()}`,
      }),
    });
  }

  getClientDetails() {
    return this.httpClient.get(this.baseUrl + '/core/client-details', {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.tokenService.getToken()}`,
        accessToken: `Bearer ${this.tokenService.getAccessToken()}`,
      }),
    });
  }

  getAccessTokenByRefreshToken(data: AccessToken) {
    return this.httpClient.post(
      `${this.baseUrl2}discover/v1/FloatingPoint/AccessToken/Create`,
      data,
      {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.tokenService.getToken()}`,
        }),
      }
    );
  }

  // getToken(floatingPointId: string, customerClientId: string, loginId: string) {
  //   return this.httpClient.get(
  //     `https://tokenserver.withfloats.com/api/generate-token`,
  //     {
  //       headers: new HttpHeaders({
  //         'x-fpid': floatingPointId,
  //         'x-clientid': customerClientId,
  //         'x-loginid': loginId,
  //         'Access-Control-Allow-Origin': '*',
  //       }),
  //     }
  //   );
  // }

  CreateCustomerPaymentProfile(data: any) {
    return this.httpClient.post(
      `${this.baseUrl3}CreateCustomerPaymentProfile`,
      data,
      {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.tokenService.getToken()}`,
          accessToken: `Bearer ${this.tokenService.getAccessToken()}`,
        }),
      }
    );
  }
  UpdateCustomerPaymentProfile(data: any) {
    return this.httpClient.put(
      `${this.baseUrl3}UpdateCustomerPaymentProfile`,
      data,
      {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.tokenService.getToken()}`,
          accessToken: `Bearer ${this.tokenService.getAccessToken()}`,
        }),
      }
    );
  }
  CustomerPaymentProfile(id: string, clientId: string) {
    return this.httpClient.get(this.baseUrl3 + `CustomerPaymentProfile/${id}`, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.tokenService.getToken()}`,
        accessToken: `Bearer ${this.tokenService.getAccessToken()}`,
      }),
      params: new HttpParams().append('clientid', clientId),
    });
  }

  GetStateCode(clientId: string) {
    return this.httpClient.get(
      this.baseUrl2 + `api/v1/Business/GetStateCode?clientId=${clientId}`,
      {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.tokenService.getToken()}`,
          accessToken: `Bearer ${this.tokenService.getAccessToken()}`,
        }),
      }
    );
  }

  GetGSTINInformation(clientId: string, gstIn: string) {
    return this.httpClient.get(
      this.baseUrl2 + `api/v1/Business/GetGSTINInformation`,
      {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.tokenService.getToken()}`,
          accessToken: `Bearer ${this.tokenService.getAccessToken()}`,
        }),
        params: new HttpParams()
          .append('clientid', clientId)
          .append('gstin', gstIn),
      }
    );
  }
}
