import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'setting-domain-booking-inprocess',
  templateUrl: './domain-booking-inprocess.component.html',
  styleUrls: ['./domain-booking-inprocess.component.scss'],
})
export class DomainBookingInprocessComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DomainBookingInprocessComponent>
  ) {}

  ngOnInit(): void {}

  closeModal() {
    this.dialogRef.close();
  }
}
