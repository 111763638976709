<div class="modal-content">
  <div class="modal-body">
    <!-- <img class="circle-tick-icon" src="./assets/images/ezgif.svg" /> -->
    <mat-icon class="circle-tick-icon">check_circle</mat-icon>
    <img
      (click)="closeModal()"
      class="modal-dismiss"
      src="assets/img/closeicon.svg
      "
    />
    <div class="update-message-title">Update Posted successfully</div>
    <div class="update-success-description">
      <p>
        Click on the below button to see it live or share with in your
        connections.
      </p>
    </div>
    <div class="update-success-cta">
      <a
        class="view-website-btn"
        target="_blank"
        href="https://cairo.getboost360.com"
        >View on website<img src="assets/img/UpwardRightArrow.svg"
      /></a>
    </div>
    <div class="update-social-share">
      <div>Share:</div>
      <div
        style="cursor: pointer"
        [cdkCopyToClipboard]="URL"
        (click)="copyToClipBoard()"
      >
        <img src="assets/img/Vectors.svg" />
      </div>
      <div>
        <a
          target="_blank"
          [href]="'https://www.facebook.com/sharer/sharer.php?u=' + URL"
          ><img src="assets/urlimg/Facebook.svg"
        /></a>
      </div>
      <div>
        <a
          target="_blank"
          [href]="'http://www.twitter.com/intent/tweet?url=' + URL"
          ><img src="assets/urlimg/Twitter.svg"
        /></a>
      </div>
      <div>
        <a target="_blank" [href]="'https://wa.me/?text=' + URL"
          ><img src="assets/urlimg/Whatsapp.svg"
        /></a>
      </div>
      <div>
        <a
          target="_blank"
          [href]="'https://www.linkedin.com/sharing/share-offsite/?url=' + URL"
          ><img src="assets/urlimg/LinkedIn.svg"
        /></a>
      </div>
      <div>
        <a
          target="_blank"
          [href]="'mailto:?subject=Latest Update&amp;body=' + URL"
          ><img src="assets/urlimg/Mail.svg"
        /></a>
      </div>
    </div>
  </div>
</div>
