import { HttpHeaders, HttpParams } from '@angular/common/http';
import {
  NfApiResponseType,
  NfApiTokenStorageType,
  NfApiTokenType,
} from './api.enum';

export interface NfApiConfig {
  baseUrl: string;
  tokenKey?: string;
  tokenType?: NfApiTokenType;
  storedIn?: NfApiTokenStorageType;
  headers?: NfApiHeaders;
  authData?: string;
  refreshToken?: string;
  mainToken?: string;
}

export interface NfApiOptions {
  apiPath: string;
  params?: NfApiParams;
  headers?: NfApiHeaders;
  responseType?: NfApiResponseType;
  withCredentials?: boolean;
  config?: NfApiConfig;
}

export class NfApiParams extends HttpParams {}

export class NfApiHeaders extends HttpHeaders {}

export interface NfApiMethodOptions {
  headers?: NfApiHeaders;
  observe?: 'body' | 'events' | 'response';
  params?: NfApiParams;
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
}
