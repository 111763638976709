import { PriceManager } from './../../services/price-manager.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  AutoRenewResponse,
  PaymentData,
  PaymentDetails,
  PurchaseOrder,
  RemindMeResponse,
  Widget,
} from './../../model/payment';
import { CartServiceService } from './../../services/cart-service.service';
import { CommonService } from './../../services/common.service';
import { ConsumerService } from './../../services/consumer.service';
import { CouponService } from './../../services/coupon.service';
import { HomeService } from './../../services/home.service';
import { TokenService } from './../../services/token.service';
import { WebengageService } from './../../services/webengage.service';
import { WindowRefService } from './../../window-ref.service';
import { environment } from './../../../environments/environment';
import { AutoRenewSubscriptionModalComponent } from '../auto-renew-subscription-modal/auto-renew-subscription-modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-renew-subscription-modal',
  templateUrl: './renew-subscription-modal.component.html',
  styleUrls: ['./renew-subscription-modal.component.scss'],
  providers: [WindowRefService],
})
export class RenewSubscriptionModalComponent implements OnInit, OnDestroy {
  value: string = 'AutoRenew';
  paymentdetails = new PaymentData();
  purchaseOrderDetails = new PurchaseOrder();
  purchaseOrderDetailsWidgets = new Widget();
  remindMeRes = new RemindMeResponse();
  autoRenewRes = new AutoRenewResponse();
  isLoader = false;
  consumerDataSubscription!: Subscription;
  homeDataSubscription!: Subscription;
  cartItems: any;
  constructor(
    public dialogRef: MatDialogRef<RenewSubscriptionModalComponent>,
    public dialog: MatDialog,
    private apiService: CommonService,
    public cartService: CartServiceService,
    private winRef: WindowRefService,
    private router: Router,
    public consumerService: ConsumerService,
    public couponService: CouponService,
    private tokenService: TokenService,
    private homeService: HomeService,
    private webEngageService: WebengageService,
    private priceManager: PriceManager
  ) {}

  includedAddOns: any = [];
  homeData: any;
  homeDatas: any;

  ngOnInit(): void {
    this.cartItems = this.cartService.getCartItems();
    this.cartService.onCalculate();
    this.getAddonsData();

    this.consumerDataSubscription =
      this.consumerService.getConsumerData.subscribe((data) => {
        if (!data) {
          const token = this.tokenService.getToken();
          if (token) {
            this.router.navigateByUrl('/?token=' + token);
          } else {
            this.router.navigateByUrl('/addon-marketplace/marketplace');
          }
        }
      });
  }

  ngOnDestroy() {
    if (
      this.consumerDataSubscription &&
      !this.consumerDataSubscription.closed
    ) {
      this.consumerDataSubscription.unsubscribe();
    }
    if (this.homeDataSubscription && !this.homeDataSubscription.closed) {
      this.homeDataSubscription.unsubscribe();
    }
  }

  checkHomePageData() {
    this.homeDataSubscription = this.homeService.getHomeData.subscribe(
      (data) => {
        if (data) {
          this.homeData = data;
        }
      }
    );
  }

  getAddonsData() {
    this.checkHomePageData();
    if (this.cartItems && this.cartItems.length) {
      for (var i = 0; i < this.cartItems.length; i++) {
        if (this.cartItems && this.cartItems[i].included_features) {
          if (this.cartItems[i].included_features) {
            for (
              let x = 0;
              x < this.cartItems[i].included_features.length;
              x++
            ) {
              this.cartItems[i].included_features[
                x
              ].feature_price_discount_percent =
                this.cartItems[i].overall_discount_percent ?? 0;
            }
          }

          this.includedAddOns = [
            ...this.includedAddOns,
            ...this.cartItems[i].included_features,
          ];
        } else {
          this.includedAddOns = [
            ...this.includedAddOns,
            ...[this.cartItems[i]],
          ];
        }
      }
    }
    if (this.includedAddOns && this.includedAddOns.length) {
      for (var i = 0; i < this.includedAddOns.length; i++) {
        if (this.includedAddOns[i].feature_code) {
          const addonDetail = this.homeData.Data[0].features.filter(
            (element: { feature_code: any }) =>
              element.feature_code == this.includedAddOns[i].feature_code
          );
          this.includedAddOns[i].addOnDetail = addonDetail[0];
        }
      }
    }
  }

  openModal(): void {
    const dialogRef = this.dialog.open(AutoRenewSubscriptionModalComponent, {
      data: {
        cartItems: this.cartService.getCartItems(),
      },
    });
  }

  onItemChange(value: any) {
    this.value = value;
  }

  errorMsg = '';
  onProceed() {
    this.errorMsg = '';
    if (this.value == 'AutoRenew') {
      this.isLoader = true;

      this.setDefaultData();
      this.apiService.autoRenew(this.paymentdetails).subscribe(
        (data) => {
          this.autoRenewRes = data as AutoRenewResponse;
          this.cartService.setPurchaseOrder(this.autoRenewRes, this.value);
          this.isLoader = false;
          this.errorMsg = '';
          window.open(
            this.autoRenewRes.Result?.TransactionRequestLink,
            '_self'
          );
        },
        (err) => {
          this.errorMsg = 'Something went wrong. Please try again later';
          this.isLoader = false;
        }
      );
    } else if (this.value == 'remindRenew') {
      this.isLoader = true;
      this.setDefaultData();
      this.apiService.remindMeToRenew(this.paymentdetails).subscribe(
        (data) => {
          this.remindMeRes = data as RemindMeResponse;
          this.cartService.setPurchaseOrder(this.remindMeRes, this.value);
          this.isLoader = false;
          this.errorMsg = '';
          this.payWithRazor();
        },
        (err) => {
          this.errorMsg = 'Something went wrong. Please try again later';
          this.isLoader = false;
        }
      );
    }
  }

  payWithRazor() {
    this.webEngageService._webengage.track(
      `ADD-ONS Marketplace Payment Screen Loaded`
    );
    const options: any = {
      key: environment.razorpayKey,
      // amount: 1,
      amount: this.remindMeRes.Result?.TotalPrice,
      currency: 'INR',
      name: '', // company name or product name
      description: '', // product description
      image: '', // company logo or product image
      order_id: this.remindMeRes.Result?.OrderId,
      modal: {
        escape: true,
      },
      notes: {
        // include notes if any
      },
      theme: {
        color: 'var(--primary-color)',
      },
    };
    options.handler = (response: any, error: any) => {
      options.response = response;

      var cartItemNames = '';
      this.cartItems = this.cartService.getCartItems();
      if (this.cartItems && this.cartItems.length) {
        for (let i = 0; i < this.cartItems.length; i++) {
          if (cartItemNames) {
            cartItemNames = cartItemNames + ',' + this.cartItems[i].name;
          } else {
            cartItemNames = this.cartItems[i].name;
          }
        }
      }

      this.webEngageService._webengage.track(
        `ADDONS_MARKETPLACE Payment Success`,
        {
          revenue: this.remindMeRes.Result?.TotalPrice,
          cartIds: cartItemNames,
          couponIds: this.couponService.getCouponName()
            ? this.couponService.getCouponName()
            : '',
          rev: this.remindMeRes.Result?.TotalPrice,
        }
      );

      this.cartService.setPaymentDetails(options.response);
      this.dialogRef.close();
      this.openModal();
      this.cartService.emptyCartItems();
      const token = this.tokenService.getToken();
      if (token) {
        this.router.navigateByUrl('/?token=' + token);
      } else {
        this.router.navigateByUrl('/addon-marketplace/marketplace');
      }
      // call your backend api to verify payment signature & capture transaction
    };
    options.modal.ondismiss = () => {
      this.webEngageService._webengage.track(
        `ADD-ONS Marketplace Payment Failed`
      );
      // handle the case when user closes the form while transaction is in progress
    };
    const rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open();
  }

  setDefaultData() {
    const paymentDetails: PaymentDetails = {
      Discount: this.cartService.couponDiscountPrice ?? 0,
      TotalPrice: this.cartService.totalAmountWithGST,
      CurrencyCode: 'INR',
      PaymentChannelProvider:
        this.value == 'remindRenew' ? 'RAZORPAY' : 'RAZORPAY_SUBSCRIPTION_LINK',
      TaxDetails: {
        TanNumber: this.cartService.getTanNumber() ?? '',
        Tax: 18,
        GSTIN: this.cartService.getGSTINNumber() ?? '',
        TDS: 0,
      },
    };
    this.paymentdetails.PaymentDetails = paymentDetails;
    this.paymentdetails.PurchaseOrderType = 'NEW';
    this.paymentdetails.FloatingPointId = this.cartService.getFpID();
    this.paymentdetails.ClientId = this.cartService.getClientID();
    this.paymentdetails.CartStateId = '';
    let purchaseOrderDetails = this.setPurchaseOrders();
    this.paymentdetails.PurchaseOrders = [purchaseOrderDetails];
  }

  // (this.value == 'remindRenew') ? 30*(this.cartService.validityMonths??1): 30

  arr: any = [];
  setPurchaseOrders() {
    this.arr = [];
    this.includedAddOns.forEach(
      (element: {
        _kid: string;
        boost_widget_key: string;
        name: string;
        description: string;
        addOnDetail: any;
        price: number;
        discount_percent: number;
        feature_price_discount_percent: number;
        extended_properties: any;
      }) => {
        // (this.value == 'remindRenew') ? 30*(this.cartService.validityMonths??1): 30
        const purchaseOrderDetailsWidgets: Widget = {
          WidgetKey: element.addOnDetail
            ? element.addOnDetail.boost_widget_key
            : element.boost_widget_key,
          Name: element.addOnDetail ? element.addOnDetail.name : element.name,
          Desc: element.addOnDetail
            ? element.addOnDetail.description
            : element.description,
          ConsumptionConstraint: {
            MetricKey: 'DAYS',
            MetricValue:
              this.value == 'remindRenew'
                ? 30 *
                  this.priceManager.getPriceWithoutMonth(
                    this.cartService.validityMonths ?? 1
                  )
                : 30,
          },
          Discount: 0,
          Price: element.addOnDetail
            ? element.addOnDetail.price ?? 0
            : element.price ?? 0,
          Images: [],
          Expiry: {
            Key: 'MONTHS',
            Value: this.priceManager.getPriceWithoutMonth(
              this.cartService.validityMonths ?? 1
            ),
          },
          Category: '',
          RecurringPaymentFrequency: 'MONTHLY',
          IsCancellable: true,
          IsRecurringPayment: true,
          Quantity: 1,
          DependentWidget: '',

          NetPrice: element.addOnDetail
            ? element.addOnDetail.price ?? 0
            : element.price ?? 0,

          Properties: element.addOnDetail
            ? element.addOnDetail.extended_properties
            : element.extended_properties,
          BaseWidgetId: element.addOnDetail
            ? element.addOnDetail._kid
            : element._kid,
        };
        this.arr.push(purchaseOrderDetailsWidgets);
      }
    );
    const purchaseOrderDetails: PurchaseOrder = {
      CouponCode: this.couponService.getCouponName() ?? '',
      NetPrice: this.priceManager.getPriceWithoutMonth(
        this.cartService.totalAmountWithOutGST ?? 0
      ),
      Discount: this.cartService.couponDiscountPrice ?? 0,
      ExtraPurchaseOrderDetails: {
        ClaimeeEmail: this.cartService.getEmail() ?? '',
      },
      Widgets: this.arr,
    };
    return purchaseOrderDetails;
  }
}
