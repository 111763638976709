import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { NfClientService, NfClient } from '@nf/core';

@Directive({
  selector: '[jio-online]',
})
export class JioOnlineDirective implements OnInit {
  constructor(private el: ElementRef, private clientService: NfClientService) {}

  ngOnInit(): void {
    if (this.clientService.currentClientConfig().client != NfClient.JioOnline)
      this.el.nativeElement.style.display = 'none';
  }
}
