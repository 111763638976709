<div class="compare-modal-wrapper">
  <div class="compare-modal-header">
    Pick a premium pack
    <span class="modal-close" (click)="dialogRef.close()">
      <img src="assets/images/white-close-icon.svg" />
    </span>
  </div>
  <div class="compare-wrapper">
    <div class="compare-modal-content">
      <div class="compare-card" *ngFor="let data of homeData.Data[0].bundles">
        <div class="flex-custom">
          <div class="compare-logo">
            <img
              onerror="this.src='assets/images/thumbnail-image.svg';"
              src="{{ data.primary_image.url }}"
            />
          </div>
          <div class="compare-header-txt">
            <h2>{{ data.name }}</h2>
            <p>{{ data.discountedPrice | currency: 'Rs' }}/month</p>
            <span class="ideal-for" *ngIf="data.desc">
              {{ data.desc | truncate: [70, '...'] }}
            </span>
          </div>
        </div>
        <div class="compare-table">
          <table>
            <tr *ngFor="let i of data.nameAddOns">
              <td>{{ i }}</td>
            </tr>
          </table>
        </div>
        <div class="btn-bx">
          <!--       [ngClass]="itemInCart == data._kid ? 'btn-remove' : 'none'" -->

          <button
            type="button"
            (mouseenter)="
              itemInCartHover('enter', _buttonCart(data), data._kid)
            "
            (mouseleave)="
              itemInCartHover('leave', _buttonCart(data), data._kid)
            "
            class="btn-yellow btn-100"
            [ngClass]="{
              'btn-remove': itemInCart == data._kid,
              'btn-border-rounded': _buttonCart(data) == 'ADDED'
            }"
            (click)="onClickButtonCart(data, _buttonCart(data))"
          >
            <span *ngIf="itemInCart == data._kid"> REMOVE</span>
            <span *ngIf="!(itemInCart == data._kid)">
              {{ _buttonCart(data) }}</span
            >
          </button>
        </div>
      </div>
    </div>
    <p class="compare-para">
      If you dont pick any of above premium plan or do not renew your existing
      premium plan, you will be moved to out Free forever base plan with limited
      features. See the features in Free Forever Base Plan.
    </p>
  </div>
</div>
