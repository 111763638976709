import { PriceManager } from './../../services/price-manager.service';
import { Feature } from './../../model/home';
import { FeatureService } from './../../services/features-service.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AddonsDetailModalComponent } from './../../modals/addons-detail-modal/addons-detail-modal.component';
import { WebengageService } from './../../services/webengage.service';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-recommendations-modal',
  templateUrl: './recommendations-modal.component.html',
  styleUrls: ['./recommendations-modal.component.scss'],
})
export class RecommendationsModalComponent implements OnInit, OnDestroy {
  public feature = new Array<Feature>();
  private getFeatureSubscription!: Subscription;
  constructor(
    public featureService: FeatureService,
    private webEngageService: WebengageService,
    public dialogRef: MatDialogRef<AddonsDetailModalComponent>,
    public dialog: MatDialog,
    public priceManager: PriceManager
  ) {}

  ngOnInit(): void {
    this.getData();
  }
  ngOnDestroy() {
    if (this.getFeatureSubscription && !this.getFeatureSubscription.closed) {
      this.getFeatureSubscription.unsubscribe();
    }
  }
  getData() {
    this.getFeatureSubscription = this.featureService.getFeatureArray.subscribe(
      (data) => {
        this.feature = data;
        this.webEngageService._webengage.track(
          'ADD-ONS Marketplace All_Features Loaded'
        );
      }
    );
  }

  openModal(): void {
    const dialogRef = this.dialog.open(AddonsDetailModalComponent, {
      panelClass: 'addons-detail-modal',
    });
  }

  onOpenModel(data: Feature) {
    this.featureService.setFeatures(data);
    this.openModal();
  }
}
