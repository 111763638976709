import { NgModule } from '@angular/core';
import { UiLibComponent } from './ui-lib.component';



@NgModule({
  declarations: [
    UiLibComponent,
  ],
  imports: [
  ],
  exports: [
    UiLibComponent
  ]
})
export class UiLibModule { }
