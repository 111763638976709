import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LocalStorageKeys } from 'projects/main-app/src/environments/environment.prod';
import { AuthToken } from './select-store.model';

@Component({
  selector: 'main-select-store',
  templateUrl: './select-store.component.html',
  styleUrls: ['./select-store.component.scss'],
})
export class SelectStoreComponent implements OnInit {
  public activeAuthToken: AuthToken[] = [];
  public deActiveAuthToken: AuthToken[] = [];
  public searchActive: string = '';
  public searchDeActive: string = '';

  public tabs: any[] = [
    { key: 'ACTIVE', label: 'ACTIVE' },
    { key: 'EXPIRED', label: 'EXPIRED' },
  ];
  public activeLink: string = this.tabs[0].key;

  constructor(public dialogRef: MatDialogRef<SelectStoreComponent>) {
    const user = JSON.parse(localStorage.getItem(LocalStorageKeys.user)!);

    if (user)
      for (let i = 0; i < user.authTokens.length; i++) {
        if (user.authTokens[i].PaymentState == 1) {
          this.activeAuthToken = [...this.activeAuthToken, user.authTokens[i]];
        } else
          this.deActiveAuthToken = [
            ...this.deActiveAuthToken,
            user.authTokens[i],
          ];
      }
  }

  ngOnInit(): void {}

  tabSelected(event: string) {
    this.activeLink = event;
  }

  closeModal() {
    this.dialogRef.close();
  }
}
