<button
  mat-stroked-button
  [color]="color"
  [class]="className"
  [ngStyle]="style ? style : {}"
  [disabled]="disabled"
  [type]="type"
>
  <ng-content></ng-content>
</button>
