import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NeedHelpModalComponent } from '../need-help-modal/need-help-modal.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login-down',
  templateUrl: './login-down.component.html',
  styleUrls: ['./login-down.component.scss'],
})
export class LoginDownComponent implements OnInit {
  public dialogSub?: Subscription;
  constructor(
    public dialogRef: MatDialogRef<LoginDownComponent>,
    public dialog: MatDialog
  ) {}

  ngOnInit() {}
  openNeedHelp() {
    const dialogRef = this.dialog.open(NeedHelpModalComponent, {
      width: '500px',
      panelClass: 'login-down',
      backdropClass: 'dark-backdrop',
      disableClose: true,
      autoFocus: false,
    });
    this.dialogSub = dialogRef.afterClosed().subscribe((result) => {});
  }
  closeModal() {
    this.dialogRef.close();
  }
}
