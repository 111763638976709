export const DICTIONARY = {
  'LOCALHOST:': {
    IsWhiteLabeled: false,
    FBAppID: '539836972828386',
    FBAppSecret: '9d1b06e831ebfa3027d59902bb428f32',
    TwitterKey: '4CiUg5gcIbvYre8XjGd8GsElV',
    TwitterSecret: '4kNsAsyM2w4F3fAEqyIK4xTcIUgoq2CtRpkH5Z7U4WW5T2ucC9',
    BoostAlias: 'Boost 360', //Boost
    WildfireEnabled: true,
    WildfireAlias: 'WildFire',
    WildfireIcon: 'wildfire-icon-image',
    ManagePortal: 'https://localhost:44352',
    TwilioKey: 'gXYHhTa0gJb6VlJxw0aEqWxHHrw5AEi5',
    TermsLink: 'https://www.nowfloats.com/tnc',
    PrivacyLink: 'https://www.nowfloats.com/privacy',
    SupportMail: 'ria@nowfloats.com',
    SupportNumber: '1860-123-1233',
    SupportName: 'Ria',
    SupportGender: 'female',
    IsAssuredPurchaseEnabled: true,
    IsCallTrackerEnabled: true,
    IsMarketplaceEnabled: true,
    BrandFavicon:
      'https://cdn.nowfloats.com/manage/assets/Images/boost/logos/favicon-png.png',
    PackagesAvailable: true,
    EnableBusinessApps: true,
    EnableANAChat: true,
    EnableDictate: true,
    LightHouseAlias: 'LightHouse',
    SiteRunner: 'nowfloats',
    FooterBrandName: 'NowFloats Technologies Limited - All rights reserved.',
    FooterOpacity: 1.0,
  },
  JIOONLINE: {
    IsWhiteLabeled: false,
    FBAppID: '539836972828386',
    FBAppSecret: '9d1b06e831ebfa3027d59902bb428f32',
    TwitterKey: 'TE20KJRCg35Sg3o8lM7OvTGRM',
    TwitterSecret: 'mvHwpYzH4y2nJFdZfIHsyyEBnaZibMmtg3P5VnKKwGgIwFAn0M',
    BoostAlias: 'JioOnline', //Boost
    WildfireEnabled: true,
    WildfireAlias: 'WildFire',
    WildfireIcon: 'wildfire-icon-image',
    ManagePortal: 'https://jioonline.nowfloats.com',
    TwilioKey: 'gXYHhTa0gJb6VlJxw0aEqWxHHrw5AEi5',
    TermsLink: 'https://www.nowfloats.com/tnc',
    PrivacyLink: 'https://www.nowfloats.com/privacy',
    SupportMail: 'businesscare@jio.com',
    SupportNumber: '1800-889-9444',
    SupportName: 'JioAssist',
    SupportGender: 'female',
    IsAssuredPurchaseEnabled: true,
    IsCallTrackerEnabled: true,
    IsMarketplaceEnabled: false,
    BrandFavicon: 'https://cdn.withfloats.com/jio/jioonline-favicon.ico',
    PackagesAvailable: true,
    EnableBusinessApps: true,
    EnableANAChat: false,
    EnableDictate: true,
    LightHouseAlias: 'LightHouse',
    SiteRunner: 'jioonline',
    FooterBrandName: 'Reliance Jio Infocomm Ltd. All rights reserved',
    FooterOpacity: 1.0,
    ClientId:
      '2D5C6BB4F46457422DA36B4977BD12E37A92EEB13BB4423A548387BA54DCEBD5',
    GoogleMyBusinessKey:
      '316471373124-0vmpnsv0jf25m2ko7ttcfqgp8h7rcm0o.apps.googleusercontent.com',
    GoogleMyBusinessSecret: '8fs1vG_oYJcrje3eTv25O0Ul',
  },
  'NOWFLOATS.COM': {
    IsWhiteLabeled: false,
    FBAppID: '539836972828386',
    FBAppSecret: '9d1b06e831ebfa3027d59902bb428f32',
    TwitterKey: '4CiUg5gcIbvYre8XjGd8GsElV',
    TwitterSecret: '4kNsAsyM2w4F3fAEqyIK4xTcIUgoq2CtRpkH5Z7U4WW5T2ucC9',
    BoostAlias: 'Boost 360',
    WildfireEnabled: true,
    WildfireAlias: 'WildFire',
    WildfireIcon: 'wildfire-icon-image',
    ManagePortal: 'https://boost.nowfloats.com',
    TwilioKey: 'gXYHhTa0gJb6VlJxw0aEqWxHHrw5AEi5',
    TermsLink: 'https://www.nowfloats.com/tnc',
    PrivacyLink: 'https://www.nowfloats.com/privacy',
    SupportMail: 'ria@nowfloats.com',
    SupportNumber: '1860-123-1233',
    SupportName: 'Ria',
    SupportGender: 'female',
    IsAssuredPurchaseEnabled: true,
    IsCallTrackerEnabled: true,
    IsMarketplaceEnabled: true,
    BrandFavicon:
      'https://cdn.nowfloats.com/manage/assets/Images/boost/logos/favicon-png.png',
    PackagesAvailable: true,
    EnableBusinessApps: true,
    EnableANAChat: true,
    EnableDictate: true,
    LightHouseAlias: 'LightHouse',
    SiteRunner: 'nowfloats',
    FooterBrandName: 'NowFloats Technologies Limited - All rights reserved.',
    FooterOpacity: 1.0,
  },
  'NOWFLOATSDEV.COM': {
    IsWhiteLabeled: false,
    FBAppID: '539836972828386',
    FBAppSecret: '9d1b06e831ebfa3027d59902bb428f32',
    TwitterKey: '5DHpMmeJARVL4RH2mXe9TgN61',
    TwitterSecret: 'KLRfyMx6zqfyWhbAQVHbbgq8221W2MW8CCLCOgohYnsUlaHjMC',
    BoostAlias: 'Boost 360',
    WildfireEnabled: true,
    WildfireAlias: 'WildFire',
    WildfireIcon: 'wildfire-icon-image',
    ManagePortal: 'https://boost.nowfloatsdev.com',
    TwilioKey: 'gXYHhTa0gJb6VlJxw0aEqWxHHrw5AEi5',
    TermsLink: 'https://www.nowfloats.com/tnc',
    PrivacyLink: 'https://www.nowfloats.com/privacy',
    SupportMail: 'ria@nowfloats.com',
    SupportNumber: '1860-123-1233',
    SupportName: 'Ria',
    SupportGender: 'female',
    IsAssuredPurchaseEnabled: true,
    IsCallTrackerEnabled: true,
    IsMarketplaceEnabled: true,
    BrandFavicon:
      'https://cdn.nowfloats.com/manage/assets/Images/boost/logos/favicon-png.png',
    PackagesAvailable: true,
    EnableBusinessApps: true,
    EnableANAChat: true,
    EnableDictate: true,
    LightHouseAlias: 'LightHouse',
    SiteRunner: 'nowfloats',
    FooterBrandName: 'NowFloats Technologies Limited - All rights reserved.',
    FooterOpacity: 1.0,
  },
  DIGITALSEOZ: {
    IsWhiteLabeled: true,
    FBAppID: '298916947224187',
    FBAppSecret: 'c54f62df029213f958362c5189c04729',
    TwitterKey: 'VSfjudvcrSvfOesMgaHzlq8eG',
    TwitterSecret: 'jM656pIF5dSjyNI09I8OrAl1hdZ20oaPbAaYtMqHAarA025Xy9',
    BoostAlias: 'Pricing Plans',
    EnableANAChat: true,
    WildfireEnabled: true,
    WildfireAlias: 'DigiAdz',
    WildfireIcon: 'DigitalSEOZ/DIGIADZ',
    ManagePortal: 'https://manage.digitalseoz.com',
    TwilioKey: '7HDSlFz99w3OGPVz8WAEeht41lb039Vv',
    TermsLink: 'http://www.digitalseoz.com/Tnc.html',
    PrivacyLink: 'http://www.digitalseoz.com/Privacy.html',
    SupportMail: 'support@digitalseoz.com',
    SupportNumber: '+91-9320690003',
    SupportName: 'Support',
    SupportGender: 'male',
    IsAssuredPurchaseEnabled: true,
    IsCallTrackerEnabled: false,
    IsMarketplaceEnabled: true,
    BrandFavicon:
      'https://cdn.withfloats.com/PartnerImages/devzmedia/digitalseoz-favicon.png',
    PackagesAvailable: true,
    EnableBusinessApps: false,
    EnableDictate: false,
    LightHouseAlias: 'SEO Magnet',
    SiteRunner: 'digitalseoz',
    FooterBrandName: 'DIGITALSEOZ - All rights reserved.',
    FooterOpacity: 1.0,
  },
  AECASIAINC: {
    IsWhiteLabeled: true,
    FBAppID: '768028443377125',
    FBAppSecret: '8f0644fb6d1f204d5b154c9f40d0ac6c',
    TwitterKey: '4CiUg5gcIbvYre8XjGd8GsElV',
    TwitterSecret: '4kNsAsyM2w4F3fAEqyIK4xTcIUgoq2CtRpkH5Z7U4WW5T2ucC9',
    BoostAlias: 'Pricing Plans',
    WildfireEnabled: false,
    WildfireAlias: 'WildFire',
    WildfireIcon: 'wildfire-icon-image',
    ManagePortal: 'http://manage.aecasiainc.com',
    TwilioKey: 'gXYHhTa0gJb6VlJxw0aEqWxHHrw5AEi5',
    TermsLink:
      'http://aecasiainc.com/more-about-us/terms-of-use/59a6b21961288a0b2cd5e254',
    PrivacyLink:
      'http://aecasiainc.com/more-about-us/privacy-policy/59a6b28b499d35080c624bc3',
    SupportMail: 'support@aecasiainc.com',
    SupportNumber: '1860-123-1233',
    SupportName: 'Support',
    SupportGender: 'male',
    IsAssuredPurchaseEnabled: true,
    IsCallTrackerEnabled: true,
    IsMarketplaceEnabled: true,
    BrandFavicon:
      'https://cdn.withfloats.com/PartnerImages/aec/AEC-favicon.ico',
    PackagesAvailable: false,
    EnableBusinessApps: false,
    EnableANAChat: true,
    EnableDictate: false,
    LightHouseAlias: 'LightHouse',
    SiteRunner: 'aecasiainc',
    FooterBrandName: 'AECASIAINC - All rights reserved.',
    FooterOpacity: 1.0,
    ClientId:
      '5B9FD26322EF3B1514D39A6CF92B9CC982E28BD79188D4AF449D80FB7861FEBA',
  },
  TRIVONE: {
    IsWhiteLabeled: true,
    IsAssuredPurchaseEnabled: true,
    IsCallTrackerEnabled: false,
    IsMarketplaceEnabled: true,
    PackagesAvailable: false,
    EnableBusinessApps: false,
    EnableANAChat: true,
    EnableDictate: false,
    FBAppID: '539836972828386',
    FBAppSecret: '9d1b06e831ebfa3027d59902bb428f32',
    TwitterKey: '4CiUg5gcIbvYre8XjGd8GsElV',
    TwitterSecret: '4kNsAsyM2w4F3fAEqyIK4xTcIUgoq2CtRpkH5Z7U4WW5T2ucC9',
    BoostAlias: 'Pricing Plans',
    WildfireEnabled: false,
    WildfireAlias: 'WildFire',
    WildfireIcon: 'wildfire-icon-image',
    ManagePortal: 'http://manage.trivone.com',
    TwilioKey: 'gXYHhTa0gJb6VlJxw0aEqWxHHrw5AEi5',
    TermsLink: 'https://www.nowfloats.com/tnc',
    PrivacyLink: 'https://www.nowfloats.com/privacy',
    SupportMail: 'contact@trivone.com',
    SupportNumber: '+91-1244934342',
    SupportName: 'Support',
    SupportGender: 'male',
    BrandFavicon:
      'https://cdn.withfloats.com/partner/trivone/trivone-favicon.png',
    LightHouseAlias: 'LightHouse',
    SiteRunner: 'trivone',
    FooterBrandName: 'Trivone - All rights reserved.',
    FooterOpacity: 1.0,
  },
  SUKERE: {
    IsWhiteLabeled: true,
    IsAssuredPurchaseEnabled: true,
    IsCallTrackerEnabled: false,
    IsMarketplaceEnabled: true,
    PackagesAvailable: false,
    WildfireEnabled: false,
    EnableBusinessApps: false,
    EnableDictate: false,
    EnableANAChat: true,
    FBAppID: '949149385111216128',
    FBAppSecret: 'er7oOIMdUqxK7pBmg8SFwS8NZuI2RVXt3bgZj77cv7IGqKSGtQ',
    TwitterKey: 'Xx4v6Ak52csfHNOn41BAkJmww',
    TwitterSecret: 'km55qJLj1YmiQdHhAZgwbas5XCYcgdU9syQ2WoL0HcZqXf9Qe7',
    WildfireAlias: 'WildFire',
    WildfireIcon: 'wildfire-icon-image',
    ManagePortal: 'http://boost.sukere.com',
    TwilioKey: 'gXYHhTa0gJb6VlJxw0aEqWxHHrw5AEi5',
    TermsLink: 'http://sukere.com/terms-and-conditions',
    PrivacyLink: 'http://sukere.com/terms-and-conditions',
    SupportMail: 'support@sukere.com',
    SupportNumber: '9962744000',
    SupportName: 'Support',
    SupportGender: 'male',
    BrandFavicon:
      'https://cdn.withfloats.com/PartnerImages/sukere/sukere-favicon.jpg',
    LightHouseAlias: 'LightHouse',
    SiteRunner: 'sukere',
    BoostAlias: 'Pricing Plans',
    FooterBrandName: 'Sukere - All rights reserved.',
    FooterOpacity: 1.0,
  },
  'NOWFLOATS.US': {
    IsWhiteLabeled: true,
    IsAssuredPurchaseEnabled: false,
    IsCallTrackerEnabled: false,
    IsMarketplaceEnabled: false,
    PackagesAvailable: true,
    WildfireEnabled: false,
    EnableBusinessApps: false,
    EnableDictate: false,
    EnableANAChat: false,
    FBAppID: '539836972828386',
    FBAppSecret: '9d1b06e831ebfa3027d59902bb428f32',
    TwitterKey: 'Xx4v6Ak52csfHNOn41BAkJmww',
    TwitterSecret: 'km55qJLj1YmiQdHhAZgwbas5XCYcgdU9syQ2WoL0HcZqXf9Qe7',
    ManagePortal: 'http://boost.nowfloats.us',
    TwilioKey: 'gXYHhTa0gJb6VlJxw0aEqWxHHrw5AEi5',
    TermsLink: 'https://www.nowfloats.us/tnc',
    PrivacyLink: 'https://www.nowfloats.us/privacy',
    SupportMail: 'us@nowfloats.com',
    SupportNumber: '18448112065',
    SupportName: 'Tim',
    SupportGender: 'male',
    BrandFavicon: 'http://nowfloats.com/favicon.png',
    LightHouseAlias: 'LightHouse',
    SiteRunner: 'nowfloats',
    SiteProductLink: 'https://www.nowfloats.us/product',
    SiteFAQ: 'https://www.nowfloats.us/faqs',
    AcceptedCurrencies: ['USD'],
    BoostAlias: 'Boost',
    FooterBrandName: 'Nowfloats Technologies',
    FooterOpacity: 1.0,
  },
  REDTIM: {
    IsWhiteLabeled: true,
    IsAssuredPurchaseEnabled: false,
    IsCallTrackerEnabled: false,
    IsMarketplaceEnabled: false,
    PackagesAvailable: true,
    WildfireEnabled: false,
    EnableBusinessApps: false,
    EnableDictate: false,
    EnableANAChat: false,
    TwitterKey: 'qhecWhz0QK6PcGcsrCL03K8V2',
    TwitterSecret: 'MMD73UQ3vvptgFYOtHSl7iZxnwxXguzffTgMSqLzYDElKXY5Ky',
    ManagePortal: 'https://manage.redtim.com',
    TwilioKey: 'gXYHhTa0gJb6VlJxw0aEqWxHHrw5AEi5',
    TermsLink: 'https://redtim.com/tnc',
    PrivacyLink: 'https://redtim.com/privacy',
    SupportName: 'Tim',
    SupportGender: 'male',
    SupportMail: 'tim@redtim.com',
    SupportNumber: '18448112065',
    BrandFavicon: 'http://redtim.com/Assets/images-and-icons/favicon.png',
    LightHouseAlias: 'RedTim',
    SiteRunner: 'nowfloats',
    SiteProductLink: 'https://redtim.com/product',
    SiteFAQ: 'https://redtim.com/faqs',
    AcceptedCurrencies: ['USD'],
    BoostAlias: 'RedTim',
    EnableFacebookIntegration: true,
    EnableTwitterIntegration: true,
    EnableQuikrIntegration: false,
    FooterBrandName: 'Nowfloats Technologies',
    FooterOpacity: 0,
    TipsToUpdateLink: 'https://redtim.com/write-effective-content',
    SeoLink: 'https://redtim.com/faqs#seo-results',
    DefaultIcon: 'https://redtim.com/Assets/images-and-icons/RedTim-Logo.svg',
  },
};
