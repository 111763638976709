<div id="mlc-imgration-modal" class="modal fade primary-font">
  <div class="mlc-modal-close" (click)="closeModal()">
    <img src="assets/images/Cancel.png" />
  </div>
  <div class="mlc-migration-bizhq">
    <div class="mlc-img-h3">
      <div class="mlc-img-container">
        <img src="assets/images/boost_mlc_bizhq.png" class="mlc-bizhq-img" />
      </div>
      <h3>Congratulations, you’ve been upgraded!</h3>
    </div>
    <p>
      Login to
      <a href="https://bizhq.nowfloats.com/" target="_blank"
        >bizhq.nowfloats.com</a
      >
      with your old credentials & manage your existing website & all its content
      in an enhanced dashboard.
    </p>
    <p>
      Kindly note that the old dashboard will not longer be available. Watch the
      video for more information.
    </p>
    <p>
      For support, please contact <a href="tel:18601231233">18601231233</a> or
      email us at <a href="mailto:">ria@bizhq.in</a>
    </p>
    <div class="migration-button-container">
      <a
        href="https://bizhq.nowfloats.com/"
        target="_blank"
        class="mlc-primary-btn"
      >
        <img src="assets/images/SignIn.svg" />
        <span>Login</span>
      </a>
      <a
        href="https://www.youtube.com/watch?v=92WNt9lnbdU"
        target="_blank"
        class="mlc-secondary-btn"
      >
        <img src="assets/images/Tutorial_videos.svg" />
        <span>Watch video</span>
      </a>
    </div>
  </div>
</div>
