<div class="web-container">
  <div class="container">
    <div class="section-top-header">
      <div class="header-row">
        <div class="btn-container">
          <h1 class="section-button">My add-ons</h1>
        </div>
        <div class="btn-container-2">
          <div class="section-button-wrapper">
            <img (click)="goBack()" src="assets/images/cross-page-icon.svg" />
          </div>
        </div>
      </div>
    </div>

    <div class="addons-wrapper">
      <div class="addons-headeer">
        <div class="addon-details">
          <div class="addons-tab-wrapper">
            <div
              class="renewal-text"
              *ngIf="lastRenewal"
              (click)="openModalOrderDetail()"
            >
              Last renewal:
              {{
                _date_field(lastRenewal.ToBeActivatedOn)
                  | date: 'MMM d, y h:mm a'
              }}

              <span>View</span>
            </div>

            <mat-tab-group [color]="'#555'">
              <mat-tab label="ALL">
                <div class="tab-card-bx" *ngIf="homeData && homeData.Data">
                  <div class="tab-card" *ngFor="let data of addOnArray">
                    <i class="star-icon" *ngIf="data.isPremium == true">
                      <img src="assets/images/star-icon.svg" />
                    </i>
                    <img src="{{ data.primary_image?.url }}" />
                    <p>{{ data.name | truncate: [28, '...'] }}</p>
                    <span class="price-text" *ngIf="data.is_premium"
                      >Activated</span
                    >
                    <span class="price-text" *ngIf="!data.is_premium"
                      >Free</span
                    >
                  </div>
                </div>
              </mat-tab>
              <mat-tab label="FREE">
                <div class="tab-card-bx">
                  <div class="wrap" *ngFor="let data of addOnArray">
                    <div
                      class="tab-card"
                      (click)="onOpenModel(data)"
                      *ngIf="!data.is_premium"
                    >
                      <img src="{{ data.primary_image?.url }}" />
                      <p>{{ data.name | truncate: [28, '...'] }}</p>
                      <span class="price-text">Free</span>
                    </div>
                  </div>
                </div>
              </mat-tab>
              <mat-tab label="PREMIUM">
                <div class="tab-card-bx">
                  <div class="" *ngFor="let data of addOnArray">
                    <div class="tab-card" *ngIf="data.is_premium">
                      <i class="star-icon">
                        <img src="assets/images/star-icon.svg" />
                      </i>
                      <img src="{{ data.primary_image?.url }}" />
                      <p>{{ data.name | truncate: [28, '...'] }}</p>
                      <span class="price-text">Activated</span>
                    </div>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
