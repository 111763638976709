import { NF_ADDON_MARKETPLACE_MENU_CONFIG } from './addon-marketplace-menu.config';
import { NF_DASHBOARD_MENU_CONFIG } from './dashboard-menu.config';
import { NF_MANAGE_CONTENT_MENU_CONFIG } from './manage-content-menu.config';
import { NF_MANAGE_REPORT_MENU_CONFIG } from './manage-report-menu.config';
import { NF_MANAGE_USER_MENU_CONFIG } from './manage-user-menu.config';
import { NF_SETTING_MENU_CONFIG } from './setting-menu.config';
import { NfMenuConfig } from '../menu.type';

export const NF_MENU_CONFIG: NfMenuConfig[] = [
  ...NF_DASHBOARD_MENU_CONFIG,
  ...NF_MANAGE_USER_MENU_CONFIG,
  ...NF_MANAGE_CONTENT_MENU_CONFIG,
  ...NF_MANAGE_REPORT_MENU_CONFIG,
  ...NF_ADDON_MARKETPLACE_MENU_CONFIG,
  ...NF_SETTING_MENU_CONFIG,
];
