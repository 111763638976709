import { Component, OnDestroy, OnInit } from '@angular/core';
import { AddonsDetailModalComponent } from './../../modals/addons-detail-modal/addons-detail-modal.component';
import { OrderHistoryModalComponent } from './../../modals/order-history-modal/order-history-modal.component';
import { RenewalHistory } from './../../model/renewalHistory';
import { CommonService } from './../../services/common.service';
import { FeatureService } from './../../services/features-service.service';
import { Location } from '@angular/common';
import { ConsumerService } from './../../services/consumer.service';
import { Subscription } from 'rxjs';
import { HomeService } from './../../services/home.service';
import { WebengageService } from './../../services/webengage.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgDialogAnimationService } from 'ng-dialog-animation';
@Component({
  selector: 'app-addons',
  templateUrl: './addons.component.html',
  styleUrls: ['./addons.component.scss'],
})
export class AddonsComponent implements OnInit, OnDestroy {
  renewHistoryData = new RenewalHistory();
  homeData: any;
  lastRenewal: any;
  closest: any;
  date: any;
  consumerDataSubscription!: Subscription;
  consumerData: any;
  filterFeaturedArray: any = [];
  filterBundlesArray: any = [];
  filterBannersArray: any = [];
  premiumPlans: any;
  homeDataSubscription!: Subscription;
  addOnArray: any = [];

  constructor(
    public dialogRef: MatDialogRef<AddonsComponent>,
    private service: CommonService,
    private featureService: FeatureService,
    private _location: Location,
    public consumerService: ConsumerService,
    private homeService: HomeService,
    private webEngageService: WebengageService,
    public dialog: NgDialogAnimationService
  ) {}

  ngOnInit(): void {
    this.webEngageService._webengage.track(
      'ADD-ONS Marketplace My_Addons Loaded'
    );
    this.getApi();
    this.checkHomePageData();
    // this.getPremiumPlans();
  }

  goBack() {
    this._location.back();
  }

  checkHomePageData() {
    this.homeDataSubscription = this.homeService.getHomeData.subscribe(
      (data) => {
        if (data) {
          this.homeData = data;
          this.filterMyAddons();
        }
      }
    );
  }

  filterMyAddons() {
    if (
      this.homeData &&
      this.homeData.Data[0] &&
      this.homeData.Data[0].myAddOns
    ) {
      this.addOnArray = this.homeData.Data[0].myAddOns;
    }
  }

  getApi() {
    this.service.getMyAdOns().subscribe((data) => {
      this.renewHistoryData = data as RenewalHistory;
      this.featureService.setRenewHistory(data);
      this.lastRenewalFilter();
    });
  }

  lastRenewalFilter() {
    if (this.renewHistoryData) {
      if (this.renewHistoryData.Result && this.renewHistoryData.Result.length) {
        if (this.renewHistoryData.Result.length) {
          for (var i = 0; i < this.renewHistoryData.Result.length; i++) {
            this.date = this._date_field(
              this.renewHistoryData.Result[i].ToBeActivatedOn
            );
            if (!this.closest) {
              this.closest = this.date;
              this.lastRenewal = this.renewHistoryData.Result[i];
            } else {
              if (this.closest < this.date) {
                this.closest = this.date;
                this.lastRenewal = this.renewHistoryData.Result[i];
              }
            }
          }
        }
      }
      if (this.lastRenewal) {
        this.setRenewHistory();
      }
    }
  }

  ngOnDestroy() {
    if (
      this.consumerDataSubscription &&
      !this.consumerDataSubscription.closed
    ) {
      this.consumerDataSubscription.unsubscribe();
    }
    if (this.homeDataSubscription && !this.homeDataSubscription.closed) {
      this.homeDataSubscription.unsubscribe();
    }
  }

  onOpenModel(data: any) {
    this.featureService.setFeatures(data);
    this.openModal();
  }

  openModal(): void {
    const dialogRef = this.dialog.open(AddonsDetailModalComponent, {
      panelClass: 'addons-detail-modal',
    });

    // const dialogRef = this.dialog.open(AddonsDetailModalComponent, {
    //   width: '428px',
    //   animation: {
    //     to: 'left',
    //   },
    //   position: { right: '0', top: '0', bottom: '0' },
    //   panelClass: 'slide-modal',
    // });
  }
  openModalOrderDetail(): void {
    const dialogRef = this.dialog.open(OrderHistoryModalComponent, {
      panelClass: ['renewal-history-modal', 'order-history-modal'],
    });

    // const dialogRef = this.dialog.open(OrderHistoryModalComponent, {
    //   width: '428px',
    //   animation: {
    //     to: 'left',
    //   },
    //   position: { right: '0', top: '0', bottom: '0' },
    //   panelClass: ['slide-modal', 'order-history-modal'],
    // });
  }

  _date_field(date: any) {
    if (date) {
      let x = date.split('(');
      let y = x[1].split(')');
      let z = new Date(+y[0]);
      return z;
    } else {
      return null;
    }
  }

  setRenewHistory() {
    this.featureService.setRenewHistory(this.lastRenewal);
  }
}
