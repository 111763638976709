import { NfAppExpCode, NfApplication } from '../menu.enum';
import { NfMenuConfig } from '../menu.type';

const menuName = 'DASHBOARD';
const titleName = 'Business Dashboard';
export const MLC_DASHBOARD_MENU_CONFIG: NfMenuConfig[] = [
  {
    application: NfApplication.Dashboard,
    appExpCode: NfAppExpCode.MFG,
    menu: {
      name: menuName,
      title: titleName,
      url: '/enterprise/dashboard'
    },
  },
];
