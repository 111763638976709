import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  NfApiParams,
  NfApiService,
  NfApiHeaders,
  NfApiResponseType,
  CanAutoUnsubscribe,
} from '@nf/common';
import { NfClientService } from '@nf/core';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageKeys } from 'projects/main-app/src/environments/environment';
import { Subscription } from 'rxjs';
import { MlcService } from '../../services/mlc.service';
import { WebsiteUpdatedModalComponent } from '../website-updated-modal/website-updated-modal.component';

@Component({
  selector: 'main-republish-website-modal',
  templateUrl: './republish-website-modal.component.html',
  styleUrls: ['./republish-website-modal.component.scss'],
})
@CanAutoUnsubscribe
export class RepublishWebsiteModalComponent implements OnInit {
  private fpTag: string = '';

  //Subscription variables
  public publishSub?: Subscription;

  constructor(
    private toastr: ToastrService,
    private clientService: NfClientService,
    public dialog: MatDialog,
    private apiService: NfApiService,
    public dialogRef: MatDialogRef<RepublishWebsiteModalComponent>,
    private mlcService: MlcService
  ) {
    const authToken = localStorage.getItem(LocalStorageKeys.auth_token);

    if (this.mlcService.isMLCStore && this.mlcService.fpTag) {
      this.fpTag = this.mlcService.fpTag;
    } else {
      if (authToken && authToken.length) {
        const parsedData = JSON.parse(authToken);
        this.fpTag = parsedData.FloatingPointTag;
      }
    }

    this.publishWebsite();
  }

  ngOnInit(): void {}

  publishWebsite() {
    this.publishSub = this.apiService
      .get({
        responseType: NfApiResponseType.TEXT,
        apiPath: '/kitsune/v2/InvalidateFLMCache',
        params: new NfApiParams()
          .append('clientId', this.clientService.defaultClientConfig().clientId)
          .append('key', this.fpTag),
      })
      .subscribe(
        (res) => {
          setTimeout(() => {
            this.closeModal();
            this.dialog.open(WebsiteUpdatedModalComponent, {
              backdropClass: 'popupBackdropClass',
              panelClass: 'website-updated',
              disableClose: true,
            });
          }, 1500);
        },
        (err) => {
          this.closeModal();
          this.toastr.error(
            'There is some issue while publishing the update on the website',
            'Error',
            {
              positionClass: 'toast-bottom-right',
            }
          );
        }
      );
  }

  closeModal() {
    this.dialogRef.close();
  }
}
