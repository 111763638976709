import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AnimationOptions } from 'ngx-lottie';
import { Subscription } from 'rxjs';
import { Clients } from './../../constants/client.constant';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ConsumerService } from '../../services/consumer.service';
@Component({
  selector: 'app-website-modal',
  templateUrl: './website-modal.component.html',
  styleUrls: ['./website-modal.component.scss'],
  providers: [],
})
export class WebsiteModalComponent implements OnInit, OnDestroy {
  baseUrl: any;
  consumerData: any;
  isLoader = true;
  consumerDataSubscription!: Subscription;
  constructor(
    public dialogRef: MatDialogRef<WebsiteModalComponent>,
    public dialog: MatDialog,
    private domSanitizer: DomSanitizer,
    private consumerService: ConsumerService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // this.consumerService.setClientDetails(this.getClientData);
    this.consumerService.getClientDetails.subscribe((consumer: any) => {
      if (
        consumer &&
        consumer.Name &&
        consumer.Name.toLowerCase() == Clients.jioOnline
      ) {
        this.options = {
          path: 'assets/lottie/jio-lottie-loader.json',
        };
      } else {
        this.options = {
          path: 'assets/lottie/boost-lottie-loader.json',
        };
      }
    });
  }

  options: AnimationOptions = {
    path: '../assets/lottie/boost-lottie-loader.json',
  };

  ngOnInit() {
    if (this.data && this.data.baseUrl) {
      this.baseUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
        this.data.baseUrl
      );
    }
  }

  ngOnDestroy() {
    if (
      this.consumerDataSubscription &&
      !this.consumerDataSubscription.closed
    ) {
      this.consumerDataSubscription.unsubscribe();
    }
  }
  myLoadEvent() {
    this.isLoader = false;
  }
}
