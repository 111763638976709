<div class="addons-detail-modal-wrapper">
  <div class="addons-detail-header">
    <i class="modal-backicon" (click)="onBack(isPackage)">
      <img
        *ngIf="isBack || isPackage"
        src="assets/images/modal-back-icon.svg"
      />
    </i>
    <span *ngIf="feature && !feature.included_features"> Add-on details </span>
    <span
      *ngIf="
        feature && feature.included_features && feature.included_features.length
      "
    >
      Pack Details
    </span>

    <span class="modal-close" (click)="dialogRef.close()">
      <img src="assets/images/modal-close-icon.svg" />
    </span>
  </div>

  <div class="addons-detail-content" *ngIf="feature">
    <div class="flex-custom">
      <div class="addon-logo">
        <img
          *ngIf="feature.primary_image"
          onerror="this.src='assets/images/thumbnail-image.svg';"
          src="{{ feature.primary_image?.url }}"
        />
      </div>
      <div
        class="addon-header-txt"
        *ngIf="
          feature &&
          feature.included_features &&
          feature.included_features.length
        "
      >
        <h2 *ngIf="feature.name">
          {{ feature.name }}
        </h2>

        <div *ngIf="!isPackage">
          <p *ngIf="feature.discountedPrice">
            <!-- {{ feature.discountedPrice | currency: '₹' }}/month -->
            {{ priceManager.getPrice(feature.discountedPrice) }}
          </p>
          <p *ngIf="!feature.discountedPrice">Free Forever</p>

          <button
            type="button"
            (mouseenter)="
              itemInCartHover('enter', _buttonCart(feature), feature._kid)
            "
            (mouseleave)="
              itemInCartHover('leave', _buttonCart(feature), feature._kid)
            "
            class="btn-yellow"
            [ngClass]="{
              'btn-border-rounded': _buttonCart(feature) == 'ADDED',
              'btn-remove': itemInCart == feature._kid
            }"
            *ngIf="feature.discountedPrice"
            (click)="onClickButtonCart(feature, _buttonCart(feature))"
          >
            <span *ngIf="itemInCart == feature._kid"> REMOVE</span>
            <span *ngIf="!(itemInCart == feature._kid)">
              {{ _buttonCart(feature) }}</span
            >
          </button>
        </div>
      </div>

      <div class="addon-header-txt" *ngIf="!feature.included_features">
        <h2 *ngIf="feature.name">
          {{ feature.name }}
        </h2>
        <div *ngIf="!isPackage">
          <p
            *ngIf="
              feature.is_premium && feature.price && !feature.discountedPrice
            "
          >
            <!-- {{ feature.price | currency: '₹' }}/month -->
            {{ priceManager.getPrice(feature.price) }}
          </p>
          <p
            *ngIf="
              feature.is_premium && feature.discountedPrice && feature.price
            "
          >
            <!-- {{ feature.discountedPrice | currency: '₹' }}/month -->
            {{ priceManager.getPrice(feature.discountedPrice) }}
          </p>
          <p
            *ngIf="
              feature.is_premium && !feature.discountedPrice && !feature.price
            "
          >
            Free
          </p>

          <button
            type="button"
            (mouseenter)="
              itemInCartHover('enter', _buttonCart(feature), feature._kid)
            "
            (mouseleave)="
              itemInCartHover('leave', _buttonCart(feature), feature._kid)
            "
            class="btn-yellow"
            [ngClass]="{
              'btn-border-rounded': _buttonCart(feature) == 'ADDED',
              'btn-remove': itemInCart == feature._kid
            }"
            *ngIf="feature.is_premium"
            (click)="onClickButtonCart(feature, _buttonCart(feature))"
          >
            <span *ngIf="itemInCart == feature._kid"> REMOVE</span>
            <span *ngIf="!(itemInCart == feature._kid)">
              {{ _buttonCart(feature) }}</span
            >
          </button>

          <p *ngIf="feature && !feature.is_premium">Free Forever</p>
        </div>
      </div>
    </div>
    <div class="addon-para">
      <h3 *ngIf="feature.description_title">
        {{ feature.description_title }}
      </h3>
      <p *ngIf="feature.description">
        {{ feature.description }}
      </p>
      <p *ngIf="feature.desc">
        {{ feature.desc }}
      </p>

      <!-- If Package Addons -->
      <div
        class="recommended-modal-content"
        *ngIf="feature && feature.featuresDetails"
      >
        <h3 class="included-feature-heading">Included Features</h3>
        <div *ngFor="let data of feature.featuresDetails">
          <div
            class="recommended-card"
            (click)="onOpenModel(data); dialogRef.close()"
          >
            <img
              onerror="this.src='assets/images/thumbnail-image.svg';"
              src="{{ data.primary_image?.url }}"
            />
            <p>{{ data.name | truncate: [28, '...'] }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
