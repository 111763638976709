import { SharedModule } from './../../../addon-marketplace-app/src/app/shared/shared/shared.module';
import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NfCoreLibModule } from '@nf/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { CommonLibModule, NfApiModule } from '@nf/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { RepublishWebsiteModalComponent } from './modal/republish-website-modal/republish-website-modal.component';
import { WebsiteUpdatedModalComponent } from './modal/website-updated-modal/website-updated-modal.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DownloadBoostAppComponent } from './modal/download-boost-app/download-boost-app.component';
import { ErrorHandlerInterceptor } from '@nf/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { JioHeaderComponent } from './jio-online/jio-header/jio-header.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MlcHeaderComponent } from './mlc-header/mlc-header.component';
import { SelectStoreComponent } from './modal/select-store/select-store.component';
import { NfLoaderModule, NfTabsModule } from '@nf/ui';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { AllStoreComponent } from './modal/all-store/all-store.component';
@NgModule({
  declarations: [
    AppComponent,
    // RepublishWebsiteModalComponent,
    WebsiteUpdatedModalComponent,
    DownloadBoostAppComponent,
    JioHeaderComponent,
    MlcHeaderComponent,
    SelectStoreComponent,
    SearchFilterPipe,
    AllStoreComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NfCoreLibModule.forRoot(environment.coreConfig),
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatMenuModule,
    MatInputModule,
    SharedModule,
    MatBadgeModule,
    HttpClientModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    NfApiModule.forRoot(environment.apiConfig),
    MatTooltipModule,
    ClipboardModule,
    MatSidenavModule,
    CommonLibModule,
    MatProgressBarModule,
    NfTabsModule,
    FormsModule,
    NfLoaderModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    Title,
    CurrencyPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
