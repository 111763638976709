import {
  NfApiParams,
  NfApiService,
  NfApiOptions,
  NfApiConfig,
  NfApiHeaders,
  NfApiResponseType,
} from '@nf/common';
import { LocalStorageKeys } from 'projects/main-app/src/environments/environment';

export class HttpRequest implements NfApiOptions {
  constructor(url: string, baseurl?: string, params?: NfApiParams) {
    this.apiPath = url;
    if (baseurl) {
      this.config = {
        baseUrl: baseurl,
      };
    }
    //this.headers = new NfApiHeaders().append('Authorization', `Bearer ${this.accessToken}`)
    this.params = params;
  }

  apiPath: string;
  params?: NfApiParams | undefined;
  headers?: NfApiHeaders | undefined;
  responseType?: NfApiResponseType | undefined;
  withCredentials?: boolean | undefined;
  config?: NfApiConfig | undefined;
  accessToken = localStorage.getItem(LocalStorageKeys.token)!;
}
