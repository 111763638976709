import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';
import { CartServiceService } from './../../services/cart-service.service';
import { ConsumerService } from './../../services/consumer.service';
import { HomeService } from './../../services/home.service';
import { WebengageService } from './../../services/webengage.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-compare-modal',
  templateUrl: './compare-modal.component.html',
  styleUrls: ['./compare-modal.component.scss'],
  providers: [MatSnackBar],
})
export class CompareModalComponent implements OnInit, OnDestroy {
  homeDatas: any;
  homeData: any;
  filteredList: any;
  homeDataSubscription!: Subscription;
  consumerDataSubscription!: Subscription;
  buttonName = 'ADD TO CART';
  itemInCart = '';
  bgColor: any;
  constructor(
    public dialogRef: MatDialogRef<CompareModalComponent>,
    public dialog: MatDialog,
    private cartService: CartServiceService,
    private homeService: HomeService,
    private consumerService: ConsumerService,
    private webEngageService: WebengageService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.getHomeData();
    this.getConsumerData();
  }

  ngOnDestroy() {
    if (this.homeDataSubscription && !this.homeDataSubscription.closed) {
      this.homeDataSubscription.unsubscribe();
    }
    if (
      this.consumerDataSubscription &&
      !this.consumerDataSubscription.closed
    ) {
      this.consumerDataSubscription.unsubscribe();
    }
  }

  getHomeData() {
    this.homeDataSubscription = this.homeService.getHomeData.subscribe(
      (data) => {
        if (data) {
          this.homeData = data;
          this.webEngageService._webengage.track(
            'ADD-ONS Marketplace My_Addons Loaded'
          );
        }
      }
    );
    this.homeData.Data[0].bundles = this.cartService.calculatingBundlesPrice(
      this.homeData.Data[0].filteredBundlesByCategory,
      this.homeData.Data[0].features
    );
  }

  getConsumerData() {
    this.consumerDataSubscription =
      this.consumerService.getClientDetails.subscribe((data) => {
        if (data) {
          this.bgColor = data.ManageConfiguration.ClientBrandingColorCode;
        }
      });
  }

  // On Hover state will be changed to remove (button name or color)
  itemInCartHover(state: string, inCartState: any, id: string) {
    if (state == 'enter') {
      if (inCartState == 'ADDED') {
        this.itemInCart = id;
        this.buttonName = 'REMOVE';
      }
    } else {
      if (this.buttonName == 'REMOVE') {
        this.itemInCart = '';
        this.buttonName = 'ADDED';
      }
    }
  }

  // check item is present in cart or not && set the value of the button
  _buttonCart(value: any) {
    if (value) {
      const itemData = this.cartService.checkItemIsPresentInCart(value._kid);
      if (itemData && itemData.length > 0) {
        return 'ADDED';
      } else {
        return 'ADD TO CART';
      }
    } else {
      return 'ADD TO CART';
    }
  }

  // On click on button ( add to cart or remove from cart)
  onClickButtonCart(value: any, type: string) {
    if (type == 'ADD TO CART') {
      this.cartService.setCartItems(value);
      this.openSnackBar(value.name + ' is added to cart');
    } else {
      this.cartService.deleteCartItems(value);
      this.openSnackBar(value.name + ' is removed from cart');
    }
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      duration: 2000, // in seconds
      panelClass: ['snackbar-color'],
    });
  }
}
