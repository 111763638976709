<div class="main-container">
  <div class="header">
    <h1>Scan the code & download our app</h1>
  </div>
  <div class="qr-code">
    <img src="../../../assets/img/QR-code/apps-qrcode.png" alt="QR-Code" />
  </div>
  <div class="app-download flex justify-center items-center gap-4 w-full">
    <div class="android">
      <a class="flex items-center gap-1" [href]="androidUrl" target="_blank">
        <img
          class="w-4 h-4"
          style="margin-top: 3px"
          src="../../../assets/images/androidFooter.svg"
          alt="android-icon"
        />
        <span class="app-text"> Android App </span>
      </a>
    </div>
    <div class="ios">
      <a class="flex items-center gap-1" [href]="iosUrl" target="_blank">
        <img
          class="w-3 h-3"
          src="../../../assets/images/appleFooter.svg"
          alt="apple-icon"
        />

        <span class="app-text"> IOS App </span>
      </a>
    </div>
  </div>
</div>
