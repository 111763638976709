<div class="addons-detail-modal-wrapper">
  <div class="addons-detail-header">
    renewal history
    <span class="modal-close" (click)="dialogRef.close()">
      <img src="assets/images/modal-close-icon.svg" />
    </span>
  </div>

  <div class="loader" *ngIf="showLoader">Loading..</div>

  <div
    class="recommended-wrapper"
    *ngIf="
      renewHistoryData.Result && renewHistoryData.Result.length && !showLoader
    "
  >
    <div class="renewal-modal-content">
      <section *ngFor="let history of renewHistoryData.Result">
        <div class="add-cart-wrapper" (click)="onOpenModel(history)">
          <div class="add-cart-header flex-space-between">
            <h4 *ngIf="history.orderId">ORDER ID: #{{ history.orderId }}</h4>
            <h6>Rs. {{ history.paidAmount }}</h6>
          </div>
          <div class="pad-wrapper">
            <div class="add-cart-content">
              <i
                class="add-cart-icon"
                *ngIf="history.purchasedPackageDetails?.PrimaryImageUri"
              >
                <img
                  onerror="this.src='assets/images/thumbnail-image.svg';"
                  src="{{ history.purchasedPackageDetails?.PrimaryImageUri }}"
                />
              </i>
              <div class="add-text-wrapper">
                <i class="cart-close">
                  <!-- <img src="assets/images/yellow-back-icon.svg" /> -->
                  <svg
                    width="11"
                    height="20"
                    viewBox="0 0 11 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.00013 17.8452L9.28516 9.86277L2.00013 1.5002"
                      stroke="var(--primary-color)"
                      stroke-width="2.69286"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </i>
                <h4>{{ history.purchasedPackageDetails?.Name }}</h4>
                <div class="addon-price">
                  <p>
                    <span>
                      ₹{{ history.paidAmount }}/month
                      <!-- {{ priceManager.getPrice(history.paidAmount) }} -->
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="add-cart-date">
            {{ _date_field(history.ToBeActivatedOn) | date: 'MMM d, y h:mm a' }}
          </div>
        </div>
      </section>
    </div>
  </div>

  <div
    class="order-empty"
    *ngIf="
      !renewHistoryData.Result ||
      (!renewHistoryData.Result.length && !showLoader)
    "
  >
    <div class="order-empty-content">
      <h4>And up that goes</h4>
      <p>There's nothing in your order history.</p>
      <img src="assets/images/order-empty-icon.svg" />
      <button
        type="button"
        (click)="dialogRef.close()"
        routerLink="/"
        class="btn-yellow"
      >
        go back to marketplace
      </button>
    </div>
  </div>
</div>
