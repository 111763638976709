<div class="addons-detail-modal-wrapper">
  <div class="addons-detail-header">
    <span>
      Billing Details
      <span class="gst-label"> for GST Invoice </span>
    </span>
    <span class="modal-close" (click)="onCloseModal()">
      <img src="assets/images/modal-close-icon.svg" />
    </span>
  </div>
  <div class="recommended-wrapper">
    <div class="renew-subscription-content">
      <form #businessForm="ngForm" (ngSubmit)="onProceed(businessForm)">
        <div class="field">
          <div class="label">
            <span> Business registered to GST ? </span>
            <span>
              <div class="checker col-xs-1 pl-4 pr-4">
                <div
                  class="
                    bootstrap-switch
                    bootstrap-switch-wrapper
                    bootstrap-switch-animate
                    bootstrap-switch-id-store-timing-checkbox-Sunday
                  "
                  [ngClass]="
                    isGst ? 'bootstrap-switch-focused' : 'bootstrap-switch-off'
                  "
                >
                  <div
                    class="bootstrap-switch-container"
                    (click)="isGst = !isGst"
                    [ngClass]="isGst ? 'yes-checkbox' : 'no-checkbox'"
                  >
                    <span
                      class="
                        bootstrap-switch-handle-on bootstrap-switch-primary
                      "
                      >Yes</span
                    ><label class="bootstrap-switch-label">&nbsp;</label>
                    <span
                      class="
                        bootstrap-switch-handle-off bootstrap-switch-default
                      "
                      >No</span
                    >
                    <input
                      data-bind=""
                      type="checkbox"
                      name="store-timing-checkbox"
                      id="store-timing-checkbox-Sunday"
                    />
                  </div>
                </div>
              </div>
            </span>
          </div>

          <div *ngIf="isGst" class="div-full">
            <input
              class="input"
              type="text"
              [(ngModel)]="billingDetails.TaxDetails.GSTIN"
              name="GSTIN"
              #GSTIN="ngModel"
              placeholder="Enter your GSTIN"
              pattern="\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}"
              [required]="isGst"
              (blur)="onChangeGstDetails()"
            />
            <div
              class="error"
              *ngIf="GSTIN.invalid && (GSTIN.dirty || GSTIN.touched)"
            >
              Invalid GST Number!!
            </div>
          </div>
        </div>

        <div class="field">
          <div class="label">Business name*</div>
          <input
            class="input"
            type="text"
            [(ngModel)]="billingDetails.Name"
            name="Name"
            #Name="ngModel"
            placeholder="Enter your Business name"
            [readonly]="isGst"
            [ngClass]="isGst ? 'readonly-input' : ''"
          />
        </div>

        <div class="field">
          <div class="label">Business contact number*</div>
          <input
            class="input"
            type="text"
            [(ngModel)]="billingDetails.BusinessDetails.PhoneNumber"
            name="PhoneNumber"
            #PhoneNumber="ngModel"
            placeholder="XXXXXXXXXX"
            pattern="^[6-9]{1}[0-9]{9}$"
            required
          />
          <div
            class="error"
            *ngIf="
              PhoneNumber.invalid && (PhoneNumber.dirty || PhoneNumber.touched)
            "
          >
            Entered Mobile Number is not valid!!
          </div>
        </div>

        <div class="field">
          <div class="label">Business email ID*</div>
          <input
            class="input"
            type="text"
            [(ngModel)]="billingDetails.Email"
            name="Email"
            #Email="ngModel"
            placeholder="sample@example.com"
            pattern='^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
            required
          />
          <div
            class="error"
            *ngIf="Email.invalid && (Email.dirty || Email.touched)"
          >
            Entered Email ID is not valid!!
          </div>
        </div>

        <div class="field" *ngIf="states && states.length">
          <div class="label">State (Place of supply)*</div>
          <select
            class="select"
            [(ngModel)]="billingDetails.AddressDetails.State"
            name="State"
            #State="ngModel"
          >
            <option value="0">Select</option>
            <option value="{{ state.state }}" *ngFor="let state of states">
              {{ state.state }}
            </option>
          </select>
        </div>

        <div class="field">
          <div class="label">Business address*</div>
          <input
            class="input"
            type="text"
            [(ngModel)]="billingDetails.AddressDetails.Line1"
            name="Line1"
            #Line1="ngModel"
            placeholder="Enter your business address"
            [readOnly]="isGst"
            [ngClass]="isGst ? 'readonly-input' : ''"
          />
        </div>

        <!-- <button
          *ngIf="!isLoader"
          [disabled]="!businessForm.form.valid"
          type="submit"
          class="btn-yellow btn-100"
          [ngClass]="!businessForm.form.valid ? 'disabled' : ''"
        >
          Save Details & Proceed
        </button> -->

        <nf-raised-button
          [style]="{ 'font-weight': '600' }"
          *ngIf="!isLoader"
          [disabled]="!businessForm.form.valid"
          type="submit"
        >
          Save Details & Proceed
        </nf-raised-button>

        <div class="btn-yellow btn-100 loader" *ngIf="isLoader">
          <div class="full-loader"></div>
        </div>

        <p *ngIf="errorMsg">
          {{ errorMsg }}
        </p>
      </form>
    </div>
  </div>
  <!-- <div class="order-empty">
    <div class="order-empty-content">
      <h4>And up that goes</h4>
      <p>There's nothing in your order history.</p>
      <img src="assets/images/order-empty-icon.svg" />
      <button type="button" routerLink="/" class="btn-yellow">
        go back to marketplace
      </button>
    </div>
  </div> -->
</div>
