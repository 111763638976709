import { NgStyle } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'nf-stroked-button',
  templateUrl: './stroked-button.component.html',
  styleUrls: ['./stroked-button.component.scss'],
})
export class NfStrokedButtonComponent implements OnInit {
  @Input() color: string = 'primary';
  @Input() disabled: boolean = false;
  @Input() style?: any;
  @Input() className: string = '';
  @Input() type: string = 'button';

  ngOnInit(): void {
    if (this.className) {
      this.className = `${this.className} mat-elevation-z0 stroked-btn`;
    } else {
      this.className = `mat-elevation-z0 stroked-btn`;
    }
  }
}
