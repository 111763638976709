import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PriceManager } from './price-manager.service';
@Injectable({
  providedIn: 'root',
})
export class CartServiceService {
  constructor(public priceManager: PriceManager) {}
  cartItems: any[] = [];
  totalAmountWithOutGST: number = 0;
  totalAmountWithGST: number = 0;
  gstAmount: number = 0;
  tanNumber: string = '';
  gstNumber: string = '';
  email: string = '';
  couponCode = '';
  validityMonths: number = 12;
  couponDiscountPrice = 0;
  // couponDiscountPercent = 0;
  createPurchaseOrder: any;
  // array: any[] = [];
  clientId: any;
  fpId: any;
  paymentDetails: any;
  typeOfPayment: any;
  private cartValue$ = new BehaviorSubject<boolean>(false);

  // item: any;
  get getCart() {
    // this.data = localStorage.getItem('cartItems');
    // this.cartItems = JSON.parse(this.data);

    if (this.cartItems && this.cartItems.length) {
      this.cartValue$.next(true);
    } else {
      this.cartValue$.next(false);
    }
    return this.cartValue$.asObservable();
  }

  setCart(data: any) {
    this.cartValue$.next(data);
  }

  onSetCouponDiscount(value: any) {
    // this.couponDiscountPercent = value;
    this.couponDiscountPrice = value;
    this.onCalculate();
  }

  setCouponCode(value: any) {
    if (value) {
      this.couponCode = value;
    }
  }
  getCouponCode() {
    return this.couponCode;
  }

  setCartItems(items: any) {
    if (this.cartItems && this.cartItems.length) {
      this.cartItems.push(items);
    } else {
      this.cartItems = [items];
    }
    if (this.cartItems && this.cartItems.length) {
      this.setCart(true);
    } else {
      this.setCart(false);
    }
  }

  emptyCartItems() {
    this.cartItems = [];
    this.setCart(false);
  }

  deleteCartItems(items: any) {
    this.cartItems.splice(
      this.cartItems.findIndex(function (i: any) {
        return i._kid === items._kid;
      }),
      1
    );

    if (this.cartItems && this.cartItems.length) {
      this.setCart(true);
    } else {
      this.setCart(false);
    }
  }

  getCartItems() {
    return this.cartItems;
  }

  onCalculate() {
    let totalAmountWithOutGST: number = 0;
    this.gstAmount = 0;
    this.totalAmountWithGST = 0;
    this.cartItems = this.getCartItems();
    this.cartItems.forEach(
      (element: {
        price: number;
        overall_discount_percent: number;
        discountedPrice: number;
        discount_percent: number;
      }) => {
        if (element.discountedPrice) {
          totalAmountWithOutGST =
            totalAmountWithOutGST +
            (element.discountedPrice ? element.discountedPrice : 0) *
              this.validityMonths;
        } else {
          totalAmountWithOutGST =
            totalAmountWithOutGST +
            (element.price ? element.price : 0) * this.validityMonths;
        }
      }
    );
    this.gstAmount =
      ((this.priceManager.getPriceWithoutMonth(totalAmountWithOutGST) -
        this.couponDiscountPrice) *
        18) /
      100;
    this.totalAmountWithGST =
      this.priceManager.getPriceWithoutMonth(totalAmountWithOutGST) -
      this.couponDiscountPrice +
      this.gstAmount;

    this.totalAmountWithOutGST = totalAmountWithOutGST;
  }

  setvalidityMonths(validitymonth: number) {
    if (validitymonth) {
      this.validityMonths = validitymonth;
      this.onCalculate();
    }
  }

  getValidiyMonths() {
    if (this.validityMonths) {
      return this.validityMonths;
    } else {
      return null;
    }
  }

  checkItemIsPresentInCart(id: any) {
    if (this.cartItems && this.cartItems.length) {
      const checkItemCart = this.cartItems.filter(
        (element: { _kid: string }) => element._kid == id
      );
      if (checkItemCart) {
        return checkItemCart;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  getlengthCartItems() {
    if (this.cartItems) {
      return this.cartItems.length;
    } else {
      return 0;
    }
  }

  setTanNumber(tanNumber: string) {
    if (tanNumber) {
      this.tanNumber = tanNumber;
    }
  }

  getTanNumber() {
    if (this.tanNumber && this.tanNumber.length) {
      return this.tanNumber;
    } else {
      return '';
    }
  }

  setGSTINNumber(gstNumber: string) {
    if (gstNumber && gstNumber.length) {
      this.gstNumber = gstNumber;
    }
  }

  getGSTINNumber() {
    if (this.gstNumber && this.gstNumber.length) {
      return this.gstNumber;
    } else {
      return '';
    }
  }

  setEmail(email: string) {
    if (email && email.length) {
      this.email = email;
    }
  }

  getEmail() {
    if (this.email && this.email.length) {
      return this.email;
    } else {
      return '';
    }
  }

  setFpClientID(fpId: string, clientId: string) {
    if (fpId && fpId.length && clientId && clientId.length) {
      this.clientId = clientId;
      this.fpId = fpId;
    }
  }

  getFpID() {
    return this.fpId;
  }

  getClientID() {
    return this.clientId;
  }

  setPurchaseOrder(value: any, type: any) {
    if (value) {
      this.createPurchaseOrder = value;
      return this.createPurchaseOrder;
    }
  }
  getPurchaseOrder() {
    return this.createPurchaseOrder;
  }

  setPaymentDetails(value: any) {
    if (value) {
      this.paymentDetails = value;
    }
  }
  getPaymentDetails() {
    return this.paymentDetails;
  }

  getTypeOfPayment() {
    return this.typeOfPayment;
  }

  calculatingBundlesPrice(bundles: any, features: any) {
    if (bundles) {
      for (let i = 0; i < bundles.length; i++) {
        let price = 0;
        let nameAddons: any = [];
        let featuresDetails: any = [];
        bundles[i].included_features?.forEach((data: any) => {
          let filteredList: any = [];

          filteredList = features.filter(
            (featuresdata: any) =>
              featuresdata.feature_code == data.feature_code
          );

          if (filteredList && filteredList.length) {
            featuresDetails.push(filteredList[0]);
          }
          if (
            filteredList &&
            filteredList[0] &&
            filteredList[0].price &&
            data.feature_price_discount_percent
          ) {
            price =
              price +
              (filteredList[0].price -
                (filteredList[0].price * data.feature_price_discount_percent) /
                  100);
          } else if (
            filteredList &&
            filteredList[0] &&
            filteredList[0].price &&
            !data.feature_price_discount_percent
          ) {
            price = price + filteredList[0].price;
          }

          if (filteredList && filteredList[0] && filteredList[0].name) {
            nameAddons.push(filteredList[0].name);
          }
        });
        if (price) {
          bundles[i].totalPrice = price;
        }
        if (bundles[i].overall_discount_percent == undefined) {
          bundles[i].overall_discount_percent = 0;
        }
        bundles[i].discountedPrice =
          bundles[i].totalPrice -
          (price * bundles[i].overall_discount_percent) / 100;
        if (nameAddons && nameAddons.length) {
          bundles[i].nameAddOns = nameAddons;
        }
        if (featuresDetails && featuresDetails.length) {
          bundles[i].featuresDetails = featuresDetails;
        }
      }
    }
    return bundles;
  }
}
