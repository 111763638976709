import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConsumerService } from './../../services/consumer.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-request-callback-modal',
  templateUrl: './request-callback-modal.component.html',
  styleUrls: ['./request-callback-modal.component.scss'],
})
export class RequestCallbackModalComponent implements OnInit, OnDestroy {
  onClickSendRequest = false;
  consumerData: any;
  consumerDataSubscription!: Subscription;

  constructor(
    public dialogRef: MatDialogRef<RequestCallbackModalComponent>,
    public dialog: MatDialog,
    private consumerService: ConsumerService
  ) {}

  ngOnInit(): void {
    //  this.consumerData = this.consumerService.getConsumerData();
    this.consumerDataSubscription =
      this.consumerService.getConsumerData.subscribe((data) => {
        this.consumerData = data;
      });
  }

  ngOnDestroy() {
    if (
      this.consumerDataSubscription &&
      !this.consumerDataSubscription.closed
    ) {
      this.consumerDataSubscription.unsubscribe();
    }
  }

  requestSent() {
    this.onClickSendRequest = !this.onClickSendRequest;
  }
}
