import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  constructor() {}

  private isHomeData = new BehaviorSubject<any>(null);

  get getHomeData() {
    return this.isHomeData.asObservable();
  }

  setHomeData(data: any) {
    this.isHomeData.next(data);
  }
}
