import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NfRaisedButtonComponent } from './raised-button/raised-button.component';
import { NfStrokedButtonComponent } from './stroked-button/stroked-button.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [NfRaisedButtonComponent, NfStrokedButtonComponent],
  imports: [CommonModule, MatButtonModule],
  exports: [NfRaisedButtonComponent, NfStrokedButtonComponent],
})
export class NfButtonModule {}
